import { MenuCheckboxProps, Checkbox as MenuCheckbox } from "components/Menu/Item";
import React, { forwardRef } from "react";
import { FFC } from "util/type";

export type DropdownMenuCheckboxProps = Omit<MenuCheckboxProps, "role">;

/**
 * A DropdownMenu version of a Checkbox. Handles the necessary styling for placing a Checkbox within
 * a DropdownMenu as an option.
 */
export const Checkbox: FFC<HTMLInputElement, DropdownMenuCheckboxProps> = forwardRef(
    ({ ...props }, ref) => {
        return <MenuCheckbox {...props} role={"option"} ref={ref} />;
    },
);
Checkbox.displayName = "Checkbox";
