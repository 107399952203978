import clsx from "clsx";
import { Supplement } from "components/Menu/DropdownMenu/Item/Supplement";
import { Link } from "components/Text";
import * as CommonIcon from "components/Icon/CommonIcon";
import React, { UIEventHandler, FC } from "react";
import "./Load.scss";

export interface DropdownMenuLoadProps {
    className?: string;
    loading?: boolean;
    onClick?: UIEventHandler<HTMLAnchorElement>;
}

export const Load: FC<DropdownMenuLoadProps> = ({ loading = false, onClick, className }) => {
    return (
        <Supplement
            className={clsx("bb-popover-menu__load", className, {
                "bb-popover-menu__load--loading": loading,
            })}
        >
            {loading ? (
                <CommonIcon.Loading size={20} aria-hidden={true}>
                    Loading...
                </CommonIcon.Loading>
            ) : (
                // Usually, this onClick will remove this link element from the DOM. If the element
                // is not in the DOM and useDetectClickOutside fires, it will not detect this link
                // as a child of the popover menu, so it will cause the menu to hide.
                // We use a setTimeout here with no timeout so that this onClick fires *after*
                // the useDetectClickOutside, so that it is detected as inside the popover menu
                // when that fires.
                <Link onClick={(e) => setTimeout(() => onClick?.(e))}>Load more</Link>
            )}
        </Supplement>
    );
};
