import * as Icon from "components/Icon";
import { MenuOptionProps, MenuOptionType, Option } from "components/Menu/Item/Option";
import { Tag, TagType } from "components/Tag";
import React, { FC } from "react";
import { EverColor } from "tokens/typescript/EverColor";

export enum MenuCreateOptionType {
    NEW = "New",
    CUSTOM = "Custom",
}

export interface DropdownMenuCreateOptionProps
    extends Pick<MenuOptionProps, "onClick" | "subLabel" | "ellipsify" | "everId"> {
    createType?: MenuCreateOptionType;
    itemName?: string;
    itemType?: string;
}

export const CreateOption: FC<DropdownMenuCreateOptionProps> = ({
    itemName,
    itemType = "option",
    onClick,
    subLabel,
    createType = MenuCreateOptionType.NEW,
    ellipsify,
    everId,
}) => {
    return (
        <Option
            label={itemName || `${createType} ${itemType}`}
            subLabel={subLabel}
            icon={<Icon.Plus color={EverColor.EVERBLUE_50} />}
            tag={itemName ? <Tag type={TagType.INFORMATIONAL}>{createType}</Tag> : undefined}
            type={MenuOptionType.BUTTON}
            onClick={onClick}
            ellipsify={ellipsify}
            everId={everId}
        />
    );
};
