import { MenuOptionProps, MenuOptionType, Option } from "components/Menu/Item";
import React from "react";
import { FC } from "react";

export type DropdownMenuButtonProps = Omit<
    MenuOptionProps,
    "tag" | "type" | "selected" | "href" | "link"
>;

export const Button: FC<DropdownMenuButtonProps> = (props) => {
    return <Option {...props} type={MenuOptionType.BUTTON} />;
};
