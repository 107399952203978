import clsx from "clsx";
import { IconProps } from "components/Icon/IconProps";
import { TooltipProps } from "components/Tooltip";
import { everIdProp } from "EverAttribute/EverId";
import React, {
    cloneElement,
    CSSProperties,
    FC,
    ReactElement,
    UIEventHandler,
    useId,
    useRef,
} from "react";
import "./UserBadge.scss";
import * as ColorTokens from "tokens/typescript/ColorTokens";
import { EverIdProp } from "util/type";

export enum UserBadgeSize {
    SMALL = "small",
    LARGE = "large",
}

export type UserBadgeColor =
    | typeof ColorTokens.USER_BADGE_1
    | typeof ColorTokens.USER_BADGE_2
    | typeof ColorTokens.USER_BADGE_3
    | typeof ColorTokens.USER_BADGE_4
    | typeof ColorTokens.USER_BADGE_5
    | typeof ColorTokens.USER_BADGE_6
    | typeof ColorTokens.USER_BADGE_7
    | typeof ColorTokens.USER_BADGE_8
    | typeof ColorTokens.USER_BADGE_9;

export interface UserBadgeProps extends EverIdProp {
    /**
     * An optional class name to apply to the user badge.
     */
    className?: string;
    /**
     * A maximum two character string representing the user's name, or an icon to display in
     * the badge.
     */
    children: string | ReactElement<IconProps>;
    /**
     * The size of the badge. Defaults to {@link UserBadgeSize.SMALL}.
     */
    size?: UserBadgeSize;
    /**
     * The background color of the badge. Defaults to {@link ColorTokens.USER_BADGE_PRIMARY}.
     */
    color?: UserBadgeColor;
    /**
     * The function to call when the badge is clicked.
     */
    onClick?: UIEventHandler<HTMLButtonElement>;
    /**
     * The tooltip to display on hover.
     */
    tooltip?: ReactElement<TooltipProps>;
}

interface UserBadgeCSSProperties extends CSSProperties {
    "--bb-userBadge-color"?: string;
}

/**
 * A single size user badge.
 *
 * This is a spec component, and has not actually been completely defined by design. It can be
 * used as-is, but will be updated when the official component is defined and implemented.
 */
export const UserBadge: FC<UserBadgeProps> = ({
    everId,
    className,
    children,
    size = UserBadgeSize.SMALL,
    color = ColorTokens.USER_BADGE_PRIMARY,
    onClick,
    tooltip,
}) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const divRef = useRef<HTMLDivElement>(null);
    const tooltipId = useId();
    let badgeContent;
    if (typeof children === "string") {
        badgeContent = children.slice(0, 2).toUpperCase();
    } else {
        badgeContent = cloneElement(children, {
            className: clsx(children.props.className, "bb-user-badge__icon"),
            size: size === UserBadgeSize.SMALL ? 16 : 20,
            color: ColorTokens.TEXT_INVERTED,
        });
    }
    tooltip &&= cloneElement(tooltip, {
        id: tooltipId,
        target: onClick ? buttonRef : divRef,
    });
    const sharedClasses = clsx(className, "bb-user-badge", `bb-user-badge--${size}`);
    const style: UserBadgeCSSProperties = { "--bb-userBadge-color": color };
    return (
        <>
            {onClick ? (
                <button
                    ref={buttonRef}
                    className={clsx(sharedClasses, "bb-user-badge--button")}
                    onClick={onClick}
                    style={style}
                    aria-describedby={tooltipId}
                    {...everIdProp(everId)}
                >
                    {badgeContent}
                </button>
            ) : (
                <div
                    ref={divRef}
                    className={sharedClasses}
                    style={style}
                    aria-describedby={tooltipId}
                    tabIndex={tooltip ? 0 : undefined}
                    {...everIdProp(everId)}
                >
                    {badgeContent}
                </div>
            )}
            {tooltip}
        </>
    );
};
