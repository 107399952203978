import clsx from "clsx";
import { IconButtonProps } from "components/Button";
import { describedBy, InputWrapper } from "components/util/InputWrapper";
import {
    baseInputProps,
    formatButton,
    TextInputAutoComplete,
    TextInputProps,
} from "components/TextInput/TextInput";
import { everIdProp } from "EverAttribute/EverId";
import React, {
    forwardRef,
    MutableRefObject,
    ReactElement,
    useLayoutEffect,
    useId,
    useRef,
    useState,
} from "react";
import * as CSS from "csstype";
import { Complete, EverIdProp } from "util/type";

export enum TextAreaHeight {
    SMALL = "small",
    MEDIUM = "medium",
    LARGE = "large",
}

export interface TextAreaProps extends TextInputProps<HTMLTextAreaElement>, EverIdProp {
    /**
     * The height of the text area. Defaults to {@link TextAreaHeight.MEDIUM}. Text areas with
     * {@link TextAreaHeight.SMALL} will grow vertically as text is entered.
     */
    height?: TextAreaHeight;
    /**
     * If true, makes the text area resizable by the user. Defaults to false.
     */
    resizable?: boolean;
    /**
     * The right button for this text field. Appears after the suffix (if present) and before the
     * second right button (if present).
     */
    rightButton?: ReactElement<IconButtonProps>;
}

type TextAreaWithRequiredIdProps = TextAreaProps & Required<Pick<TextAreaProps, "id">>;

const MIN_TEXT_AREA_INPUT_HEIGHT = 32;
function calculateDesiredHeight(input: HTMLTextAreaElement): CSS.Property.Height {
    const inputStyle = window.getComputedStyle(input);
    return `calc(
        ${input.scrollHeight}px
        + ${inputStyle.borderTopWidth}
        + ${inputStyle.borderBottomWidth}
    )`;
}

function calculatePadding(input: HTMLElement): CSS.Property.Padding {
    const inputStyle = window.getComputedStyle(input);
    // offsetWidth includes borders and scrollbars
    // clientWidth excludes borders and scrollbars
    // offsetWidth - clientWidth = borderWidth + scrollbarWidth
    // offsetWidth - clientWidth - borderWidth = scrollbarWidth
    const scrollbarWidth = input.offsetWidth - input.clientWidth;
    // When the text area is SMALL, it can shrink down to 36px, which is not enough to fit the
    // button + padding, so we only use 2px padding when it's too small.
    const initialPadding = input.clientHeight <= MIN_TEXT_AREA_INPUT_HEIGHT ? 2 : 4;
    return `${initialPadding}px calc(
        ${initialPadding + scrollbarWidth}px
        - ${inputStyle.borderLeftWidth}
        - ${inputStyle.borderRightWidth}
    )`;
}

const TextAreaWrapper = forwardRef<
    HTMLTextAreaElement,
    Complete<Omit<TextAreaWithRequiredIdProps, "everId">> & TextAreaWithRequiredIdProps
>((props, ref) => {
    const [inputHeight, setInputHeight] = useState<CSS.Property.Height>();
    const [overlayPadding, setOverlayPadding] = useState<CSS.Property.Padding>();
    const [inputPaddingRight, setInputPaddingRight] = useState<CSS.Property.PaddingRight>();
    const internalRef = useRef<HTMLTextAreaElement>();
    const inputRef = (ref || internalRef) as MutableRefObject<HTMLTextAreaElement>;
    useLayoutEffect(() => {
        const element = inputRef.current;
        if (!element) {
            return;
        }
        if (props.rightButton) {
            setOverlayPadding(calculatePadding(element));
            setInputPaddingRight(
                element.clientHeight <= MIN_TEXT_AREA_INPUT_HEIGHT ? "32px" : "34px",
            );
        } else {
            setOverlayPadding(undefined);
            setInputPaddingRight(undefined);
        }
        if (props.height === TextAreaHeight.SMALL) {
            if (element.scrollHeight > element.clientHeight) {
                setInputHeight(calculateDesiredHeight(element));
            } else {
                // The inner text might've shrunk. We have to shrink the text area down to 0
                // so that we can check the height it should be.
                const oldHeight = element.style.height;
                element.style.height = "0";
                setInputHeight(calculateDesiredHeight(element));
                element.style.height = oldHeight;
            }
        } else {
            // It's possible that the height switched from SMALL to some other height, which has
            // a constant input height defined in CSS, so we have to unset the input height in case
            // it was previously set.
            setInputHeight(undefined);
        }
    }, [
        props.className,
        props.rightButton,
        props.value,
        props.placeholder,
        props.height,
        inputRef,
    ]);
    return (
        <div className="bb-text-area__input-wrapper">
            <textarea
                {...baseInputProps(props)}
                aria-describedby={describedBy(
                    props.id,
                    !!props.errorMessage,
                    !!props.helper,
                    props["aria-errormessage"],
                )}
                className="bb-text-area__input"
                ref={inputRef}
                onChange={props.onChange}
                onClick={props.onClick}
                onBlur={props.onBlur}
                onKeyDown={props.onKeyDown}
                onKeyUp={props.onKeyUp}
                onFocus={props.onFocus}
                onScroll={props.onScroll}
                style={{ paddingRight: inputPaddingRight, height: inputHeight }}
                {...everIdProp(props.everId)}
            />
            {props.rightButton && (
                <div className="bb-text-area__input-overlay" style={{ padding: overlayPadding }}>
                    {props.rightButton}
                </div>
            )}
        </div>
    );
});
TextAreaWrapper.displayName = "TextAreaWrapper";

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
    (
        {
            height = TextAreaHeight.MEDIUM,
            id,
            everId,
            autoComplete = TextInputAutoComplete.OFF,
            errorMessage = "This field is required",
            "aria-required": ariaRequired,
            ...props
        },
        ref,
    ) => {
        const generatedId = useId();
        id = id || generatedId;
        const rootClass = clsx("bb-text-area", `bb-text-area--${height}-height`, props.className, {
            "bb-text-area--error": props.error,
            "bb-text-area--with-right-button": props.rightButton,
            "bb-text-area--resizable": props.resizable,
            "bb-text-area--disabled": props.disabled,
            "bb-text-area--read-only": props.readOnly,
            "bb-text-area--required": props.required,
            "bb-text-area--horizontal": props.horizontal,
        });

        props.rightButton &&= formatButton(props.rightButton, {
            className: "bb-text-area__button",
        });

        return (
            <div className={rootClass}>
                <InputWrapper
                    errorMessage={props.error ? errorMessage : undefined}
                    helper={props.helper}
                    hideLabel={props.hideLabel}
                    info={props.info}
                    inputId={id}
                    label={props.label}
                    required={props.required}
                    subLabel={props.subLabel}
                    horizontal={props.horizontal}
                >
                    <TextAreaWrapper
                        height={height}
                        resizable={!!props.resizable}
                        autoComplete={autoComplete}
                        autoFocus={props.autoFocus}
                        className={props.className}
                        disabled={props.disabled}
                        error={props.error}
                        errorMessage={errorMessage}
                        aria-errormessage={props["aria-errormessage"]}
                        helper={props.helper}
                        hideLabel={props.hideLabel}
                        id={id}
                        everId={everId}
                        info={props.info}
                        label={props.label}
                        name={props.name}
                        onChange={props.onChange}
                        onClick={props.onClick}
                        onBlur={props.onBlur}
                        onScroll={props.onScroll}
                        onKeyDown={props.onKeyDown}
                        onKeyUp={props.onKeyUp}
                        onFocus={props.onFocus}
                        horizontal={props.horizontal}
                        placeholder={props.placeholder}
                        readOnly={props.readOnly}
                        required={props.required}
                        aria-required={ariaRequired}
                        rightButton={props.rightButton}
                        subLabel={props.subLabel}
                        value={props.value}
                        ref={ref}
                    />
                </InputWrapper>
            </div>
        );
    },
);
TextArea.displayName = "TextArea";
