import { MenuOptionProps, MenuOptionType, Option as MenuOption } from "components/Menu/Item";
import React, { FC } from "react";

export interface PopoverMenuOptionProps extends Omit<MenuOptionProps, "tag" | "type"> {
    /**
     * If true, the option can have and display a selected state. If false, the selected prop will
     * be ignored. An unselectable option is still focusable and clickable; however, there will not
     * be extra padding to the left of label to show a check icon for selected state.
     */
    selectable?: boolean;
}

export const Option: FC<PopoverMenuOptionProps> = ({ selectable = false, ...props }) => {
    return (
        <MenuOption
            {...props}
            type={selectable ? MenuOptionType.SELECTABLE : MenuOptionType.BUTTON}
        />
    );
};
