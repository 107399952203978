import { FC } from "react";

import {
    Tooltip as TooltipWithDebug,
    TooltipProps as TooltipWithDebugProps,
    TooltipPlacement,
} from "./Tooltip";
export * from "components/Tooltip/useEllipsisTooltip";

export type TooltipProps = Omit<TooltipWithDebugProps, "debug">;
export const Tooltip: FC<TooltipProps> = TooltipWithDebug;

export { TooltipPlacement };
