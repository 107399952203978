import React from "react";

// Taken from https://github.com/jaredpalmer/formik/blob/e677bea8181f40e6762fc7e7fb009122384500c6/packages/formik/src/Formik.tsx#L1191

// React currently throws a warning when using useLayoutEffect on the server.
// To get around it, we can conditionally useEffect on the server (no-op) and
// useLayoutEffect in the browser.
// @see https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85
const useIsomorphicLayoutEffect =
    typeof window !== "undefined"
    && typeof window.document !== "undefined"
    && typeof window.document.createElement !== "undefined"
        ? React.useLayoutEffect
        : React.useEffect;

/**
 * Uses refs to ensure that event callbacks use the most up-to-date version of
 * the function passed.
 *
 * This is needed when the callback function depends on variables that may be
 * out of date in an old version of a closure. This is not needed for pure functions
 * or functions defined outside of a component.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useEventCallback<T extends (...args: any[]) => unknown>(fn: T): T {
    const ref = React.useRef(fn);

    // we copy a ref to the callback scoped to the current state/props on each render
    useIsomorphicLayoutEffect(() => {
        ref.current = fn;
    });

    return React.useCallback((...args: unknown[]) => ref.current.apply(void 0, args), []) as T;
}
