import { useLatest } from "hooks/useLatest";
import { Dispatch, SetStateAction, useCallback, useState } from "react";

/**
 * This hook was adapted from https://usehooks.com/useLocalStorage/.
 *
 * This hook is not exported from the design system and used by {@link useLocalStorage} and
 * {@link useSessionStorage}.
 * @param storageType The kind of browser storage to use.
 * @param key The key to save values under in storage.
 * @param initialValue The initial value to saved to storage if there is no value under {@link key}.
 * This value must be serializable to JSON.
 */
export function useStorage<T>(
    storageType: "localStorage" | "sessionStorage",
    key: string,
    initialValue: T | (() => T),
): [T, Dispatch<SetStateAction<T>>] {
    const storage: Storage = window[storageType];
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            // Get from storage by key
            const item: string | null = storage.getItem(key);
            // Parse stored json or if none return initialValue
            return item !== null ? JSON.parse(item) : initialValue;
        } catch (error) {
            // If error also return initialValue
            return initialValue;
        }
    });
    const storedValueRef = useLatest<T>(storedValue);

    // Return a wrapped version of useState's setter that persists the new value to storage.
    const setValue = useCallback(
        (value: T | ((oldValue: T) => T)) => {
            try {
                // Allow value to be a function so we have same API as useState
                const valueToStore =
                    value instanceof Function ? value(storedValueRef.current) : value;
                // Save state
                setStoredValue(valueToStore);
                // Save to storage
                storage.setItem(key, JSON.stringify(valueToStore));
            } catch (error) {
                // A more advanced implementation would handle the error case
                console.log(error);
            }
        },
        // Wrap storedValue in a ref because we don't want setValue to change when storedValue
        // changes.
        [key, storage, storedValueRef],
    );

    return [storedValue, setValue];
}
