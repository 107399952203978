/**
 * A singleton tooltip manager that ensures that only one tooltip is displayed at any given time.
 * When a tooltip is triggered, its hide function is registered with the manager. Thus, when
 * the next tooltip is triggered, the previous one can be hidden.
 */
class TooltipManager {
    private currentHideFunc?: () => void;

    changeTooltip(hideFunc?: () => void) {
        if (hideFunc !== this.currentHideFunc) {
            this.currentHideFunc?.();
            this.currentHideFunc = hideFunc;
        }
    }

    unmount(hideFunc: () => void) {
        if (hideFunc === this.currentHideFunc) {
            this.currentHideFunc = undefined;
        }
    }
}

export const tooltipManager = new TooltipManager();
