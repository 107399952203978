import clsx from "clsx";
import React, { forwardRef, useImperativeHandle } from "react";
import "./MarkedText.scss";
import { escapeRegExp } from "util/string";
import { FFC } from "util/type";

interface MarkProps {
    className: string;
    children: string;
}

/**
 * A helper component for MarkedText that wraps the given text in a <mark> tag.
 */
const Mark: FFC<HTMLElement, MarkProps> = forwardRef(({ className, children }, ref) => {
    return (
        <mark className={clsx("bb-mark", className)} ref={ref}>
            {children}
        </mark>
    );
});
Mark.displayName = "Mark";

export enum MarkType {
    HIGHLIGHT = "highlight",
    SEARCH_HIT = "search hit",
}

const markStyleMap = {
    [MarkType.HIGHLIGHT]: "bb-mark--highlight",
    [MarkType.SEARCH_HIT]: "bb-mark--search-hit",
};

export interface MarkedTextProps {
    /**
     * An optional class name to apply to the <mark> elements.
     *
     * If the styling is significantly different from that of any existing variants in MarkType,
     * consider adding a new variant.
     */
    markClassName?: string;
    /**
     * The mark style variant that should be used. Default {@link MarkType.HIGHLIGHT}.
     */
    markType?: MarkType;
    /**
     * The search string to mark within the larger text.
     */
    textToMark?: string;
    /**
     * Whether the highlight search should be case-sensitive. Default false.
     */
    caseSensitive?: boolean;
    /**
     * Whether white space should be preserved on the text to be marked. Default false.
     *
     * By default, HTML collapses white space. To handle this, extra white space is removed to
     * mimic what the user sees. So, if the original text is "Hello  world" with two spaces, it
     * will appear as "Hello world" with one space to the user, and a {@link textToMark} of
     * "Hello world" will cause the text to be highlighted.
     *
     * However, some options for the CSS white-space property preserve whitespace. If this is true
     * for the text to be marked, you should set {@link preserveWhitespace} to true. In this case,
     * whitespace will not be removed.
     */
    preserveWhitespace?: boolean;
    /**
     * The text for which all occurrences of highlightText should be highlighted.
     */
    children: string;
}

/**
 * A component that wraps searched substrings within a text in <mark> tags for highlighting.
 */
export const MarkedText: FFC<HTMLElement[], MarkedTextProps> = forwardRef(
    (
        {
            markClassName,
            markType = MarkType.HIGHLIGHT,
            textToMark,
            caseSensitive = false,
            preserveWhitespace = false,
            children,
        }: MarkedTextProps,
        ref,
    ) => {
        const marks = React.useRef<HTMLElement[]>([]);
        // Reset marks.current upon every render so that it doesn't keep on growing.
        marks.current = [];
        // Expose the list of marks to the parent component using useImperativeHandle.
        // See the TextSearch story for an example usage of the list.
        useImperativeHandle(ref, () => marks.current);

        if (!textToMark) {
            return <>{children}</>;
        }

        const text = preserveWhitespace ? children : children.replace(/\s+/g, " ");
        const parts = text.split(
            new RegExp(`(${escapeRegExp(textToMark)})`, caseSensitive ? "g" : "gi"),
        );
        return (
            <>
                {parts.map((part, i) =>
                    part.toLowerCase() === textToMark.toLowerCase() ? (
                        <Mark
                            key={i}
                            className={clsx(markClassName, markStyleMap[markType])}
                            // Using a callback ref for the Marks allows us to push it to the marks list.
                            // We need to check if mark is null because inline callback refs are called
                            // first with null, and then again with the actual element. See
                            // https://reactjs.org/docs/refs-and-the-dom.html#callback-refs for more info.
                            ref={(mark) => mark && marks.current.push(mark)}
                        >
                            {part}
                        </Mark>
                    ) : (
                        part
                    ),
                )}
            </>
        );
    },
);
MarkedText.displayName = "MarkedText";
