import { useBrandedCallback } from "hooks/useBranded";
import { DetectClickConfig, useDetectClickOutside } from "hooks/useDetectClickOutside";
import { useEventListener } from "hooks/useEventListener";
import { MultiValueRefObject, refObjectValues } from "hooks/useMultiValueRef";
import { RefObject, useState } from "react";
import { filterNonNullish } from "util/array";

/**
 * Hook used to detect focus going outside the given element, or clicking outside the given element.
 * Note that focus loss from a click inside the given element (i.e. by clicking a non-focusable
 * element within the given element) will not result in the callback being called.
 */
export function useDetectClickOrFocusOutside<E extends Element = Element>(
    // Disabling no explicit any for the key type, since we only care about the values
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    elementRef: RefObject<E> | MultiValueRefObject<any, E>,
    onTriggered: EventListener,
    detectClickConfig?: DetectClickConfig,
): void {
    /* Detecting focus loss from within a group of elements can be rather complicated. For instance,
     * given the following document:
     * <root>
     *     <element tabIndex="0" />
     *     <element tabIndex="0" />
     * </root>
     * If we want to detect focus leaving / clicks outside of the root element, we have to be able
     * to differentiate between focusout events going from the first element to the second element
     * (or vice versa), and focusout events going from one of the elements inside the root to an
     * element outside the root.
     *
     * When a keyboard-based focusout event occurs (e.g. through a tab/shift-tab), we get a
     * relatedTarget on the event, which is the element that focus shifted to. If this relatedTarget
     * is inside the root element, we know focus is still within root. So, this case is simple to
     * differentiate.
     *
     * When a mouse-based focusout event occurs (i.e. through clicking outside of the currently
     * focused element), the relatedTarget will be undefined, regardless of if the user clicked on
     * a focusable element. This makes it harder to determine if a focusout event from a mouse
     * click actually shifts focus outside the root element. However, since we already have a hook
     * to detect clicks outside a given element, we can ignore all mouse-based focusout events and
     * just allow that hook to handle them.
     *
     * In most cases, you can differentiate between mouse-based and keyboard-based focusout events
     * by simply checking if the relatedTarget is undefined. Typically, if it's undefined, the
     * focusout comes from the mouse (which, again, we ignore and defer to useDetectClickOutside).
     * However, if there are multiple documents in the same window (e.g. through iframes, etc.),
     * relatedTarget will be undefined regardless of the source. So, we add one additional
     * workaround: we store in state whether the last user interaction with the page was via a
     * mouse or a keyboard, and use that state variable to distinguish the two. Then, detecting
     * whether a known keyboard-based focusout event shifted focus outside the root element becomes
     * simple: if the relatedTarget is undefined, we know the user focused to another document,
     * which we can just assume is not part of root; if the relatedTarget is not undefined, we just
     * need to check whether it was in root.
     */
    const [isClick, setIsClick] = useState(false);
    useEventListener(
        document,
        "keydown",
        useBrandedCallback(() => setIsClick(false), []),
    );
    useEventListener(
        document,
        "mousedown",
        useBrandedCallback(() => setIsClick(true), []),
    );

    const focusListener = useBrandedCallback(
        (event: Event) => {
            // If the focus loss comes from a click, we handle it using useDetectClickOutside, so we
            // can ignore it here.
            // Otherwise, if the relatedTarget (i.e. the element that received focus as a result of the
            // focusout) is falsey, we know it's outside of the current document, so we know focus has
            // left the given element.
            // If the relatedTarget is truthy (i.e. it's inside the current document) and the given
            // element does not contain it, we know focus has left the given element.
            if (!(event instanceof FocusEvent) || isClick) {
                return;
            } else if (!event.relatedTarget) {
                onTriggered(event);
            } else {
                const els: E[] = filterNonNullish(refObjectValues(elementRef));
                if (!els.some((el) => el.contains(event.relatedTarget as Node))) {
                    onTriggered(event);
                }
            }
        },
        [elementRef, onTriggered, isClick],
    );
    useEventListener(elementRef, "focusout", focusListener);

    useDetectClickOutside(elementRef, onTriggered, detectClickConfig);
}
