import { useRef, useLayoutEffect, useState, useCallback } from "react";

/*
 * Redefine target as optional so that we can provide a default ResizeObserverEntry state.
 */
interface ResizeObserverEntry<E extends Element = Element> {
    readonly target?: E;
    readonly contentRect: DOMRectReadOnly;
}

export function useResizeObserver<T extends Element>(): [
    (instance: T | null) => void,
    ResizeObserverEntry<T>,
] {
    // Define a default ContentRect for less boilerplate nullish coalescing
    const [observerEntry, setObserverEntry] = useState<ResizeObserverEntry<T>>({
        contentRect: {
            bottom: 0,
            height: 0,
            left: 0,
            right: 0,
            top: 0,
            width: 0,
            x: 0,
            y: 0,
            toJSON: () => {},
        },
    });
    const [node, setNode] = useState<T | null>(null);
    const observer = useRef<ResizeObserver>();

    const disconnect = useCallback(() => observer.current?.disconnect(), []);
    const observe = useCallback(() => {
        observer.current = new ResizeObserver(([entry]: ResizeObserverEntry[]) =>
            setObserverEntry(entry as ResizeObserverEntry<T>),
        );
        node && observer.current.observe(node);
    }, [node]);

    useLayoutEffect(() => {
        observe();
        return disconnect;
    }, [disconnect, observe]);

    return [(e) => setNode(e), observerEntry];
}
