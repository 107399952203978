import React, { FC } from "react";
import { SelectorGroup, SelectorGroupProps } from "components/SelectorGroup";
import { CheckboxValue } from "components/Checkbox/Checkbox";

export type CheckboxGroupProps = Omit<SelectorGroupProps, "error" | "errorMessage">;

/**
 * A grouping of checkboxes
 */
export const CheckboxGroup: FC<CheckboxGroupProps> = (props) => {
    return <SelectorGroup {...props} />;
};

/**
 * Utility function that returns the proper value of the parent checkbox given the values of its
 * child checkboxes. Assumes all children use the same value type (CheckboxValue or boolean).
 */
export function parentCheckboxValue(values: (CheckboxValue | boolean)[]): CheckboxValue {
    const unique = !!values.length && values.every((v) => v === values[0]);
    if (unique) {
        if (typeof values[0] === "boolean") {
            return values[0] ? CheckboxValue.TRUE : CheckboxValue.FALSE;
        }
        return values[0];
    }
    return CheckboxValue.INDETERMINATE;
}
