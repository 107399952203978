import clsx from "clsx";
import { IconButtonProps } from "components/Button";
import { IconProps } from "components/Icon/IconProps";
import { accessibilityProps } from "components/util/InputWrapper";
import { TooltipProps } from "components/Tooltip";
import React, {
    ChangeEventHandler,
    FocusEventHandler,
    InputHTMLAttributes,
    KeyboardEventHandler,
    MouseEventHandler,
    ReactElement,
    ReactNode,
    TextareaHTMLAttributes,
    UIEventHandler,
} from "react";
import "./TextInput.scss";
import { EverColor } from "tokens/typescript/EverColor";
import { randomString } from "util/string";
import { EverIdProp, Overwrite } from "util/type";

/*
 * This file holds functions, components, types, enums, and other utilities common to text inputs
 * (both text fields and text areas). Only parts of this file are exported to webapp as needed.
 *
 * @author annacc
 */

export abstract class TextInputAutoComplete {
    abstract value(): string;

    private static Constant = class extends TextInputAutoComplete {
        constructor(readonly constant: string) {
            super();
        }

        value(): string {
            return this.constant;
        }
    };

    // Chrome does not respect autocomplete=off, and if you use a random or nonsense (but constant)
    // autocomplete value, it will reuse that input's values for any text input that shares that
    // autocomplete value. To bend Chrome to our will, we instead use a randomly generated string
    // id to turn autocomplete off when we actually want it turned off.
    static readonly OFF = new (class extends TextInputAutoComplete {
        value(): string {
            // A random string of length 10, using the 62 alphanumeric characters,
            // yields 62^10 (~839 quadrillion) possible strings. The chance of two text inputs with
            // such randomly generated autocomplete values overlapping is so infinitesimal as to be
            // not worth considering. Thus, this will effectively disable autocomplete for text
            // inputs using these autocomplete values.
            return randomString(10);
        }
    })();
    // From https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
    // Even though many of these may not be used, they are all valid auto-complete values.
    static readonly ON = new TextInputAutoComplete.Constant("on");
    static readonly FULL_NAME = new TextInputAutoComplete.Constant("name");
    static readonly FIRST_NAME = new TextInputAutoComplete.Constant("given-name");
    static readonly MIDDLE_NAME = new TextInputAutoComplete.Constant("additional-name");
    static readonly LAST_NAME = new TextInputAutoComplete.Constant("family-name");
    static readonly HONORIFIC_PREFIX = new TextInputAutoComplete.Constant("honorific-prefix");
    static readonly HONORIFIC_SUFFIX = new TextInputAutoComplete.Constant("honorific-suffix");
    static readonly TITLE = new TextInputAutoComplete.Constant("organization-title");
    static readonly ORGANIZATION = new TextInputAutoComplete.Constant("organization");
    static readonly NICKNAME = new TextInputAutoComplete.Constant("nickname");
    static readonly EMAIL = new TextInputAutoComplete.Constant("email");
    static readonly USERNAME = new TextInputAutoComplete.Constant("username");
    static readonly NEW_PASSWORD = new TextInputAutoComplete.Constant("new-password");
    static readonly CURRENT_PASSWORD = new TextInputAutoComplete.Constant("current-password");
    static readonly ONE_TIME_CODE = new TextInputAutoComplete.Constant("one-time-code");
    static readonly ADDRESS = new TextInputAutoComplete.Constant("street-address");
    static readonly ADDRESS_LINE_ONE = new TextInputAutoComplete.Constant("address-line1");
    static readonly ADDRESS_LINE_TWO = new TextInputAutoComplete.Constant("address-line2");
    static readonly ADDRESS_LINE_THREE = new TextInputAutoComplete.Constant("address-line3");
    static readonly ADDRESS_LEVEL_ONE = new TextInputAutoComplete.Constant("address-level-one");
    static readonly ADDRESS_LEVEL_TWO = new TextInputAutoComplete.Constant("address-level-two");
    static readonly ADDRESS_LEVEL_THREE = new TextInputAutoComplete.Constant("address-level-three");
    static readonly ADDRESS_LEVEL_FOUR = new TextInputAutoComplete.Constant("address-level-four");
    static readonly COUNTRY_CODE = new TextInputAutoComplete.Constant("country");
    static readonly COUNTRY = new TextInputAutoComplete.Constant("country-name");
    static readonly POSTAL_CODE = new TextInputAutoComplete.Constant("postal-code");
    static readonly CREDIT_CARD_FULL_NAME = new TextInputAutoComplete.Constant("cc-name");
    static readonly CREDIT_CARD_FIRST_NAME = new TextInputAutoComplete.Constant("cc-given-name");
    static readonly CREDIT_CARD_MIDDLE_NAME = new TextInputAutoComplete.Constant(
        "cc-additional-name",
    );
    static readonly CREDIT_CARD_LAST_NAME = new TextInputAutoComplete.Constant("cc-family-name");
    static readonly CREDIT_CARD_NUMBER = new TextInputAutoComplete.Constant("cc-number");
    static readonly CREDIT_CARD_EXPIRATION_DATE = new TextInputAutoComplete.Constant("cc-exp");
    static readonly CREDIT_CARD_EXPIRATION_MONTH = new TextInputAutoComplete.Constant(
        "cc-exp-month",
    );
    static readonly CREDIT_CARD_EXPIRATION_YEAR = new TextInputAutoComplete.Constant("cc-exp-year");
    static readonly CREDIT_CARD_SECURITY_CODE = new TextInputAutoComplete.Constant("cc-csc");
    static readonly CREDIT_CARD_TYPE = new TextInputAutoComplete.Constant("cc-type");
    static readonly TRANSACTION_CURRENCY = new TextInputAutoComplete.Constant(
        "transaction-currency",
    );
    static readonly TRANSACTION_AMOUNT = new TextInputAutoComplete.Constant("transaction-amount");
    static readonly LANGUAGE = new TextInputAutoComplete.Constant("language");
    static readonly BIRTHDAY = new TextInputAutoComplete.Constant("bday");
    static readonly BIRTHDAY_DAY = new TextInputAutoComplete.Constant("bday-day");
    static readonly BIRTHDAY_MONTH = new TextInputAutoComplete.Constant("bday-month");
    static readonly BIRTHDAY_YEAR = new TextInputAutoComplete.Constant("bday-year");
    static readonly SEX = new TextInputAutoComplete.Constant("sex");
    static readonly TELEPHONE = new TextInputAutoComplete.Constant("tel");
    static readonly TELEPHONE_COUNTRY_CODE = new TextInputAutoComplete.Constant("tel-country-code");
    static readonly TELEPHONE_NATIONAL = new TextInputAutoComplete.Constant("tel-national");
    static readonly TELEPHONE_AREA_CODE = new TextInputAutoComplete.Constant("tel-area-code");
    static readonly TELEPHONE_LOCAL = new TextInputAutoComplete.Constant("tel-local");
    static readonly TELEPHONE_EXTENSION = new TextInputAutoComplete.Constant("tel-extension");
    static readonly INSTANT_MESSAGING_URL = new TextInputAutoComplete.Constant("impp");
    static readonly URL = new TextInputAutoComplete.Constant("url");
    static readonly PHOTO = new TextInputAutoComplete.Constant("photo");
}

export interface TextInputProps<T extends HTMLInputElement | HTMLTextAreaElement>
    extends EverIdProp {
    /**
     * Whether to enable autocomplete on this field. Defaults to false.
     */
    autoComplete?: TextInputAutoComplete;
    /**
     * An optional boolean that, when true, automatically focuses the input on render.
     * If no value is provided, defaults to false.
     */
    autoFocus?: boolean;
    /**
     * An optional CSS class to add to the component.
     */
    className?: string;
    /**
     * Whether to disable the field. If true, the input is disabled. Note that any buttons
     * will need to be disabled separately.
     */
    disabled?: boolean;
    /**
     * Whether to display the input as an error. If an errorMessage is provided, will be displayed
     * below the input.
     */
    error?: boolean;
    /**
     * An error string to display below the text input. Will not be displayed if error is falsey.
     */
    errorMessage?: ReactNode;
    /**
     * The id of the element containing the error message for the input. Only use this prop when
     * {@link errorMessage} is falsy so that the built-in error message is not displayed.
     */
    "aria-errormessage"?: string;
    /**
     * A helper string which describes the text input.
     */
    helper?: ReactNode;
    /**
     * Whether to hide the label (and sub-label) from the user. Useful for having a label for
     * screen readers but hiding it visually.
     */
    hideLabel?: boolean;
    /**
     * When true, displays the input in a horizontal orientation.
     */
    horizontal?: boolean;
    /**
     * The id for the text input. If none is provided, one is automatically generated. Note that,
     * given the id "id", elements of this input component will also use id__helper,
     * id__error-alert, id__label, id__suffix, and id__info-icon, should the relevant properties be
     * provided.
     */
    id?: string;
    /**
     * When present, adds an information icon to the label that has the given tooltip.
     */
    info?: ReactElement<TooltipProps>;
    /**
     * The label that describes the text input. Required for accessibility purposes. If you don't
     * want the label to be displayed, use hideLabel.
     */
    label: ReactNode;
    /**
     * The name to apply to the input element.
     */
    name?: string;
    /**
     * A callback to apply when the input is unfocused.
     */
    onBlur?: FocusEventHandler<T>;
    /**
     * A callback to apply when the input is changed (any text entered or deleted). In general,
     * users of text inputs should follow the controlled component pattern, and store the current
     * value of the text input in state.
     */
    onChange?: ChangeEventHandler<T>;
    /**
     * A callback that is applied when the input is clicked. Also applied when the label is clicked.
     */
    onClick?: MouseEventHandler<T>;
    /**
     * A callback to apply when the input is focused.
     */
    onFocus?: FocusEventHandler<T>;
    /**
     * A callback to apply when a key is pressed down while the input is focused.
     */
    onKeyDown?: KeyboardEventHandler<T>;
    /**
     * A callback to apply when a key is released while the input is focused.
     */
    onKeyUp?: KeyboardEventHandler<T>;
    /**
     * A callback that is applied when the input scrolls.
     */
    onScroll?: UIEventHandler<T>;
    /**
     * The placeholder to display on the input when no value is present.
     */
    placeholder?: string;
    /**
     * If true, the input is disabled, but the field is styled like it's not disabled. Useful for
     * text inputs where you just want the user to be able to copy the text.
     */
    readOnly?: boolean;
    /**
     * Whether this field is required or not. If true, adds a red '*' to the label.
     */
    required?: boolean;
    /**
     * Whether this field should have "aria-required" or not. If not provided, defaults to
     * the value of required. If required is true, then aria-required will always be true.
     */
    "aria-required"?: boolean;
    /**
     * An extra bit of text to place to the right of or below the label.
     */
    subLabel?: ReactNode;
    /**
     * The value to populate the text input with by default.
     */
    value?: string;
}

export function formatButton(
    button: ReactElement<IconButtonProps>,
    extraProps: Partial<IconButtonProps> = {},
): ReactElement<IconButtonProps> {
    return React.cloneElement(button, {
        children: formatIcon(button.props.children, {
            color: button.props.children.props.color,
        }),
        ...extraProps,
        className: clsx(button.props.className, extraProps.className),
    });
}

export function formatIcon(
    icon: ReactElement<IconProps>,
    extraProps: Partial<IconProps> = {},
): ReactElement<IconProps> {
    return React.cloneElement(icon, {
        size: 20,
        color: icon.props.color || EverColor.PARCHMENT_40,
        ...extraProps,
        className: clsx(icon.props.className, extraProps.className),
        "aria-hidden": true,
    });
}

export function baseInputProps<T extends HTMLInputElement | HTMLTextAreaElement>(
    props: Overwrite<TextInputProps<T>, { id: string }>,
): TextareaHTMLAttributes<HTMLTextAreaElement> & InputHTMLAttributes<HTMLInputElement> {
    return {
        ...accessibilityProps(
            props.id,
            !!props.error,
            !!props.errorMessage,
            !!props.required,
            props["aria-errormessage"],
        ),
        autoComplete: props.autoComplete?.value() || TextInputAutoComplete.OFF.value(),
        autoFocus: props.autoFocus,
        disabled: props.disabled,
        "aria-disabled": props.disabled,
        id: props.id,
        name: props.id,
        // Some screenreaders will read the placeholder, even if a value is present, which is
        // undesirable.
        placeholder: props.value ? undefined : props.placeholder,
        readOnly: props.readOnly,
        "aria-readonly": props.readOnly,
        "aria-required": props["aria-required"] || props.required,
        value: props.value,
    };
}
