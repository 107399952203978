import { MiddlewareData, VirtualElement } from "@floating-ui/core";
import { RefObject } from "react";

/**
 * Given `middlewareData` provided by `useFloating`, determines whether the target/reference
 * element is hidden. This function is defined in a separate module from the Popover for easier
 * mocking during unit testing since jsdom can make offset calculations a little strange for
 * `useFloating`.
 *
 * @param target Popover target ref.
 * @param middlewareData Middleware data returned by `useFloating`.
 */
export function targetHidden(
    target: RefObject<Element | VirtualElement | null>,
    middlewareData: MiddlewareData,
): boolean {
    if (target.current && !(target.current instanceof Element)) {
        // Target is a VirtualElement. Return false because visibility calcs don't always work
        // as expected on VirtualElements.
        return false;
    }
    return middlewareData.hide?.referenceHidden || false;
}
