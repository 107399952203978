import clsx from "clsx";
import { TextField, TextFieldProps } from "components/TextInput/TextField";
import React, { forwardRef } from "react";

export interface RenameableProps extends Omit<TextFieldProps, "ellipsify"> {
    /**
     * If true, the text input will be shifted to the left so that the text will be left aligned
     * with other elements rather than the left edge of the text input.
     */
    textAlign?: boolean;
    /**
     * If true, will ellipsify the text if it extends beyond the width of the input box.
     * Defaults to true.
     */
    ellipsify?: boolean;
}

export const Renameable = forwardRef<HTMLInputElement, RenameableProps>(
    ({ className, textAlign = false, ellipsify = true, ...props }, ref) => {
        return (
            <TextField
                {...props}
                className={clsx(className, "bb-text-field--renameable", {
                    "bb-text-field--text-aligned": textAlign,
                })}
                ref={ref}
                ellipsify={ellipsify}
            />
        );
    },
);
Renameable.displayName = "Renameable";
