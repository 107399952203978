import { useCallback, useEffect, useRef } from "react";

/**
 * Hook used to return focus to the previous element that had focus after e.g. a dialog closes
 */
export function useReturnFocus(capturingFocus: boolean): void {
    const previousFocus = useRef<HTMLElement | null>(null);
    const previousCapturing = useRef<boolean>(capturingFocus);

    const focusChange = useCallback(() => {
        if (!capturingFocus) {
            previousFocus.current = document.activeElement as HTMLElement;
        }
    }, [capturingFocus]);

    const returnFocus = useCallback(() => {
        previousFocus.current && previousFocus.current.focus();
    }, []);

    useEffect(() => {
        if (!capturingFocus && previousCapturing.current) {
            returnFocus();
        }
        !capturingFocus && document.addEventListener("focusin", focusChange);
        previousCapturing.current = capturingFocus;
        return () => {
            document.removeEventListener("focusin", focusChange);
        };
    }, [capturingFocus, focusChange, returnFocus]);
}
