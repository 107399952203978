import {
    Checkbox,
    Info,
    Option,
    PopoverMenuCheckboxProps,
    PopoverMenuOptionProps,
    PopoverMenuSectionProps,
    PopoverMenuToggleSwitchProps,
    PopoverMenuInfoProps,
    Section,
    ToggleSwitch,
} from "components/Menu/PopoverMenu/Item";
import { PopoverPlacement as PopoverMenuPlacement } from "components/util/BasePopover/BasePopover";
import {
    BasePopoverMenu,
    BasePopoverMenuProps,
} from "components/util/BasePopoverMenu/BasePopoverMenu";
import { useDetectClickOutside } from "hooks/useDetectClickOutside";
import { useArrowNav } from "hooks/useArrowNav";
import React, { Dispatch, FC, SetStateAction, useRef } from "react";
import { combineSelectors } from "util/css";
import { FFC } from "util/type";
import "./PopoverMenu.scss";

export { PopoverMenuPlacement };
export type {
    PopoverMenuCheckboxProps,
    PopoverMenuOptionProps,
    PopoverMenuSectionProps,
    PopoverMenuToggleSwitchProps,
    PopoverMenuInfoProps,
};

export interface PopoverMenuProps
    extends Omit<
        BasePopoverMenuProps,
        // These props are only used internally by Bluebook
        | "menuId"
        | "stickyFooter"
        | "stickyHeader"
        | "role"
        | "modal"
        | "renderOutsideParent"
        | "rootRef"
        | "aria-labelledby"
        | "aria-multiselectable"
        | "aria-readonly"
        | "aria-required"
        | "style"
        | "minWidth"
        | "maxWidth"
    > {
    /**
     * Function that sets the show state.
     */
    setShow: Dispatch<SetStateAction<boolean>>;
    /**
     * CSS selectors for which matching elements should be ignored when clicked. Normally,
     * when a click outside the popover or target is detected, the popover closes. If the
     * clicked element matches any of the {@link triggerSelectors} though, the click will
     * be ignored and the popover will remain open.
     *
     * Use this option when any elements outside the target should trigger the popover.
     * Otherwise, trying to open the popover by clicking a non-target element will cause
     * the popover to immediately close, since a click outside the target was detected.
     *
     * Use constants instead of hard-coded strings when possible to prevent selector
     * mismatches when classes are renamed.
     */
    triggerSelectors?: string[];
}

/**
 * A menu that displays its contents in a popover of max size 400x400 anchored to a given trigger
 * element. This component can contain a list of on demand options/actions allowing users to make
 * selections and  initiate actions.
 *
 * Supported children are Checkboxes, CheckboxGroups, ToggleSwitches, and PopoverMenuOptions. A
 * PopoverMenuSection can be used to visually separate sections, but is not needed for menus with a
 * single section that lacks a header.
 *
 * Clicking outside the popover or pressing escape will setShow to false. If escape is pressed,
 * focus returns to the trigger.
 */
export const PopoverMenu: FC<PopoverMenuProps> & {
    Checkbox: FFC<HTMLInputElement, PopoverMenuCheckboxProps>;
    Option: FC<PopoverMenuOptionProps>;
    Section: FC<PopoverMenuSectionProps>;
    ToggleSwitch: FFC<HTMLInputElement, PopoverMenuToggleSwitchProps>;
    Info: FC<PopoverMenuInfoProps>;
} = ({
    className,
    everId,
    children,
    trigger,
    placement = PopoverMenuPlacement.BOTTOM,
    arrow = true,
    arrowWidth = 16,
    arrowHeight = 12,
    arrowMargin = 12,
    show = false,
    setShow,
    triggerSelectors,
    nesting,
}) => {
    const menuContainerRef = useRef<HTMLDivElement>(null);
    useDetectClickOutside(menuContainerRef, (e) => {
        if (!show) {
            return;
        }
        // Ignore click events on triggers. Trigger elements should handle their own events.
        if (e instanceof globalThis.MouseEvent) {
            if (e.target instanceof Node && trigger.current?.contains(e.target)) {
                return;
            }
            if (
                triggerSelectors?.length
                && e.target instanceof Element
                && e.target.matches(combineSelectors(triggerSelectors))
            ) {
                return;
            }
        }
        if (
            e instanceof KeyboardEvent
            && e.key === "Escape"
            && trigger.current instanceof HTMLElement
        ) {
            trigger.current?.focus();
        }
        setShow(false);
    });
    useArrowNav(menuContainerRef);

    return (
        <BasePopoverMenu
            className={className}
            everId={everId}
            placement={placement}
            arrow={arrow}
            arrowWidth={arrowWidth}
            arrowHeight={arrowHeight}
            arrowMargin={arrowMargin}
            role={"menu"}
            rootRef={menuContainerRef}
            show={show}
            trigger={trigger}
            children={children}
            modal={true}
            renderOutsideParent={true}
            nesting={nesting}
        />
    );
};

PopoverMenu.Checkbox = Checkbox;
PopoverMenu.Option = Option;
PopoverMenu.Section = Section;
PopoverMenu.ToggleSwitch = ToggleSwitch;
PopoverMenu.Info = Info;
