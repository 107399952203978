import clsx from "clsx";
import { Renderer, Scrollbar } from "components/Scrollbar";
import { everIdProp } from "EverAttribute/EverId";
import { useArrowNav } from "hooks/useArrowNav";
import React, { FC, ReactNode, useEffect, useRef } from "react";
import { EverIdProp, FFC } from "util/type";
import {
    ComplexItem,
    ComplexItemProps,
    Item,
    ItemProps,
    ParentItem,
    ParentItemProps,
    Section,
    SectionProps,
} from "./Item";
import "./SideNavigation.scss";
import { SideNavVariant, VariantContext } from "./VariantContext";

const DEFAULT_COLLAPSED_WINDOW_SIZE = 1280;

export interface SideNavigationProps extends EverIdProp {
    /**
     * Side navigation content - can consist of sections and navigation items.
     */
    children: ReactNode;
    /**
     * Side Navigation Bar variant
     */
    variant: SideNavVariant;
    /**
     * Optional style class to be applied
     */
    className?: string;
    /**
     * Whether the side navigation bar is in its collapsed form.
     */
    collapsed?: boolean;
    /**
     * State handler for side navigation collapsed state.
     */
    setCollapsed?: React.Dispatch<React.SetStateAction<boolean>>;
    /**
     * Optional header section
     */
    header?: ReactNode;
    /**
     * Optional footer section
     */
    footer?: ReactNode;
}

/**
 * Side Navigation Bar component which takes navigation items as children and has
 * two main variants.
 */
export const SideNavigation: FC<SideNavigationProps> & {
    Section: FFC<HTMLDivElement, SectionProps>;
    Item: FC<ItemProps>;
    ParentItem: FC<ParentItemProps>;
    ComplexItem: FC<ComplexItemProps>;
} = ({ children, variant, everId, className, collapsed, setCollapsed, header, footer }) => {
    const sideNavRef = useRef<HTMLElement>(null);
    // Allow up and down arrow keys to navigate side navigation items.
    useArrowNav(sideNavRef, { filterHidden: true });
    const sideNavClass = clsx(className, "bb-side-nav", {
        [`bb-side-nav--collapsed-${variant}`]: collapsed,
        [`bb-side-nav--${variant}`]: !collapsed,
    });

    const renderThumbVertical: Renderer = ({ style, ...props }) => {
        return <div className={"bb-side-nav__scroll--" + variant} {...props} />;
    };

    // Default to collapsed form if viewport width is 1280px or less on load.
    useEffect(() => {
        if (window.innerWidth <= DEFAULT_COLLAPSED_WINDOW_SIZE && setCollapsed) {
            setCollapsed(true);
        }
    }, [setCollapsed]);

    return (
        <VariantContext.Provider value={variant}>
            <nav ref={sideNavRef} className={sideNavClass} {...everIdProp(everId)}>
                {header}
                <Scrollbar
                    autoHeight={true}
                    autoHeightMin={0}
                    autoHeightMax={"100%"}
                    hideTracksWhenNotNeeded={true}
                    renderThumbVertical={renderThumbVertical}
                >
                    {children}
                </Scrollbar>
                {footer && <div className={"bb-side-nav__footer-wrapper"}>{footer}</div>}
            </nav>
        </VariantContext.Provider>
    );
};

// Use the Compound Component Pattern
SideNavigation.Section = Section;
SideNavigation.Item = Item;
SideNavigation.ParentItem = ParentItem;
SideNavigation.ComplexItem = ComplexItem;
