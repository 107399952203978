import { CSSProperties, MutableRefObject, RefObject, useRef } from "react";
import { DragLayerMonitor, useDragLayer } from "react-dnd";

export type Identifier = string | symbol;

type Point = {
    x: number;
    y: number;
};

const subtractPoints = (a: Point, b: Point): Point => {
    return {
        x: a.x - b.x,
        y: a.y - b.y,
    };
};

function calculateParentOffset(monitor: DragLayerMonitor): Point {
    const client = monitor.getInitialClientOffset();
    const source = monitor.getInitialSourceClientOffset();
    if (client === null || source === null) {
        return { x: 0, y: 0 };
    }
    return subtractPoints(client, source);
}

function calculatePointerPosition(
    monitor: DragLayerMonitor,
    childRef: RefObject<Element>,
): Point | null {
    const offset = monitor.getClientOffset();
    if (offset === null) {
        return null;
    }

    // If we don't have a reference to a valid child, use the default offset:
    // current cursor - initial parent/drag source offset
    if (!childRef.current || !childRef.current.getBoundingClientRect) {
        return subtractPoints(offset, calculateParentOffset(monitor));
    }

    const bb = childRef.current.getBoundingClientRect();
    // Position of the point within chilRef element
    const pointerPos = { x: bb.width / 2, y: Math.min(10, bb.height / 10) };
    return subtractPoints(offset, pointerPos);
}

type UsePreviewReturn<DragData = unknown, PreviewElement extends Element = Element> =
    | { display: false }
    | {
          display: true;
          ref: MutableRefObject<PreviewElement | null>;
          itemType: Identifier | null;
          item: DragData;
          style: CSSProperties;
          monitor: DragLayerMonitor;
      };

/**
 * Calculates the properties and styles needed to manually render a preview of a dragged object.
 */
export function useDragPreview<
    DragData = unknown,
    PreviewElement extends Element = Element,
>(): UsePreviewReturn<DragData, PreviewElement> {
    const elementRef = useRef<PreviewElement>(null);
    const { currentOffset, isDragging, item, itemType, monitor } = useDragLayer(
        (monitor: DragLayerMonitor<DragData>) => {
            return {
                currentOffset: calculatePointerPosition(monitor, elementRef),
                isDragging: monitor.isDragging(),
                item: monitor.getItem(),
                itemType: monitor.getItemType(),
                monitor,
            };
        },
    );

    if (!isDragging || currentOffset === null) {
        return { display: false };
    }

    const transform = `translate(${currentOffset.x.toFixed(1)}px, ${currentOffset.y.toFixed(1)}px)`;
    const style: CSSProperties = {
        pointerEvents: "none",
        position: "fixed",
        top: 0,
        left: 0,
        transform,
        WebkitTransform: transform,
    };

    return {
        display: true,
        itemType: itemType as Identifier | null,
        item,
        style,
        monitor,
        ref: elementRef,
    };
}
