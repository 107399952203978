import clsx from "clsx";
import { IconProps } from "components/Icon/IconProps";
import { TooltipProps } from "components/Tooltip";
import { everIdProp } from "EverAttribute/EverId";
import React, {
    ChangeEventHandler,
    cloneElement,
    FC,
    ReactElement,
    ReactNode,
    useId,
    useRef,
    useState,
} from "react";
import "./RadioButtonGroup.scss";
import "../Checkbox/Selector.scss";
import { EverIdProp } from "util/type";
import * as ColorTokens from "tokens/typescript/ColorTokens";

/**
 * Props for the controlled {@link RadioButton}. Typically you should not need to use these directly
 * if you are using the (@link useRadioButtonGroup} hook below.
 */
export interface RadioButtonProps extends EverIdProp {
    /**
     * True if selected. Defaults to false.
     */
    value?: boolean;
    /**
     * Optional icon to display.
     */
    icon?: ReactElement<IconProps>;
    /**
     * The radio button's label. Required at all times for accessibility purposes.
     * If you don't want to display the label, set {@link hideLabel} to true.
     */
    label: ReactNode;
    /**
     * If true, do not display labels. Defaults to false.
     */
    hideLabel?: boolean;
    /**
     * The name attribute of the radio button's input element, where radio buttons in the same
     * group have the same name attribute. This name is not exposed to the user, but used to
     * associate radio buttons in the same group for keyboard navigation purposes.
     */
    name: string;
    /**
     * A tooltip to render on the radio button.
     */
    tooltip?: ReactElement<TooltipProps>;
    /**
     * If true, the radio button cannot be selected.
     */
    disabled?: boolean;
    /**
     * The action taken when the radio button is clicked.
     */
    onClick: ChangeEventHandler<HTMLInputElement>;
    /**
     * Optional children to display below the icon and/or label.
     */
    children?: ReactNode;
}

/**
 * A controlled radio button component. Typically you should not need to create these directly
 * if you are using the (@link useRadioButtonGroup} hook below.
 */
const RadioButton: FC<RadioButtonProps> = ({
    everId,
    value = false,
    icon,
    label,
    hideLabel = false,
    name,
    tooltip,
    onClick,
    disabled = false,
    children,
}) => {
    const inputId = useId();
    const inputRef = useRef<HTMLInputElement>(null);
    const labelRef = useRef<HTMLLabelElement>(null);

    const topClass = clsx("bb-selector bb-radio-button", {
        "bb-selector--disabled": disabled,
        "bb-selector--selected": value,
    });

    tooltip &&= cloneElement(tooltip, {
        id: `${inputId}__tooltip`,
        target: labelRef,
        hoverTrigger: tooltip.props.hoverTrigger || labelRef,
        focusTrigger: tooltip.props.focusTrigger || inputRef,
    });

    return (
        <div className={topClass}>
            <input
                ref={inputRef}
                id={inputId}
                type={"radio"}
                name={name}
                aria-checked={value}
                checked={value}
                aria-describedby={tooltip ? `${inputId}__tooltip` : undefined}
                onChange={disabled ? () => {} : onClick}
                // Most elements automatically get scrolled into view on focus.
                // Here, the input element which receives focus is absolutely positioned,
                // which messes with this behavior.
                onFocus={() => labelRef.current?.scrollIntoView({ block: "nearest" })}
                aria-disabled={disabled}
                className={"bb-selector__input"}
            />
            <label
                ref={labelRef}
                className={clsx("bb-selector__label", {
                    "bb-selector__label--hidden": hideLabel,
                })}
                htmlFor={inputId}
                {...everIdProp(everId)}
            >
                {icon
                    && React.cloneElement(icon, {
                        color: value
                            ? ColorTokens.BUTTON_TEXT_PRIMARY
                            : ColorTokens.BUTTON_TEXT_SECONDARY,
                        className: "bb-selector__radio-button-icon",
                        "aria-hidden": true,
                    })}
                <div className={"bb-selector__label-content"}>
                    <div className={"bb-selector__main-label"}>{label}</div>
                    {children}
                </div>
            </label>
            {tooltip}
        </div>
    );
};

/**
 * Props for {@link RadioButtonGroup}.
 */
export interface RadioButtonGroupProps extends EverIdProp {
    /**
     * Optional class for the group.
     */
    className?: string;
    /**
     * Optional width of the button group. If not specified, the group will take up the width of its
     * parent container.
     */
    buttonContainerWidth?: number;
    /**
     * The radio buttons.
     */
    children: ReactElement<RadioButtonProps>[];
}

/**
 * A controlled radio button group. Typically you will want to use the {@link useRadioButtonGroup}
 * hook with this component, though it is not necessary if you need to customize the group in a way
 * not supported by the hook (e.g., showing labels on only a subset of buttons).
 */
export const RadioButtonGroup: FC<RadioButtonGroupProps> = ({
    everId,
    className,
    buttonContainerWidth,
    children,
}) => {
    const classes = clsx("bb-selector-group bb-radio-button-group", className);

    return (
        <div className={classes} {...everIdProp(everId)}>
            <div
                className={"bb-selector-group__children"}
                style={buttonContainerWidth ? { width: buttonContainerWidth } : {}}
            >
                {children}
            </div>
        </div>
    );
};

/**
 * Button props passed to the {@link useRadioButtonGroup} hook.
 */
export interface UseRadioButtonGroupButtonProps {
    /**
     * Optional icon to display.
     */
    icon?: ReactElement<IconProps>;
    /**
     * The radio button's label. Required at all times for accessibility purposes. If you don't want
     * to display the label, set {@link UseRadioButtonGroupProps#hideLabel} to true.
     */
    label: ReactNode;
    /**
     * Optional children to include below the icon and/or label.
     */
    children?: ReactNode;
    /**
     * If true, the radio cannot be interacted with. Defaults to false.
     */
    disabled?: boolean;
    /**
     * A tooltip to render on the radio button.
     */
    tooltip?: ReactElement<TooltipProps>;
}

/**
 * Props passed to the {@link useRadioButtonGroup} hook.
 */
export interface UseRadioButtonGroupProps {
    /**
     * Props for each button in the group.
     */
    buttonProps: UseRadioButtonGroupButtonProps[];
    /**
     * The name attribute to apply to each radio button in the group. This name is not
     * exposed to the user, but used to associate radio buttons in the same group for
     * keyboard navigation purposes.
     */
    name: string;
    /**
     * If true, hides button labels. Defaults to false.
     */
    hideLabels?: boolean;
    /**
     * Index of the initially-selected button.
     */
    initial?: number;
    /**
     * Callback for whenever a radio button is selected.
     */
    onSelect?: (selectedIndex: number) => void;
}

/**
 * Return values from the {@link useRadioButtonGroup} hook.
 */
export interface UseRadioButtonGroupResult {
    /**
     * The index of the currently-selected button, or undefined if no button should be selected.
     */
    selected: number | undefined;
    /**
     * Dispatch callback for when the selected button changes.
     */
    setSelected: React.Dispatch<React.SetStateAction<number | undefined>>;
    /**
     * The <RadioButton /> components to be passed as children into <RadioButtonGroup />.
     */
    children: ReactElement<RadioButtonProps>[];
}

/**
 * Convenience hook to avoid boilerplate when creating a {@link RadioButtonGroup}. Handles state
 * selection and creation of the {@link RadioButton} components, which can then be passed as
 * children to {@link RadioButtonGroup}.
 */
export function useRadioButtonGroup({
    buttonProps,
    name,
    hideLabels,
    initial,
    onSelect = () => {},
}: UseRadioButtonGroupProps): UseRadioButtonGroupResult {
    const [selected, setSelected] = useState<number | undefined>(initial);

    return {
        selected,
        setSelected,
        children: buttonProps.map((props, i) => {
            return (
                <RadioButton
                    key={i}
                    value={selected === i}
                    icon={props.icon || undefined}
                    label={props.label}
                    hideLabel={hideLabels}
                    name={name}
                    onClick={() => {
                        setSelected(i);
                        onSelect(i);
                    }}
                    disabled={props.disabled}
                    children={props.children}
                    tooltip={props.tooltip}
                />
            );
        }),
    };
}
