import { ButtonProps, generateButton } from "components/Button";
import { DialogFC } from "components/Dialog/Dialog";
import { Modal, ModalProps, UseModalProps, UseModalResult } from "components/Dialog/Modal";
import {
    Form,
    FormProps,
    FormSubmitButtonProps,
    useForm,
    UseFormProps,
    UseFormResult,
} from "components/Form";
import React, { ReactNode, useId, useMemo } from "react";

type OmittedFormModalCallbacks = "onComplete";

export interface FormModalProps
    extends Omit<ModalProps, OmittedFormModalCallbacks>,
        Pick<FormProps, "onSubmit"> {
    formId?: string;
}

// eslint-disable-next-line @typescript-eslint/ban-types
type FormModalFields = {};

export type UseFormModalProps<T extends FormModalFields, R = ReactNode> = UseFormProps<T, R> &
    Omit<UseModalProps, OmittedFormModalCallbacks>;

export interface UseFormModalResult<T extends FormModalFields, R = ReactNode>
    extends UseFormResult<T, R>,
        Pick<UseModalResult, "visible" | "setVisible"> {
    formModalProps: Omit<UseModalResult["modalProps"], OmittedFormModalCallbacks>;
}

function useFormModal<T extends FormModalFields, R = ReactNode>({
    onHide,
    onCancel,
    onShow,
    ...useFormProps
}: UseFormModalProps<T, R>): UseFormModalResult<T, R> {
    const useFormResult = useForm(useFormProps);
    const useModalResult = Modal.use({ onHide, onCancel, onShow });
    return useMemo(() => {
        const { visible, setVisible, modalProps } = useModalResult;
        const { onComplete: _, ...formModalProps } = modalProps;
        return {
            visible,
            setVisible,
            formModalProps,
            ...useFormResult,
        };
    }, [useModalResult, useFormResult]);
}

/**
 * Simple combination of our Form and Modal components. Wraps the children of the modal in a Form,
 * and sets some of the primary button's default props so that it is linked to the form.
 */
export const FormModal: DialogFC<FormModalProps, typeof useFormModal> = ({
    primaryButton = "Ok",
    onSubmit,
    children,
    formId,
    ...props
}) => {
    const internalFormId = useId();
    formId ||= internalFormId;
    const primaryButtonProps: Partial<ButtonProps & FormSubmitButtonProps> = {
        isSubmit: true,
        form: formId,
    };
    primaryButton = generateButton(primaryButton, primaryButtonProps);
    return (
        <Modal {...props} primaryButton={primaryButton}>
            <Form onSubmit={onSubmit} id={formId}>
                {children}
            </Form>
        </Modal>
    );
};
FormModal.use = useFormModal;
