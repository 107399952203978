import { ReactNode, useEffect, useState, FC } from "react";
import { createPortal } from "react-dom";

export interface PortalProps {
    /**
     * Tag name for the container element to render children into. This container element will be
     * automatically created and destroyed on mount and unmount. The prop should not change once
     * this component is mounted.
     */
    tagName?: keyof HTMLElementTagNameMap;
    /**
     * Element to append the container element created from tagName to.
     */
    appendTo?: Element;
    /**
     * Components to render into the Portal.
     */
    children: ReactNode;
}

/**
 * Simple utility for using React Portals. Use this to render children into a DOM node that exists
 * outside the DOM hierarchy of the parent component.
 */
export const Portal: FC<PortalProps> = ({
    tagName = "div",
    appendTo = document.body,
    children,
}) => {
    const [container] = useState(() => {
        const element = document.createElement(tagName);
        element.className = "react-root";
        return element;
    });
    useEffect(() => {
        appendTo.appendChild(container);
        return () => {
            appendTo.removeChild(container);
        };
    }, [appendTo, container]);
    return createPortal(children, container);
};
