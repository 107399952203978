import clsx from "clsx";
import {
    Button,
    ButtonColor,
    ButtonProps,
    ButtonSize,
    ButtonWidth,
    IconButton,
    TextButton,
} from "components/Button";
import { Form, FormSubmitButton } from "components/Form";
import * as Icon from "components/Icon";
import * as CommonIcon from "components/Icon/CommonIcon";
import { IconProps } from "components/Icon/IconProps";
import { ColumnFilterSummaryProps } from "components/Table/ColumnFilter";
import { RowObject } from "components/Table/Table";
import { TextField } from "components/TextInput";
import { Tooltip } from "components/Tooltip";
import { everIdProp } from "EverAttribute/EverId";
import { useAsyncDebounce } from "hooks/useAsyncDebounce";
import React, {
    Dispatch,
    FC,
    FormEvent,
    PropsWithoutRef,
    ReactElement,
    ReactNode,
    SetStateAction,
    useId,
    useRef,
    useState,
} from "react";
import * as ReactTable from "react-table";
import "./Table.scss";
import { countOf, num, toString } from "util/string";
import { EverIdProp } from "util/type";

export interface HeaderInfoIconProps {
    /**
     * The tooltip content of the header info icon.
     */
    tooltipContent: ReactNode;
    /**
     * The URL of the "learn more" link in the info icon tooltip.
     */
    learnMore?: string;
    /**
     * Optional custom text for the "learn more" link in the info icon tooltip. Only applicable
     * when {@link learnMore} is provided.
     */
    learnMoreText?: string;
}

/**
 * An info icon with a tooltip that can be passed into the {@link ColumnProps.infoIcon} prop.
 * The icon will be displayed on the column header.
 */
export const HeaderInfoIcon: FC<HeaderInfoIconProps> = ({
    tooltipContent,
    learnMore,
    learnMoreText,
}) => {
    const infoIconRef = useRef(null);
    const infoIconTooltipId = "bb-table__header-tooltip-" + useId();
    return (
        <>
            <Icon.InfoCircle
                className={"bb-table__header-info-icon"}
                ref={infoIconRef}
                size={20}
                tabIndex={0}
                aria-label={"Info"}
                aria-describedby={infoIconTooltipId}
            />
            <Tooltip
                id={infoIconTooltipId}
                target={infoIconRef}
                learnMoreLink={learnMore}
                learnMoreText={learnMoreText}
            >
                {tooltipContent}
            </Tooltip>
        </>
    );
};

export interface AddRowFormProps extends EverIdProp {
    /**
     * An optional class name to apply to the AddRowForm.
     */
    className?: string;
    /**
     * The content to be displayed above the "Cancel" and "Add" buttons. Generally this should
     * include text inputs, dropdown selectors, or some sort of user input element. If possible,
     * these elements should be aligned with the columns they correspond to.
     */
    children: ReactNode;
    /**
     * The function to call when the user hits the "Add" button.
     */
    onSubmit: (event: FormEvent<HTMLFormElement>) => void;
    /**
     * The function to call when the user hits the "Cancel" button.
     */
    onCancel: () => void;
    /**
     * Whether the "Add" button should be disabled. Defaults to false.
     */
    submitDisabled?: boolean;
    /**
     * Whether the "Add" button should be displayed in the loading state. Defaults to false.
     */
    submitLoading?: boolean;
}

/**
 * A toggleable form component to be used with AdvancedTable that supports taking user
 * inputs and adding a table row. Logic for adding the actual row, disabling/enabling the
 * submit button, etc. can be implemented using the {@link useForm} hook. Generally, there
 * should be some way to trigger the AddRowForm (e.g. {@link AddRowButton}), and hitting the
 * "Cancel" button should hide it.
 */
export const AddRowForm: FC<AddRowFormProps> = ({
    everId,
    className,
    children,
    onSubmit,
    onCancel,
    submitDisabled = false,
    submitLoading = false,
}) => {
    return (
        <Form
            className={clsx("bb-advanced-table__add-row-form", className)}
            onSubmit={onSubmit}
            everId={everId}
        >
            {children}
            <div className={"bb-form__footer"}>
                <Button
                    color={ButtonColor.SECONDARY}
                    width={ButtonWidth.FIXED}
                    size={ButtonSize.SMALL}
                    // Stop error validation from occurring when cancel button is clicked down.
                    onMouseDown={onCancel}
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <FormSubmitButton
                    color={ButtonColor.PRIMARY}
                    width={ButtonWidth.FIXED}
                    size={ButtonSize.SMALL}
                    disabled={submitDisabled}
                    loading={submitLoading}
                >
                    Add
                </FormSubmitButton>
            </div>
        </Form>
    );
};

export type AddRowButtonProps = Pick<
    ButtonProps,
    "everId" | "className" | "children" | "size" | "onClick" | "disabled"
>;

/**
 * A button with the standard styling for adding a new row to a table. Some expected usages of
 * this button might be showing an {@link AddRowForm} at the bottom of a table, or opening
 * a dialog form for adding a new table row.
 */
export const AddRowButton: FC<AddRowButtonProps> = (props) => {
    return (
        <Button
            {...props}
            width={ButtonWidth.FLEXIBLE}
            color={ButtonColor.PRIMARY}
            icon={<Icon.Plus />}
        >
            {props.children}
        </Button>
    );
};

export interface RowCountSummaryProps {
    /**
     * The total number of rows in the table before filtering.
     */
    numTotalRows: number;
    /**
     * The number of results after filtering. If no results have been filtered out,
     * then set to null.
     */
    numFilterResults: number | null;
    /**
     * The number of rows that have been selected through the checkbox column.
     */
    numSelected?: number;
    /**
     * A singular noun to describe the row count when there is exactly one row.
     * Defaults to "entry".
     */
    counter?: string;
    /**
     * The plural form of {@link counter} to describe the row count when there are 0 rows or
     * >1 rows. Defaults to `${counter}s`.
     */
    counterPlural?: string;
}

/**
 * A simple display which summarizes the number of total rows, filtered rows, and selected rows.
 * It can be placed in the {@link ActionBar} by passing it to the
 * {@link ActionBarProps.rowCountSummary} prop.
 */
export const RowCountSummary: FC<RowCountSummaryProps> = ({
    numTotalRows,
    numFilterResults,
    numSelected,
    counter,
    counterPlural,
}) => {
    const filteredText =
        numFilterResults !== null && numFilterResults < numTotalRows ? (
            <span className={"bb-table-row-count__filtered-text"}>
                {`(filtered of ${numTotalRows} total)`}
            </span>
        ) : undefined;
    const numEntries = numFilterResults !== null ? numFilterResults : numTotalRows;
    const entriesDisplay = countOf(
        numEntries,
        counter || "entry",
        counterPlural || (counter && counter + "s") || "entries",
    );
    return (
        <div className={"bb-table-row-count"}>
            {numSelected ? (
                <>
                    {num(numSelected)} selected / {entriesDisplay}
                </>
            ) : (
                entriesDisplay
            )}
            {filteredText}
        </div>
    );
};

export interface GlobalFilterProps extends EverIdProp {
    /**
     * The current value of the filter text input.
     */
    inputValue: string;
    /**
     * The setter for the {@link inputValue} state variable. This is separate from
     * {@link setFilterValue} because the input value and filter value may not be in sync if
     * there is a debounce period (see {@link debounceDelay}). When the user types into the
     * filter input, {@link inputValue} updates immediately, whereas the filter value doesn't
     * update until the debounce period has passed.
     */
    setInputValue: Dispatch<SetStateAction<string>> | ((value: string) => void);
    /**
     * Whether the input should be displayed. If false, the "Search" button is displayed instead.
     */
    showInput: boolean;
    /**
     * The setter for the {@link showInput} state variable.
     */
    setShowInput: Dispatch<SetStateAction<boolean>> | ((show: boolean) => void);
    /**
     * The setter for the filter value state variable.
     */
    setFilterValue: Dispatch<SetStateAction<string>> | ((value: string) => void);
    /**
     * The placeholder text to be displayed in the filter input. Defaults to "Global search".
     */
    placeholder?: string;
    /**
     * The width of the filter input in pixels. Defaults to 256.
     */
    width?: number;
    /**
     * The amount of debounce delay in milliseconds. Defaults to 250.
     */
    debounceDelay?: number;
}

/**
 * A global text filter which can be placed in the {@link ActionBar} by passing it to the
 * {@link ActionBarProps.globalTextFilter} prop.
 */
export const GlobalFilter: FC<GlobalFilterProps> = ({
    everId,
    inputValue,
    setInputValue,
    showInput,
    setShowInput,
    setFilterValue,
    placeholder = "Global search",
    width = 256,
    debounceDelay = 250,
}) => {
    const onChange = useAsyncDebounce(setFilterValue, debounceDelay);

    return (
        <div className={"bb-table-global-filter"}>
            {showInput ? (
                <TextField
                    everId={everId}
                    autoFocus
                    value={inputValue}
                    width={`${width}px`}
                    label={placeholder}
                    hideLabel={true}
                    leftIcon={<Icon.Search size={20} />}
                    rightButtons={
                        <IconButton
                            aria-label={"Clear filter"}
                            onClick={() => {
                                setInputValue("");
                                setFilterValue("");
                                setShowInput(false);
                            }}
                        >
                            <Icon.X size={20} />
                        </IconButton>
                    }
                    onChange={(e) => {
                        setInputValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={placeholder}
                />
            ) : (
                <TextButton
                    icon={<Icon.Search />}
                    onClick={() => setShowInput(true)}
                    aria-label={placeholder}
                >
                    Search
                </TextButton>
            )}
        </div>
    );
};

interface UseGlobalFilterProps {
    /**
     * The initial value for {@link UseGlobalFilterResult.inputValue}. Defaults to "".
     */
    initialInputValue?: string;
    /**
     * The initial value for {@link UseGlobalFilterResult.filterValue}. Defaults to "".
     */
    initialFilterValue?: string;
    /**
     * The initial value for {@link UseGlobalFilterResult.showInput}. Defaults to false.
     */
    initialShowInput?: boolean;
}

interface UseGlobalFilterResult {
    /**
     * The current string value for the filter input.
     */
    inputValue: string;
    /**
     * A setter for the {@link inputValue} state variable.
     */
    setInputValue: Dispatch<SetStateAction<string>>;
    /**
     * The current filter value. This is separate from {@link inputValue} because the
     * filter may be debounced.
     */
    filterValue?: string;
    /**
     * A setter for the {@link filterValue} state variable.
     */
    setFilterValue: Dispatch<SetStateAction<string>>;
    /**
     * Whether to show the filter input.
     */
    showInput: boolean;
    /**
     * A setter for the {@link showInput} state variable.
     */
    setShowInput: Dispatch<SetStateAction<boolean>>;
}

/**
 * A hook that sets up the necessary state variables and their setters for use with
 * {@link GlobalFilter} and returns them. This hook should be called at whatever level the state
 * of the {@link GlobalFilter} lives. The provided initial values will be used.
 *
 * See {@link UseGlobalFilterResult} for details on the variables returned by this hook.
 */
export function useGlobalFilter(props: UseGlobalFilterProps = {}): UseGlobalFilterResult {
    const { initialInputValue = "", initialFilterValue = "", initialShowInput = false } = props;
    const [inputValue, setInputValue] = useState<string>(initialInputValue);
    const [filterValue, setFilterValue] = useState<string>(initialFilterValue);
    const [showInput, setShowInput] = useState<boolean>(initialShowInput);
    return {
        inputValue,
        setInputValue,
        filterValue,
        setFilterValue,
        showInput,
        setShowInput,
    };
}

export interface ActionBarProps extends EverIdProp {
    /**
     * Custom action bar elements to be displayed on the left side of the action bar.
     */
    customContent?: ReactNode;
    /**
     * The {@link RowCountSummary} component to place in the action bar.
     */
    rowCountSummary?: ReactElement<RowCountSummaryProps>;
    /**
     * The {@link GlobalFilter} component to place in the action bar.
     */
    globalTextFilter?: ReactElement<GlobalFilterProps>;
    /**
     * The {@link ColumnFilterSummary} component to place in the action bar.
     */
    columnFilterSummary?: ReactElement<ColumnFilterSummaryProps>;
}

/**
 * An action bar that can be passed into the {@link TableProps#actionBar} prop to be displayed
 * directly above the table.
 */
export const ActionBar: FC<ActionBarProps> = ({
    everId,
    customContent,
    rowCountSummary,
    globalTextFilter,
    columnFilterSummary,
}: ActionBarProps) => {
    return (
        <div
            role={"toolbar"}
            aria-label={"Table action bar"}
            className={"bb-table-action-bar"}
            {...everIdProp(everId)}
        >
            <div className={"bb-table-action-bar__left"}>
                {customContent}
                {customContent && rowCountSummary && (
                    <hr className={"bb-table-action-bar__divider"} />
                )}
                {rowCountSummary}
            </div>
            <div className={"bb-table-action-bar__right"}>
                {globalTextFilter}
                {columnFilterSummary}
            </div>
        </div>
    );
};

export interface SortIconProps {
    className?: string;
    hovered: boolean;
    sorted: boolean;
    sortedDesc?: boolean;
}

export const SortIcon: FC<SortIconProps> = ({
    className,
    hovered,
    sorted,
    sortedDesc,
}: SortIconProps) => {
    const sharedProps: Partial<PropsWithoutRef<IconProps>> = {
        className: className,
        size: 20,
        "aria-label": "Sort",
    };
    if (sorted && sortedDesc) {
        return hovered ? (
            <CommonIcon.SortDescHover {...sharedProps} />
        ) : (
            <CommonIcon.SortDesc {...sharedProps} />
        );
    }
    if (sorted) {
        return hovered ? (
            <CommonIcon.SortAscHover {...sharedProps} />
        ) : (
            <CommonIcon.SortAsc {...sharedProps} />
        );
    }
    return hovered ? (
        <CommonIcon.SortHover {...sharedProps} />
    ) : (
        <Icon.SortDefault {...sharedProps} />
    );
};

export function alphanumericCaseInsensitiveSort<T extends RowObject>(
    rowA: ReactTable.Row<T>,
    rowB: ReactTable.Row<T>,
    columnId: string,
): number {
    const a = toString(rowA.values[columnId]);
    const b = toString(rowB.values[columnId]);
    return a.localeCompare(b);
}

export function getSelectedRowIndices(current: number, lastSelected: number): number[] {
    let start = Math.min(current, lastSelected);
    let end = Math.max(current, lastSelected);
    if (start === lastSelected) {
        start += 1;
    } else {
        end -= 1;
    }
    return [...Array(end - start + 1).keys()].map((i) => i + start);
}

/**
 * Returns an array of row ids corresponding to the given row objects that are expandable.
 *
 * Note that the returned row ids are not row keys (which you can get using the
 * {@link BaseTableProps.getKey} function). Rather, these row ids refer to the `id` property of
 * the `ReactTable.Row` objects returned from the `ReactTable.useTable` hook.
 */
export function getExpandableRowIds(rows: RowObject[], prefix?: string): string[] {
    const expandable: string[] = [];
    rows.map((row, index) => {
        if (row.subRows?.length) {
            const rowId = prefix ? `${prefix}.${index}` : String(index);
            expandable.push(rowId);
            expandable.push(...getExpandableRowIds(row.subRows, rowId));
        }
    });
    return expandable;
}
