import clsx from "clsx";
import { IconButton, IconButtonProps } from "components/Button";
import * as Icon from "components/Icon";
import "./DropdownMenu.scss";
import {
    Button,
    Checkbox,
    CreateOption,
    DropdownMenuButtonProps,
    DropdownMenuCheckboxProps,
    DropdownMenuCreateOptionProps,
    DropdownMenuInfoProps,
    DropdownMenuLoadProps,
    DropdownMenuOptionProps,
    DropdownMenuSectionProps,
    DropdownMenuSupplementProps,
    Info,
    Load,
    Option,
    Section,
    Supplement,
} from "components/Menu/DropdownMenu/Item";
import { PopoverMenuPlacement } from "components/Menu/PopoverMenu";
import { Span } from "components/Text";
import { TextField, TextFieldHeight, TextFieldWidth } from "components/TextInput";
import { TextFieldProps } from "components/TextInput/TextField";
import {
    BasePopoverMenu,
    BasePopoverMenuProps,
    MenuContext,
} from "components/util/BasePopoverMenu";
import * as CSSType from "csstype";
import { Memo, useBrandedCallback, useBrandedMemo } from "hooks/useBranded";
import { useButtonRole } from "hooks/useButtonRole";
import { useCombinedRef } from "hooks/useCombinedRef";
import { useDetectClickOrFocusOutside } from "hooks/useDetectClickOrFocusOutside";
import { useEventListener } from "hooks/useEventListener";
import { useFocusTrap } from "hooks/useFocusTrap";
import { useMultiValueRef } from "hooks/useMultiValueRef";
import { useResizeObserver } from "hooks/useResizeObserver";
import { useArrowNav } from "hooks/useArrowNav";
import React, {
    ChangeEventHandler,
    Dispatch,
    FC,
    FocusEventHandler,
    forwardRef,
    MouseEventHandler,
    ReactElement,
    Ref,
    RefObject,
    SetStateAction,
    UIEventHandler,
    useEffect,
    useId,
    useMemo,
    useRef,
    useState,
} from "react";
import * as ColorTokens from "tokens/typescript/ColorTokens";
import { EverIdProp, FFC } from "util/type";

export type {
    DropdownMenuCheckboxProps,
    DropdownMenuCreateOptionProps,
    DropdownMenuInfoProps,
    DropdownMenuLoadProps,
    DropdownMenuOptionProps,
    DropdownMenuSectionProps,
    DropdownMenuSupplementProps,
};

type UseDropdownMenuProps = Pick<
    DropdownMenuProps,
    | "filterable"
    | "readOnly"
    | "placeholder"
    | "onBlur"
    | "onChange"
    | "onDetectClickOrFocusOutside"
    | "onFocus"
    | "onScroll"
>;

type UseDropdownMenuResultDropdownProps = Required<
    Pick<
        DropdownMenuProps,
        | "onBlur"
        | "onChange"
        | "onDetectClickOrFocusOutside"
        | "onFocus"
        | "onScroll"
        | "show"
        | "setShow"
        | "filterable"
        | "readOnly"
    >
> &
    Pick<DropdownMenuProps, "placeholder" | "aria-placeholder">;

interface UseDropdownMenuResult {
    filterText: string | undefined;
    setFilterText: Dispatch<SetStateAction<string | undefined>>;
    dropdownProps: UseDropdownMenuResultDropdownProps;
}

interface UseDropdownMenuResultMixin<T = string> {
    onItemClick: (itemValue: T) => void;
    isItemSelected: Memo<(itemValue: T) => boolean>;
}

/**
 * See {@link useSingleDropdownMenu} and {@link useMultiDropdownMenu}
 */
function useDropdownMenu({
    filterable = true,
    readOnly = false,
    onBlur: externalOnBlur,
    onChange: externalOnChange,
    onDetectClickOrFocusOutside: externalOnDetectClickOrFocusOutside,
    onFocus: externalOnFocus,
    onScroll: externalOnScroll,
}: UseDropdownMenuProps): UseDropdownMenuResult {
    const [filterText, setFilterText] = useState<string>();
    const [focusWithin, setFocusWithin] = useState(false);
    const [show, setShow] = useState(false);
    useEffect(() => {
        if (!show) {
            setFilterText(undefined);
            setFocusWithin(false);
        }
    }, [show]);
    const onChange: Memo<ChangeEventHandler<HTMLInputElement>> = useBrandedCallback(
        (event) => {
            if (readOnly || !filterable) {
                return;
            }
            setFilterText(event.target.value);
            externalOnChange?.(event);
        },
        [externalOnChange, filterable, readOnly],
    );
    const onFocus: Memo<FocusEventHandler<HTMLInputElement>> = useBrandedCallback(
        (event) => {
            const wasFocusWithin = focusWithin;
            setFocusWithin(true);
            if (readOnly) {
                return;
            }
            setShow(true);
            if (!wasFocusWithin) {
                setFilterText("");
            }
            externalOnFocus?.(event);
        },
        [externalOnFocus, focusWithin, readOnly],
    );
    const [scrollLeft, setScrollLeft] = useState(0);
    const onBlur: Memo<FocusEventHandler<HTMLInputElement>> = useBrandedCallback(
        (event) => {
            // This is taking advantage of the order of events, where onBlur will happen first,
            // so we can use the old onScroll from the last time scrollLeft was set in an onScroll
            // callback.
            event.target.scrollLeft = scrollLeft;
            externalOnBlur?.(event);
        },
        [externalOnBlur, scrollLeft],
    );
    const onClick: Memo<MouseEventHandler<HTMLInputElement>> = useBrandedCallback(() => {
        !show && setShow(true);
    }, [show]);
    const onDetectClickOrFocusOutside: Memo<(e: Event, el: HTMLInputElement | null) => void> =
        useBrandedCallback(
            (event, input) => {
                setShow(false);
                externalOnDetectClickOrFocusOutside?.(event, input);
            },
            [externalOnDetectClickOrFocusOutside],
        );
    // This is a workaround for the input's caret rapidly changing back and forth in position
    // when selecting an option in a multi dropdown.
    const onScroll: Memo<UIEventHandler<HTMLInputElement>> = useBrandedCallback(
        (e) => {
            if (!(e.target instanceof HTMLInputElement)) {
                return;
            }
            setScrollLeft(e.target.scrollLeft);
            externalOnScroll?.(e);
        },
        [externalOnScroll],
    );

    return useMemo(
        () => ({
            filterText,
            setFilterText,
            dropdownProps: {
                show,
                setShow,
                onBlur,
                onChange,
                onClick,
                onDetectClickOrFocusOutside,
                onFocus,
                onScroll,
                filterable,
                readOnly,
            },
        }),
        [
            filterText,
            filterable,
            onBlur,
            onChange,
            onClick,
            onDetectClickOrFocusOutside,
            onFocus,
            onScroll,
            readOnly,
            show,
        ],
    );
}

function displayValue<T = string>(
    filterable: boolean,
    filterText: string | undefined,
    value: T | undefined,
    toString: (v: T | undefined) => string,
): string {
    return filterable && filterText !== undefined ? filterText : toString(value);
}

function placeholderProps<T>(
    defaultPlaceholder: string | undefined,
    value: T | undefined,
    isValuePlaceholder: boolean,
    valueToString: (value: T | undefined) => string,
): { placeholder: string | undefined; "aria-placeholder": string | undefined } {
    return {
        placeholder: isValuePlaceholder ? valueToString(value) : defaultPlaceholder,
        "aria-placeholder": isValuePlaceholder ? defaultPlaceholder : undefined,
    };
}

function clearButton(onClick: MouseEventHandler<HTMLButtonElement>): ReactElement<IconButtonProps> {
    return (
        <IconButton
            className={"bb-dropdown-menu__clear-button"}
            aria-label={"Clear"}
            onClick={onClick}
            key={"bb-dropdown-menu__clear-button"}
        >
            <Icon.X />
        </IconButton>
    );
}

interface UseSingleDropdownMenuProps<T = string>
    extends UseDropdownMenuProps,
        Pick<DropdownMenuProps, "placeholder" | "required" | "aria-required" | "rightButton"> {
    value: T | undefined;
    setValue: Dispatch<SetStateAction<T | undefined>> | Memo<(value: T | undefined) => void>;
    valueToString?: Memo<(value: T | undefined) => string>;
}

export type UseSingleDropdownMenuResultDropdownProps = UseDropdownMenuResultDropdownProps &
    Pick<DropdownMenuProps, "value" | "required" | "rightButton">;

export interface UseSingleDropdownMenuResult<T = string>
    extends UseDropdownMenuResult,
        UseDropdownMenuResultMixin<T> {
    dropdownProps: UseSingleDropdownMenuResultDropdownProps;
}

const DEFAULT_VALUE_TO_STRING = (<T,>(value?: T) => value?.toString() || "") as Memo<
    <T>(value?: T) => string
>;

/**
 * This hook sets up the necessary state variables and callbacks for use with single dropdowns and
 * returns them. This hook should be used when making use of a DropdownMenu with one possible value.
 * This hook should be called at whatever level the state of the DropdownMenu lives.
 *
 * The hook returns the following variables:
 * - show: Whether to show the dropdown menu. Initially false. Stored in state.
 * - setShow: A setter for the show state variable.
 * - value: The currently selected value for the dropdown, initially undefined. Stored in state.
 * - setValue: A setter for the value state variable.
 * - filterText: The current value of the filter text the user has supplied using the text input.
 *      Initially undefined. If filtering is not allowed or if the dropdown is read only, will
 *      remain undefined unless explicitly set using setFilterText. Stored in state.
 * - setFilterText: A setter for the filterText state variable.
 * - showFilterText: A boolean which, if true, shows the filterText as the displayValue rather than
 *      the value. Initially false. Stored in state.
 * - setShowFilterText: A setter for the showFilterText state variable.
 * - displayValue: The string which should be used as the actual value parameter for the
 *      DropdownMenu. If filtering is not allowed, will be the same as value.
 * - onDropdownChange: A callback which should be passed as the onChange for the DropdownMenu
 *      component associated with this hook call. Handles filter text setting automatically. If
 *      other actions need to be taken in the onChange callback for a given DropdownMenu, this
 *      callback should be called within the new onChange for that DropdownMenu.
 * - onDropdownClick: A callback which should be passed as the onClick for the DropdownMenu
 *      component associated with this hook call. Works the same as onDropdownFocus. If other
 *      actions need to be taken in the onClick callback for a given DropdownMenu, this callback
 *      should be called within the new onClick for that DropdownMenu.
 * - onDropdownFocus: A callback which should be passed as the onFocus for the DropdownMenu
 *      component associated with this hook call. Handles necessary state changes when the text
 *      input for the dropdown is focused. If other actions need to be taken in the onFocus callback
 *      for a given DropdownMenu, this callback should be called within the new onFocus for that
 *      DropdownMenu.
 * - onItemClick: A callback which should be passed as the onClick for an option of the
 *      DropdownMenu. Takes a string representing the value for the given item. If other actions
 *      need to be taken in the onClick callback for a given option, this callback should be called
 *      within the onClick for that option.
 * - isItemSelected: A function that, given the string representing the value for a given item,
 *      returns true if that item is currently selected.
 *
 * @param filterable a boolean that, when true, allows the user to input text to the text input for
 *      the associated DropdownMenu to filter the options. Note that actually filtering the options
 *      needs to be handled externally using the filterText returned by this hook.
 * @param readOnly a boolean that, when true, disables modifying the value through the dropdown, and
 *      disables showing the popover menu on focus.
 * @param required a boolean that, when true, disables clearing the selected value of the dropdown
 *      once an option has been selected. This also adds a red star to the dropdown label.
 * @param aria-required a boolean that, when true, causes the dropdown to behave as if
 *      {@param required} were true, but without the red star.
 */
function useSingleDropdownMenu<T = string>({
    filterable = true,
    readOnly = false,
    rightButton,
    placeholder,
    required,
    "aria-required": ariaRequired,
    value,
    setValue,
    valueToString = DEFAULT_VALUE_TO_STRING,
    ...callbacks
}: UseSingleDropdownMenuProps<T>): UseSingleDropdownMenuResult<T> {
    const disableClearValue = ariaRequired || required;
    const { dropdownProps, filterText, setFilterText } = useDropdownMenu({
        filterable,
        readOnly,
        ...callbacks,
    });
    const dv = displayValue(filterable, filterText, value, valueToString);
    const onItemClick = useBrandedCallback(
        (itemValue: T) => {
            setValue(itemValue);
            dropdownProps.setShow(false);
        },
        [dropdownProps, setValue],
    );
    const isItemSelected = useBrandedCallback((itemValue: T) => value === itemValue, [value]);
    const cb = useBrandedMemo(
        () =>
            dropdownProps.show && (filterText || (!disableClearValue && value !== undefined))
                ? clearButton(() => {
                      if (filterText) {
                          setFilterText("");
                      } else {
                          setValue(undefined);
                      }
                  })
                : undefined,
        [disableClearValue, dropdownProps.show, filterText, setFilterText, setValue, value],
    );
    // The filter text being an empty string means the user has focused on the text box (but not
    // typed anything). If there is a value currently selected, we want to display that value as
    // the placeholder, but still use the old placeholder as the aria-placeholder.
    const isValuePlaceholder = filterText === "" && value !== undefined;
    return useMemo(
        () => ({
            dropdownProps: {
                ...dropdownProps,
                value: dv,
                required,
                rightButton: cb || rightButton,
                ...placeholderProps(placeholder, value, isValuePlaceholder, valueToString),
            },
            onItemClick,
            isItemSelected,
            filterText,
            setFilterText,
        }),
        [
            cb,
            dropdownProps,
            dv,
            filterText,
            isItemSelected,
            isValuePlaceholder,
            onItemClick,
            placeholder,
            required,
            rightButton,
            setFilterText,
            value,
            valueToString,
        ],
    );
}

interface UseInlineDropdownMenuProps<T = string>
    extends Omit<UseSingleDropdownMenuProps<T>, "rightButton"> {
    initialDisplay: string;
}

function useInlineDropdownMenu<T = string>({
    filterable = true,
    readOnly = false,
    initialDisplay,
    placeholder,
    value,
    setValue,
    valueToString,
    ...callbacks
}: UseInlineDropdownMenuProps<T>): UseSingleDropdownMenuResult<T> {
    const useSingleDropdownMenuResult = useSingleDropdownMenu({
        filterable,
        readOnly,
        placeholder,
        value,
        setValue,
        valueToString,
        ...callbacks,
    });
    const dropdownProps = useSingleDropdownMenuResult.dropdownProps;
    return useMemo(
        () => ({
            ...useSingleDropdownMenuResult,
            dropdownProps: {
                ...dropdownProps,
                value: dropdownProps.show
                    ? dropdownProps.value
                    : dropdownProps.value || initialDisplay,
            },
        }),
        [dropdownProps, initialDisplay, useSingleDropdownMenuResult],
    );
}

function multiValueDisplay(valueCount: number): string | undefined {
    return valueCount === 0 ? undefined : `(${valueCount} selected)`;
}

interface UseMultiDropdownMenuProps<T = string>
    extends UseDropdownMenuProps,
        Pick<DropdownMenuProps, "rightButton"> {
    values: ReadonlySet<T>;
    setValues: Dispatch<SetStateAction<ReadonlySet<T>>>;
    showNSelected?: boolean;
}

export type UseMultiDropdownMenuResultDropdownProps = UseSingleDropdownMenuResultDropdownProps &
    Required<Pick<DropdownMenuProps, "multiselect">> &
    Pick<DropdownMenuProps, "rightButton">;

export interface UseMultiDropdownMenuResult<T = string>
    extends UseDropdownMenuResult,
        UseDropdownMenuResultMixin<T> {
    dropdownProps: UseMultiDropdownMenuResultDropdownProps;
}

/**
 * This hook sets up the necessary state variables and callbacks for use with single dropdowns and
 * returns them. This hook should be used when making use of a DropdownMenu with one possible
 * value.
 * This hook should be called at whatever level the state of the DropdownMenu lives.
 *
 * The hook returns the following variables:
 * - show: Whether to show the dropdown menu. Initially false. Stored in state.
 * - setShow: A setter for the show state variable.
 * - values: The currently selected values for the dropdown, initially an empty set. Stored in
 * state.
 * - setValues: A setter for the values state variable.
 * - filterText: The current value of the filter text the user has supplied using the text input.
 *      Initially undefined. If filtering is not allowed or if the dropdown is read only, will
 *      remain undefined unless explicitly set using setFilterText. Stored in state.
 * - setFilterText: A setter for the filterText state variable.
 * - showFilterText: A boolean which, if true, shows the filterText as the displayValue rather than
 *      the value. Initially false. Stored in state.
 * - setShowFilterText: A setter for the showFilterText state variable.
 * - displayValue: The string which should be used as the actual value parameter for the
 *      DropdownMenu.
 * - onDropdownChange: A callback which should be passed as the onChange for the DropdownMenu
 *      component associated with this hook call. Handles filter text setting automatically. If
 *      other actions need to be taken in the onChange callback for a given DropdownMenu, this
 *      callback should be called within the new onChange for that DropdownMenu.
 * - onDropdownClick: A callback which should be passed as the onClick for the DropdownMenu
 *      component associated with this hook call. Works the same as onDropdownFocus. If other
 *      actions need to be taken in the onClick callback for a given DropdownMenu, this callback
 *      should be called within the new onClick for that DropdownMenu.
 * - onDropdownFocus: A callback which should be passed as the onFocus for the DropdownMenu
 *      component associated with this hook call. Handles necessary state changes when the text
 *      input for the dropdown is focused. If other actions need to be taken in the onFocus
 *      callback for a given DropdownMenu, this callback should be called within the new onFocus for
 *      that DropdownMenu.
 * - onItemClick: A callback which should be passed as the onClick for an option of the
 *      DropdownMenu. Takes a string representing the value for the given item. If other actions
 *      need to be taken in the onClick callback for a given option, this callback should be called
 *      within the onClick for that option.
 * - isItemSelected: A function that, given the string representing the value for a given item,
 *      returns true if that item is currently selected.
 *
 * @param filterable a boolean that, when true, allows the user to input text to the text input
 *      for the associated DropdownMenu to filter the options. Note that actually filtering the
 *      options needs to be handled externally using the filterText returned by this hook.
 * @param readOnly a boolean that, when true, disables modifying the value through the dropdown,
 *      and disables showing the popover menu on focus.
 */
function useMultiDropdownMenu<T = string>({
    filterable = true,
    readOnly = false,
    showNSelected = true,
    rightButton,
    placeholder,
    values,
    setValues,
    ...callbacks
}: UseMultiDropdownMenuProps<T>): UseMultiDropdownMenuResult<T> {
    const { dropdownProps, filterText, setFilterText } = useDropdownMenu({
        filterable,
        readOnly,
        ...callbacks,
    });
    const dv = displayValue(
        filterable,
        filterText,
        showNSelected ? multiValueDisplay(values.size) : "",
        (v) => v || "",
    );
    const onItemClick = useBrandedCallback(
        (itemValue: T) => {
            setValues((oldValues) => {
                const newValues = new Set<T>(oldValues);
                newValues.has(itemValue) ? newValues.delete(itemValue) : newValues.add(itemValue);
                return newValues;
            });
        },
        [setValues],
    );
    const isItemSelected = useBrandedCallback((itemValue: T) => values.has(itemValue), [values]);
    const cb = useBrandedMemo(
        () => (filterText ? clearButton(() => setFilterText("")) : undefined),
        [filterText, setFilterText],
    );
    // The filter text being an empty string means the user has focused on the text box (but not
    // typed anything). If there is a value currently selected, we want to display that value as
    // the placeholder, but still use the old placeholder as the aria-placeholder.
    const isValuePlaceholder = filterText === "" && values.size > 0;
    return useMemo(
        () => ({
            dropdownProps: {
                ...dropdownProps,
                value: dv,
                multiselect: true,
                rightButton: cb || rightButton,
                ...placeholderProps(
                    placeholder,
                    showNSelected ? multiValueDisplay(values.size) : placeholder,
                    isValuePlaceholder,
                    (v) => v || "",
                ),
            },
            onItemClick,
            isItemSelected,
            filterText,
            setFilterText,
        }),
        [
            cb,
            dropdownProps,
            dv,
            filterText,
            isItemSelected,
            isValuePlaceholder,
            onItemClick,
            placeholder,
            rightButton,
            setFilterText,
            showNSelected,
            values.size,
        ],
    );
}

function isOwnClearButton(element: Element, escapedContainerId: string): boolean {
    // The clear button is removed from the document on click, but can still appear in events after
    // it is removed from the document. If the button is not in the document, we just make sure
    // it's a clear button. Otherwise, we make sure it's inside the element with the given container
    // id.
    return document.contains(element)
        ? element.matches(`#${escapedContainerId} .bb-dropdown-menu__clear-button,
                    #${escapedContainerId} .bb-dropdown-menu__clear-button *`)
        : element.matches(`.bb-dropdown-menu__clear-button, .bb-dropdown-menu__clear-button *`);
}

function shouldRefocus(
    isMultiselect: boolean,
    escapedContainerId: string,
    event: Event,
    dropdownButtonRef: RefObject<HTMLButtonElement>,
): boolean {
    if (!(event.target instanceof Element)) {
        return false;
    }
    if (event.target.closest(".bb-popover-menu__item")) {
        return !isMultiselect;
    }
    return isMultiselect
        ? !dropdownButtonRef.current?.contains(event.target)
        : isOwnClearButton(event.target, escapedContainerId);
}

function shouldIgnoreClickOutside(eventTarget: EventTarget | null): boolean {
    // The clear button is removed from the document before the useDetectClickOutside handler fires,
    // so it's considered to be outside the dropdown. We want to ignore it and consider it part
    // of the dropdown.
    return (
        eventTarget instanceof Element
        && !document.contains(eventTarget)
        && eventTarget.matches(".bb-dropdown-menu__clear-button, .bb-dropdown-menu__clear-button *")
    );
}

export enum DropdownMenuPlacement {
    TOP,
    TOP_END,
    BOTTOM,
    BOTTOM_END,
}

const DROPDOWN_POPOVER_PLACEMENT: Record<DropdownMenuPlacement, PopoverMenuPlacement> = {
    [DropdownMenuPlacement.TOP]: PopoverMenuPlacement.TOP_START,
    [DropdownMenuPlacement.TOP_END]: PopoverMenuPlacement.TOP_END,
    [DropdownMenuPlacement.BOTTOM]: PopoverMenuPlacement.BOTTOM_START,
    [DropdownMenuPlacement.BOTTOM_END]: PopoverMenuPlacement.BOTTOM_END,
};

function alteratePlacements(
    placement: DropdownMenuPlacement,
    addEndOrBeginning: boolean,
): DropdownMenuPlacement[] {
    let placements = [placement];
    switch (placement) {
        case DropdownMenuPlacement.BOTTOM:
            placements.push(DropdownMenuPlacement.TOP);
            break;
        case DropdownMenuPlacement.BOTTOM_END:
            placements.push(DropdownMenuPlacement.TOP_END);
            break;
        case DropdownMenuPlacement.TOP:
            placements.push(DropdownMenuPlacement.BOTTOM);
            break;
        case DropdownMenuPlacement.TOP_END:
            placements.push(DropdownMenuPlacement.BOTTOM_END);
            break;
    }
    if (addEndOrBeginning) {
        placements = placements
            .map((p) => {
                switch (p) {
                    case DropdownMenuPlacement.BOTTOM:
                        return [p, DropdownMenuPlacement.BOTTOM_END];
                    case DropdownMenuPlacement.BOTTOM_END:
                        return [p, DropdownMenuPlacement.BOTTOM];
                    case DropdownMenuPlacement.TOP:
                        return [p, DropdownMenuPlacement.TOP_END];
                    case DropdownMenuPlacement.TOP_END:
                        return [p, DropdownMenuPlacement.TOP];
                }
            })
            .flat();
    }
    return placements;
}

export interface DropdownMenuProps
    extends Pick<
            BasePopoverMenuProps,
            "children" | "show" | "stickyFooter" | "stickyHeader" | "nesting"
        >,
        Pick<
            TextFieldProps,
            | "alignment"
            | "aria-placeholder"
            | "aria-required"
            | "autoFocus"
            | "error"
            | "errorMessage"
            | "helper"
            | "hideLabel"
            | "horizontal"
            | "info"
            | "label"
            | "leftIcon"
            | "name"
            | "onKeyDown"
            | "placeholder"
            | "readOnly"
            | "required"
            | "subLabel"
            | "suffix"
            | "value"
        >,
        EverIdProp {
    onBlur?: Memo<TextFieldProps["onBlur"]>;
    onChange?: Memo<TextFieldProps["onChange"]>;
    onClick?: Memo<TextFieldProps["onClick"]>;
    onFocus?: Memo<TextFieldProps["onFocus"]>;
    onScroll?: Memo<TextFieldProps["onScroll"]>;
    /**
     * An optional className to add to the root element of the dropdown.
     */
    className?: string;
    /**
     * Element to use to detect clicks or focus outside of. Generally should be left undefined.
     * Used internally.
     */
    detectClickOrFocusOutsideRef?: RefObject<HTMLElement>;
    /**
     * Whether to disable the dropdown. If true, will also disable the dropdown button.
     *
     * Default false.
     */
    disabled?: boolean;
    /**
     * Whether the dropdown menu is filterable. When false, marks the text input as read only.
     *
     * Default true.
     */
    filterable?: boolean;
    /**
     * Whether the dropdown allows multiple values to be selected. Note that the actual selection
     * of multiple elements needs to be handled using {@link DropdownMenu.useMulti} or something
     * similar; this just marks the aria-multiselectable prop correctly.
     */
    multiselect?: boolean;
    /**
     * Callback to apply when the user clicks outside the menu, or focuses outside the menu through
     * keyboard nav.
     *
     * Usually should just be the function returned in the dropdownProps from useDropdownMenu.
     */
    onDetectClickOrFocusOutside?: Memo<(event: Event, input: HTMLInputElement | null) => void>;
    /**
     * The placement for the dropdown. In most cases, this should be either
     * {@link PopoverPlacement.TOP} or {@link PopoverPlacement.BOTTOM}.
     *
     * If {@link PopoverPlacement.TOP} is provided, {@link PopoverPlacement.BOTTOM} will be added
     * as an alternate placement, and vice versa, in case the menu cannot fit within the viewport.
     *
     * If the menu is wider than the text input, then {@link PopoverPlacement.TOP_END} or
     * {@link PopoverPlacement.BOTTOM_END} will align the menu to the right edge of the input.
     * The menu alignment will automatically adjust if the menu is cut off by
     * the viewport. For example, if {@link placement} is {@link PopoverPlacement.BOTTOM} and
     * the menu is cut off on the right, then the dropdown will use the
     * {@link PopoverPlacement.BOTTOM_END} placement. If the menu still can't fit within the
     * viewport, it will attempt to position itself on the other side of the input, then at the
     * end.
     *
     * Default {@link PopoverPlacement.BOTTOM}.
     */
    placement?: DropdownMenuPlacement;
    /**
     * The button to place on the right of the dropdown. Will be placed to the left of the dropdown
     * button.
     */
    rightButton?: Memo<ReactElement<IconButtonProps>>;
    /**
     * The setter for the show prop.
     */
    setShow: Dispatch<SetStateAction<boolean>> | ((show: boolean) => void);
    /**
     * The width of the dropdown.
     * Default {@link TextFieldWidth.STANDARD}.
     */
    width?: Exclude<TextFieldProps["width"], TextFieldWidth.FLEXIBLE>;
    /**
     * Whether the menu popover should be wide enough to fit its contents horizontally. If true,
     * the menu will expand up to {@link menuMaxWidth} in order to fit the content.
     *
     * Defaults to false.
     */
    fitMenuContent?: boolean;
    /**
     * A custom max-width to apply to the menu. If not provided, the menu's width will match
     * the text input's width. Regardless of the value provided here, the menu popover will
     * never be narrower than the text input.
     *
     * Only applicable if {@link fitMenuContent} is true.
     * Defaults to "none".
     */
    menuMaxWidth?: CSSType.Property.MaxWidth;
    /**
     * A ref to the popover menu element of the dropdown menu.
     */
    popoverMenuRef?: Ref<HTMLDivElement>;
    /**
     * A ref to the element that should be focused when the dropdown menu closes. If not provided,
     * the dropdown input will be focused.
     */
    elementToFocusOnClose?: RefObject<HTMLElement>;
}

export interface DropdownMenuSubComponentsAndHooks {
    Button: FC<DropdownMenuButtonProps>;
    Checkbox: FFC<HTMLInputElement, DropdownMenuCheckboxProps>;
    CreateOption: FC<DropdownMenuCreateOptionProps>;
    Info: FC<DropdownMenuInfoProps>;
    LoadMore: FC<DropdownMenuLoadProps>;
    Option: FC<DropdownMenuOptionProps>;
    Section: FC<DropdownMenuSectionProps>;
    Supplement: FC<DropdownMenuSupplementProps>;
    useSingle: typeof useSingleDropdownMenu;
    useMulti: typeof useMultiDropdownMenu;
}

type DropdownRefMember = "inputWrapper" | "popover" | "outer";
const containerView: DropdownRefMember[] = ["inputWrapper", "popover"];
const detectClickView: DropdownRefMember[] = ["outer", "inputWrapper", "popover"];

export const DropdownMenu: FFC<HTMLInputElement, DropdownMenuProps> &
    DropdownMenuSubComponentsAndHooks = forwardRef(
    (
        {
            alignment,
            autoFocus,
            children,
            className,
            detectClickOrFocusOutsideRef: detectClickOrFocusOutsideRefProp,
            disabled,
            error,
            errorMessage,
            everId,
            filterable = true,
            helper,
            hideLabel,
            horizontal,
            info,
            label,
            leftIcon,
            multiselect = false,
            name,
            nesting,
            onDetectClickOrFocusOutside,
            onBlur,
            onChange,
            onClick,
            onFocus,
            onKeyDown,
            onScroll,
            placeholder,
            placement = DropdownMenuPlacement.BOTTOM,
            popoverMenuRef: popoverMenuRefProp,
            readOnly,
            required,
            rightButton,
            setShow,
            show = false,
            subLabel,
            suffix,
            value,
            width = TextFieldWidth.STANDARD,
            fitMenuContent = false,
            menuMaxWidth,
            elementToFocusOnClose,
            "aria-placeholder": ariaPlaceholder,
            "aria-required": ariaRequired,
            ...props
        },
        ref,
    ) => {
        filterable &&= !readOnly;
        const internalInputRef = useRef<HTMLInputElement>(null);
        elementToFocusOnClose ||= internalInputRef;
        // The popover (dropdown) is not actually a child of the text field, and so we need to use a resize
        // observer to get the width of the text field so that we can adjust the width of the popover
        // appropriately.
        const [dropdownMenuResizeRef, dropdownMenuResizeEntry] =
            useResizeObserver<HTMLDivElement>();
        const dropdownMenuWidth =
            dropdownMenuResizeEntry.target?.getBoundingClientRect().width + "px";
        const inputRef = useCombinedRef(ref, internalInputRef, dropdownMenuResizeRef);
        useEffect(() => {
            // This fixes some jitter when clicking on elements of the menu
            if (!show && internalInputRef.current) {
                internalInputRef.current.scrollLeft = 0;
            }
        }, [show]);

        const containerId = useId();
        const escapedContainerId = useMemo(() => CSS.escape(containerId), [containerId]);

        const containerRef = useMultiValueRef<DropdownRefMember, HTMLElement>(
            {
                inputWrapper: null,
                popover: null,
                outer: detectClickOrFocusOutsideRefProp?.current || null,
            },
            containerView,
        );
        useEffect(() => {
            containerRef.current["outer"] = detectClickOrFocusOutsideRefProp?.current || null;
        }, [containerRef, detectClickOrFocusOutsideRefProp]);
        const popoverMenuRef = useCombinedRef(containerRef("popover"), popoverMenuRefProp);

        useArrowNav(containerRef, {
            excludeSelectors: [`#${escapedContainerId} .bb-text-field__button`],
        });
        useFocusTrap(containerRef, show);

        const dropdownButtonRef = useRef<HTMLButtonElement>(null);
        const dropdownButton = (
            <IconButton
                className={"bb-dropdown-menu__dropdown-button"}
                disabled={disabled || readOnly}
                onClick={() => {
                    if (!show && filterable) {
                        internalInputRef.current?.focus();
                    }
                    setShow(!show);
                }}
                key={useId()}
                aria-label={show ? "Collapse" : "Expand"}
                ref={dropdownButtonRef}
            >
                {show ? <Icon.ChevronUp /> : <Icon.ChevronDown />}
            </IconButton>
        );
        const rightButtons: ReactElement<IconButtonProps>[] = [dropdownButton];
        if (rightButton) {
            rightButtons.unshift(rightButton);
        }

        const focusOnClose = useBrandedCallback(() => {
            // If the input should be focused on close, then it should be focused right away,
            // since the dropdown will re-open if the input is focused after the dropdown closes.
            // If not, then use setTimeout to focus the element after the dropdown closes.
            elementToFocusOnClose.current === internalInputRef.current
                ? elementToFocusOnClose.current?.focus()
                : setTimeout(() => elementToFocusOnClose.current?.focus());
        }, [elementToFocusOnClose]);

        useDetectClickOrFocusOutside(containerRef.withView(detectClickView), (e) => {
            if (!show) {
                return;
            }
            if (e instanceof KeyboardEvent && e.key === "Escape") {
                focusOnClose();
            }
            if (shouldIgnoreClickOutside(e.target)) {
                return;
            }
            onDetectClickOrFocusOutside?.(e, internalInputRef.current);
        });
        const onContainerClick = useBrandedCallback<EventListener>(
            (event) => {
                if (!show) {
                    return;
                }
                if (shouldRefocus(multiselect, escapedContainerId, event, dropdownButtonRef)) {
                    focusOnClose();
                }
            },
            [escapedContainerId, focusOnClose, multiselect, show],
        );
        useEventListener(containerRef, "click", onContainerClick);

        const menuId = useId();
        const inputId = useId();

        if (fitMenuContent) {
            // The popover width should match the width of its content. Use the provided max width,
            // if defined, or have no max width.
            menuMaxWidth ||= "none";
        } else if (width === TextFieldWidth.FULL) {
            // If not fitMenuContent, and the width is FULL, then we should use the width from
            // the resize observer on the text field (so that the popover grows and shrinks with
            // the width of the text field).
            menuMaxWidth = dropdownMenuWidth;
        } else {
            // In this case, the width of the popover should match the width of the text field (instead
            // of the width of the popover contents), and the width of the text field is a static,
            // defined value.
            menuMaxWidth = width;
        }

        const triggerRef = useRef<HTMLDivElement>(null);
        const inputWrapperRef = useCombinedRef(triggerRef, containerRef("inputWrapper"));

        return (
            <div
                className={clsx("bb-dropdown-menu", className, {
                    "bb-dropdown-menu--open": show,
                    "bb-dropdown-menu--filterable": filterable,
                    "bb-dropdown-menu--read-only": readOnly,
                    "bb-dropdown-menu--full-width": width === TextFieldWidth.FULL,
                    "bb-dropdown-menu--disabled": disabled,
                })}
                id={containerId}
            >
                <TextField
                    className={"bb-dropdown-menu__text-field"}
                    ref={inputRef}
                    // We need to put the trigger on the wrapper, because otherwise
                    // useDetectClickOutside will detect clicking on the dropdownButton as a click
                    // outside the menu and won't open the menu. For some reason this issue only
                    // happens when clicking on the padding, not on the SVG.
                    wrapperRef={inputWrapperRef}
                    alignment={alignment}
                    autoFocus={autoFocus}
                    disabled={disabled}
                    error={error}
                    errorMessage={errorMessage}
                    everId={everId}
                    helper={helper}
                    hideLabel={hideLabel}
                    horizontal={horizontal}
                    info={info}
                    label={label}
                    leftIcon={leftIcon}
                    name={name}
                    onBlur={onBlur}
                    onClick={onClick}
                    onChange={onChange}
                    onFocus={onFocus}
                    onKeyDown={(e) => {
                        onKeyDown?.(e);
                        // If the dropdown is within a modal or some other element that closes on
                        // escape, then hitting Esc while the text field is focused would also cause
                        // that ancestor element to close. When the dropdown is open, we want hitting
                        // Esc on the text field to only close the dropdown, so we stop propagation
                        // to prevent the ancestor element from also closing.
                        show && e.key === "Escape" && e.stopPropagation();
                    }}
                    onScroll={onScroll}
                    placeholder={placeholder}
                    // Occasionally, we want a non-filterable dropdown. In this case, we would mark
                    // the text field as read only, but mark its aria-readonly prop as false, hence the
                    // difference between readOnly and aria-readonly here.
                    readOnly={!filterable}
                    aria-readonly={readOnly}
                    required={required}
                    rightButtons={rightButtons}
                    role={"combobox"}
                    subLabel={subLabel}
                    suffix={suffix}
                    value={value}
                    width={width}
                    id={inputId}
                    aria-expanded={show}
                    aria-controls={show ? menuId : undefined}
                    aria-placeholder={ariaPlaceholder}
                    aria-required={ariaRequired}
                    active={show}
                    height={TextFieldHeight.LARGE}
                />
                <MenuContext.Provider value={{ isDropdown: true, isFilterable: filterable }}>
                    <BasePopoverMenu
                        {...props}
                        renderOutsideParent={true}
                        nesting={nesting}
                        show={show}
                        className={"bb-dropdown-menu__popover"}
                        minWidth={dropdownMenuWidth}
                        maxWidth={menuMaxWidth}
                        trigger={triggerRef}
                        arrow={false}
                        arrowHeight={0}
                        placement={alteratePlacements(
                            placement,
                            fitMenuContent || !!menuMaxWidth,
                        ).map((p) => DROPDOWN_POPOVER_PLACEMENT[p])}
                        role={"listbox"}
                        menuId={menuId}
                        aria-labelledby={`${inputId}__label`}
                        aria-multiselectable={multiselect}
                        aria-readonly={readOnly}
                        aria-required={ariaRequired || required}
                        rootRef={popoverMenuRef}
                    >
                        {children}
                    </BasePopoverMenu>
                </MenuContext.Provider>
            </div>
        );
    },
) as FFC<HTMLInputElement, DropdownMenuProps> & DropdownMenuSubComponentsAndHooks;
DropdownMenu.displayName = "DropdownMenu";

export interface InlineDropdownMenuProps
    extends Omit<
        DropdownMenuProps,
        | "autoFocus"
        | "error"
        | "errorMessage"
        | "helper"
        | "hideLabel"
        | "info"
        | "multiselect"
        | "rightButton"
    > {
    /**
     * The aria label to use for the caret down icon on the inline element. Defaults to "Expand".
     */
    arrowLabel?: string;
}

export const InlineDropdownMenu: FFC<HTMLInputElement, InlineDropdownMenuProps> & {
    use: typeof useInlineDropdownMenu;
} = forwardRef(
    ({ arrowLabel = "Expand", children, className, show, setShow, value, ...props }, ref) => {
        const focusRef = useRef<HTMLDivElement>(null);
        const inlineRef = useRef<HTMLSpanElement>(null);
        const internalDropdownRef = useRef<HTMLInputElement>(null);
        const dropdownRef = useCombinedRef(internalDropdownRef, ref);
        useEffect(() => {
            if (show) {
                internalDropdownRef.current?.focus();
            }
        }, [show]);
        const showDropdown = useBrandedCallback(() => setShow(true), [setShow]);
        const { buttonProps } = useButtonRole(inlineRef, {
            onClick: showDropdown,
            "aria-disabled": props.disabled || props.readOnly,
            enabled: !show,
        });
        return (
            <div
                className={clsx("bb-inline-dropdown-menu", className, {
                    "bb-inline-dropdown-menu--disabled": props.disabled,
                    "bb-inline-dropdown-menu--read-only": props.readOnly,
                })}
                ref={focusRef}
            >
                {show && (
                    <DropdownMenu
                        detectClickOrFocusOutsideRef={focusRef}
                        hideLabel={true}
                        ref={dropdownRef}
                        setShow={setShow}
                        show={show}
                        value={value}
                        elementToFocusOnClose={inlineRef}
                        {...props}
                    >
                        {children}
                    </DropdownMenu>
                )}
                <Span.Semibold
                    {...buttonProps}
                    className={clsx("bb-inline-dropdown-menu__inline", {
                        "bb-inline-dropdown-menu__inline--hidden": show,
                    })}
                    ref={inlineRef}
                >
                    {value}
                    {!props.readOnly && (
                        <Icon.CaretDown
                            aria-label={arrowLabel}
                            className={"bb-inline-dropdown-menu__expander"}
                            size={12}
                            color={
                                props.disabled
                                    ? ColorTokens.TEXT_SECONDARY
                                    : ColorTokens.TEXT_PRIMARY
                            }
                        />
                    )}
                </Span.Semibold>
            </div>
        );
    },
) as FFC<HTMLInputElement, InlineDropdownMenuProps> & { use: typeof useInlineDropdownMenu };
InlineDropdownMenu.displayName = "InlineDropdownMenu";
InlineDropdownMenu.use = useInlineDropdownMenu;

DropdownMenu.Button = Button;
DropdownMenu.Checkbox = Checkbox;
DropdownMenu.CreateOption = CreateOption;
DropdownMenu.Info = Info;
DropdownMenu.LoadMore = Load;
DropdownMenu.Option = Option;
DropdownMenu.Section = Section;
DropdownMenu.Supplement = Supplement;
DropdownMenu.useSingle = useSingleDropdownMenu;
DropdownMenu.useMulti = useMultiDropdownMenu;
