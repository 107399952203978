import clsx from "clsx";
import { everIdProp } from "EverAttribute/EverId";
import React, { ReactNode, FC } from "react";
import "./Section.scss";
import { EverIdProp } from "util/type";

export interface MenuSectionProps extends EverIdProp {
    /**
     * Contents of the section.
     */
    children?: ReactNode;
    /**
     * Describes the purpose of the section.
     */
    header?: React.ReactNode;
    /**
     * If true, adds an extra left margin to the section header. Use this prop if the options
     * in this section have color strips and also have either checkboxes or unselectable options.
     * In these situations, the color strip causes the header to be misaligned with the option,
     * so an extra margin is needed to align them.
     */
    colorStripExtraMargin?: boolean;
}

/**
 * Used to organize popover menu sections. Will create borders between sections and format a section
 * header. Has the "group" aria role. This is not necessary for a single section lacking a header.
 */
export const Section: FC<MenuSectionProps> = ({
    header,
    children,
    everId,
    colorStripExtraMargin,
    ...props
}) => {
    return (
        <div
            className={clsx("bb-popover-menu__section", {
                "bb-popover-menu__section--headerless": !header,
            })}
            role={"group"}
            {...everIdProp(everId)}
            {...props}
        >
            {header && (
                <div
                    className={clsx("bb-popover-menu__section-header", {
                        "bb-popover-menu__section-header--extra-margin": colorStripExtraMargin,
                    })}
                >
                    {header}
                </div>
            )}
            <div className={"bb-popover-menu__section-children"}>{children}</div>
        </div>
    );
};
