import { useStorage } from "hooks/useStorage";
import { Dispatch, SetStateAction } from "react";

/**
 * This hook acts like {@link useState}, but persists the state to {@link localStorage}. The stored
 * value must be serializable to JSON. The next time the component is rendered, the state will be
 * initialized from {@link localStorage} (if it exists there) instead of using {@link initialValue}.
 * @param key The key that the value is saved under in {@link localStorage}.
 * @param initialValue The initial value to saved to {@link localStorage} and returned if there is
 * no value for {@link key} in {@link localStorage}. This value must be serializable to JSON.
 */
export function useLocalStorage<T>(
    key: string,
    initialValue: T | (() => T),
): [T, Dispatch<SetStateAction<T>>] {
    return useStorage("localStorage", key, initialValue);
}
