import { Memo, useBrandedCallback } from "hooks/useBranded";

export type EventFilter<E> = (event: E) => boolean;

/**
 * This hook provides a way to wrap your handler with a filter, that prevents your handler from
 * being called if the filter fails. In most cases, this is used in conjunction with
 * {@link useCssSelectorFilter} as an alternative to stopPropagation, but can be used in a variety
 * of other cases.
 *
 * @param handler the handler to call when the given filter returns true
 *      OR [
 *          the handler to call when the given filter returns true,
 *          the handler to call when the given filter returns false
 *      ]
 * @param filter the filter to wrap the provided handler in. If the filter returns true, the
 *      handler will be called (or, the "included" handler if provided with an array). If the
 *      filter returns false, nothing will happen (or, the "excluded" handler will be called, if
 *      provided with an array).
 */
export function useFilteredEventHandler<E extends { target: unknown }>(
    handler:
        | Memo<(event: E) => void>
        | [Memo<(event: E) => void> | undefined, Memo<(event: E) => void> | undefined]
        | undefined,
    filter: Memo<EventFilter<E>>,
): Memo<(event: E) => void> {
    const [includedHandler, excludedHandler]: [
        ((event: E) => void) | undefined,
        ((event: E) => void) | undefined,
    ] = Array.isArray(handler) ? handler : [handler, undefined];
    return useBrandedCallback(
        (event: E) => {
            if (filter(event)) {
                excludedHandler?.(event);
            } else {
                includedHandler?.(event);
            }
        },
        [excludedHandler, filter, includedHandler],
    );
}
