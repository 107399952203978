import clsx from "clsx";
import { Span } from "components/Text";
import React, { CSSProperties, FC, ReactElement, ReactNode } from "react";
import "./ProgressBar.scss";
import { everIdProp } from "EverAttribute/EverId";
import * as ColorTokens from "tokens/typescript/ColorTokens";
import { EverColor } from "tokens/typescript/EverColor";
import { Num } from "core";
import { EverIdProp } from "util/type";

enum ProgressBarVariant {
    ROUNDED = "rounded",
    DETAIL_CARD = "detail-card",
}

interface BaseProgressBarProps extends EverIdProp {
    /**
     * A number between 0 and 1 representing the current progress to display.
     */
    progress: number;
    /**
     * The aria label to apply to the progress bar.
     */
    "aria-label": string;
    /**
     * The color for the bar.
     */
    barColor?: string;
    /**
     * An optional className to apply to the progress bar.
     */
    className?: string;
    /**
     * The color for the text within the progress bar.
     */
    textColor?: string;
    /**
     * The variant of the progress bar. Defaults to ROUNDED.
     */
    variant?: ProgressBarVariant;
    /**
     * The overlay information for the progress bar, e.g. status, eta, etc.
     */
    children: ReactNode;
}

interface ProgressBarCSSProperties extends CSSProperties {
    "--bb-progressBar-color-bar": string;
    "--bb-progressBar-color-text": string;
    "--bb-progressBar-progress": string;
}

/**
 * A base component for progress bars. This component takes children as one of its props, which can
 * be used to add overlay info on the progress bar, e.g. status, eta, etc.
 */
export const BaseProgressBar: FC<BaseProgressBarProps> = ({
    progress,
    className,
    everId,
    barColor = EverColor.GREEN_30,
    textColor = ColorTokens.TEXT_PRIMARY,
    "aria-label": ariaLabel,
    variant = ProgressBarVariant.ROUNDED,
    children,
}) => {
    // TODO pulse
    const progressPercentage = Num.progress(Num.clamp(progress, 0.0, 1.0));
    const style: ProgressBarCSSProperties = {
        "--bb-progressBar-color-bar": barColor,
        "--bb-progressBar-color-text": textColor,
        "--bb-progressBar-progress": progressPercentage + "%",
    };
    return (
        <div
            className={clsx("bb-progress-bar", `bb-progress-bar--${variant}`, className)}
            style={style}
            role={"progressbar"}
            aria-valuemin={0}
            aria-valuenow={progressPercentage}
            aria-valuemax={100}
            aria-label={ariaLabel}
            {...everIdProp(everId)}
        >
            <div className={"bb-progress-bar__bar"} />
            <div className={"bb-progress-bar__progress"}>{children}</div>
        </div>
    );
};

export interface ProgressBarProps extends EverIdProp, Omit<BaseProgressBarProps, "children"> {
    /**
     * An optional string representing the ETA of the task.
     */
    eta?: string;
}

/**
 * A simple progress bar that displays a progress percentage, as well as an ETA if applicable.
 *
 * This is a spec component, and has not actually been completely defined by design. It can be
 * used as-is, but will be updated when the official component is defined and implemented.
 */
export const ProgressBar: FC<ProgressBarProps> & { DetailCard: FC<DetailCardProgressBarProps> } = ({
    className,
    eta,
    ...props
}) => {
    // TODO pulse
    const progressPercentage = Num.progress(Num.clamp(props.progress, 0.0, 1.0));
    const etaDisplay = eta ? `(${eta})` : "";

    return (
        <BaseProgressBar {...props}>
            <span>
                {progressPercentage + "%"} {etaDisplay}
            </span>
        </BaseProgressBar>
    );
};

export interface DetailCardProgressBarProps
    extends EverIdProp,
        Omit<BaseProgressBarProps, "children" | "variant"> {
    /**
     * The text for the current stage, e.g. producing. Only used when variant is CARD.
     */
    stageText?: string;
    /**
     * The text for the status, e.g. 1/4 documents. Only used when variant is CARD.
     */
    statusText?: string;
}

/**
 * A variant of progress bar used for detail cards. This variant does not have rounded corners and
 * optionally allows for stage and status text to be overlaid on the progress bar.
 */
function DetailCardProgressBar({
    stageText,
    statusText,
    className,
    ...props
}: DetailCardProgressBarProps): ReactElement<DetailCardProgressBarProps> {
    return (
        <BaseProgressBar {...props} variant={ProgressBarVariant.DETAIL_CARD}>
            <div className={"bb-progress-bar__stage"}>
                <Span.Overline>{stageText}</Span.Overline>
            </div>
            <div className={"bb-progress-bar__status"}>
                <Span.Small>{statusText}</Span.Small>
            </div>
        </BaseProgressBar>
    );
}
ProgressBar.DetailCard = DetailCardProgressBar;
