import { Renderer } from "components/Scrollbar/Scrollbar";
import React, { HTMLAttributes, CSSProperties } from "react";

export const renderViewDefault: Renderer = (props: HTMLAttributes<HTMLDivElement>) => {
    return <div {...props} />;
};

export const renderTrackHorizontalDefault: Renderer = ({ style, ...props }) => {
    const finalStyle: CSSProperties = {
        ...style,
        right: 2,
        bottom: 2,
        left: 2,
        borderRadius: 4,
        padding: 4,
    };
    return <div style={finalStyle} {...props} />;
};

export const renderTrackVerticalDefault: Renderer = ({ style, ...props }) => {
    const finalStyle: CSSProperties = {
        ...style,
        right: 2,
        bottom: 2,
        top: 2,
        borderRadius: 4,
        padding: 4,
    };
    return <div style={finalStyle} {...props} />;
};

export const renderThumbHorizontalDefault: Renderer = ({ style, ...props }) => {
    const finalStyle: CSSProperties = {
        ...style,
        cursor: "pointer",
        borderRadius: "inherit",
        backgroundColor: "rgba(0, 0, 0, .2)",
    };
    return <div style={finalStyle} {...props} />;
};

export const renderThumbVerticalDefault: Renderer = ({ style, ...props }) => {
    const finalStyle: CSSProperties = {
        ...style,
        cursor: "pointer",
        borderRadius: "inherit",
        backgroundColor: "rgba(0, 0, 0, .2)",
    };
    return <div style={finalStyle} {...props} />;
};
