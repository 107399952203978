import { INTERACTIVE_ELEMENT_SELECTORS, useCssSelectorFilter } from "hooks/useCssSelectorFilter";
import { useBrandedCallback } from "hooks/useBranded";
import { useEventListener } from "hooks/useEventListener";
import { useFilteredEventListener } from "hooks/useFilteredEventListener";
import { Dispatch, RefObject, SetStateAction, useRef, useState } from "react";

/**
 * A hook which simulates the active state for a given element. In general, this should be avoided
 * in favor of the native :active. However, this is sometimes necessary when you don't want active
 * styles to be applied when clicking on clickable child elements.
 *
 * @param ref a reference to the root element you want to track active state for
 * @param triggerKeys the keys that will trigger the active state. defaults to space and enter.
 * @param excludedSelectors any elements that should be excluded from the active state when
 *      clicking or pressing the given triggerKeys
 */
export function useActive(
    ref: RefObject<Element>,
    triggerKeys: string[] = ["Enter", " "],
    excludedSelectors: string[] = INTERACTIVE_ELEMENT_SELECTORS,
): [boolean, Dispatch<SetStateAction<boolean>>] {
    const [active, setActive] = useState(false);
    const startActive = useBrandedCallback(() => setActive(true), []);
    const endActive = useBrandedCallback(() => setActive(false), []);
    const filter = useCssSelectorFilter(ref, excludedSelectors);
    useFilteredEventListener(ref, "mousedown", startActive, filter);
    useFilteredEventListener(
        ref,
        "keydown",
        useBrandedCallback<EventListener>(
            (e) => {
                if (e instanceof KeyboardEvent && triggerKeys.includes(e.key)) {
                    startActive();
                }
            },
            // Disabling exhaustive deps so that trigger keys don't cause the callback to be
            // recomputed every render
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [startActive, triggerKeys.join(",")],
        ),
        filter,
    );
    useFilteredEventListener(
        ref,
        "keyup",
        useBrandedCallback<EventListener>(
            (e) => {
                if (e instanceof KeyboardEvent && triggerKeys.includes(e.key)) {
                    endActive();
                }
            },
            // Disabling exhaustive deps so that trigger keys don't cause the callback to be
            // recomputed every render
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [endActive, triggerKeys.join(",")],
        ),
        filter,
    );
    useFilteredEventListener(ref, "focusout", endActive, filter);
    const documentRef = useRef(document);
    useEventListener(documentRef, "dragend", endActive);
    useEventListener(documentRef, "mouseup", endActive);
    return [active, setActive];
}
