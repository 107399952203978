import { Num } from "core";
import { KiB, KB, MiB, MB, GiB, GB, TiB, TB, SEC, YR, HR, MIN, DAY, WK, MO } from "./constants";

export function ellipsify(string: string, maxLength: number): string {
    if (!string) {
        return "";
    } else if (string.length <= maxLength) {
        return string;
    }
    return string.substring(0, maxLength - 1) + "…"; // unicode ellispsis
}

interface MiddleEllipsifyResult {
    path: string;
    isEllipsed: boolean;
}

/**
 * Helper method to ellipsify a path value such that the root folder and parent folder are always
 * shown. Ellipses the middle folders if the last subfolder is not displayed. Ellipses the root
 * folder, then the parent folder if the resulting string still exceeds the provided length. Ensures
 * at least four characters are present in ellipsified strings. Returns whether the path was
 * ellipsified.
 *
 * Examples of ellipsification:
 * 1. No ellipsification (if provided a .zip). Should be in format /filename
 *      /filename --> /filename
 * 2. No ellipsification (standard path):
 *      /rootFolder/parentFolder/fileName --> /rootfolder/parentFolder
 * 3. End ellipsification:
 *      /rootFolder/parentFolderExtraLongHere/fileName --> /root…/parentFolderExtraLon…
 * 4. Middle ellipsification:
 *      /rootFolder/grandParentFolder/parentFolder --> /rootFolder/…/parentFolder
 * 5. Middle + end ellipsification:
 *      /rootFolder/grandParentFolder/parentFolderExtraLongHere --> /root…/…/parentFolderExtraL…
 */
export function middleEllipsifyPath(path: string, maxLength: number): MiddleEllipsifyResult {
    // Trimming off name of the file from the path, along with the leading [/]
    const lastFolderIndex = path.lastIndexOf("/");
    if (lastFolderIndex === 0) {
        // No parent folders
        return { path: ellipsify(path, maxLength), isEllipsed: false };
    }
    const shortPath = path.substring(1, lastFolderIndex);

    let returnPath = shortPath;
    const ellipsed = shortPath.length > maxLength;

    if (ellipsed) {
        const firstFolderIndex = shortPath.indexOf("/", 0);
        let middleSegment = "/…";
        if (firstFolderIndex === -1) {
            // No subfolders
            return { path: ellipsify("/" + shortPath, maxLength), isEllipsed: ellipsed };
        } else if (firstFolderIndex === shortPath.lastIndexOf("/")) {
            // One subfolder
            middleSegment = "";
        }
        let firstSegment = shortPath.substring(0, firstFolderIndex);
        let lastSegment = shortPath.substring(shortPath.lastIndexOf("/"));
        let extraLength =
            firstSegment.length + middleSegment.length + lastSegment.length - maxLength;

        // If middle ellipsification was insufficient, ellipsify further.
        if (extraLength > 0) {
            // Root folder ellipsification is insufficient, ellipsify parent folder too.
            if (firstSegment.length - extraLength < 4) {
                extraLength = extraLength - firstSegment.length + 5;
                firstSegment = ellipsify(firstSegment, 5);
                lastSegment = ellipsify(lastSegment, lastSegment.length - extraLength);
            } else {
                firstSegment = ellipsify(firstSegment, firstSegment.length - extraLength);
            }
        }
        returnPath = firstSegment + middleSegment + lastSegment;
    }
    return { path: "/" + returnPath, isEllipsed: ellipsed };
}

/**
 * Breaks an input string into multiple lines and ellipsifies the last line if necessary.
 *
 * Removes spaces used for line breaks, but assumes any other weird spacing (leading or trailing
 * spaces, or several spaces in a row) is intentional.
 */
export function multiLineEllipsify(
    text: string,
    maxLines: number,
    maxCharactersPerLine: number,
): string[] {
    const ellipsified = [];
    for (let i = 0; i < maxLines - 1; i++) {
        if (text.length <= maxCharactersPerLine) {
            ellipsified.push(text);
            return ellipsified;
        }
        const breakIdx = text.lastIndexOf(" ", maxCharactersPerLine);
        if (breakIdx < 0) {
            ellipsified.push(ellipsify(text, maxCharactersPerLine));
            return ellipsified;
        } else {
            ellipsified.push(text.slice(0, breakIdx));
            text = text.slice(breakIdx + 1);
        }
    }
    ellipsified.push(ellipsify(text, maxCharactersPerLine));
    return ellipsified;
}

const RANDOM_STRING_CHARS = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

/**
 * Returns a random string of letters and numbers of the specified length.
 * @param length the length of the random string to return
 * @param sampleString the characters to use to generate the random string, defaults to alphanumeric
 */
export function randomString(length: number, sampleString = RANDOM_STRING_CHARS): string {
    let result = "";
    for (let i = 0; i < length; i++) {
        result += sampleString.charAt(Math.floor(Math.random() * sampleString.length));
    }
    return result;
}

/**
 * Converts a number to a locale-specific string.
 */
export function num(n: number, options?: Intl.NumberFormatOptions): string {
    return n.toLocaleString("en-US", options);
}

/**
 * Converts a number to a locale-specific string rounded to a specified number of decimal places.
 * For example, numWithPlaces(1.2345, 3) returns "1.235".
 */
export function numWithPlaces(n: number, places: number): string {
    return num(n, { minimumFractionDigits: places, maximumFractionDigits: places });
}

export function pluralForm(singular: string, count: number, plural = singular + "s"): string {
    return count === 1 || count === -1 ? singular : plural;
}

export function countOf(count: number, noun: string, plural: string = noun + "s"): string {
    return `${num(count)} ${pluralForm(noun, count, plural)}`;
}

export function capitalize(str: string): string {
    if (str === "") {
        return "";
    }
    return str[0].toUpperCase() + str.slice(1);
}

export function uncapitalize(string: string): string {
    if (string === "") {
        return "";
    }
    return string[0].toLowerCase() + string.slice(1);
}

/**
 * Escapes the given string for use inside a regex. Special characters will be treated like
 * regular characters rather than special regex tokens.
 */
export function escapeRegExp(str: string) {
    return str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

/**
 * If the given value is a string or a valid number, returns the string value of the given value.
 * Otherwise, returns "".
 */
export function toString(a: unknown): string {
    if (typeof a === "number") {
        return isNaN(a) || a === Infinity || a === -Infinity ? "" : String(a);
    }
    if (typeof a === "string") {
        return a;
    }
    return "";
}

/**
 * Possible options for displaying a number using the {@link displayNumber} function.
 */
interface DisplayNumberProps {
    /**
     * If true, uses base-2 units instead of base-10 units to calculate the displayed value.
     *
     * For instance, if base2 is enabled, uses KiB, MiB, GiB, and TiB, instead of KB, MB, GB, and
     * TB, respectively.
     *
     * Defaults to false.
     */
    base2?: boolean;
    /**
     * The exact number of decimal places to include after the decimal, no more, no less.
     *
     * If the {@link DisplayNumberProps.places} prop is provided, this prop has no effect.
     *
     * If neither are provided, one decimal place is displayed when the final displayed number is
     * less than 10, and 0 otherwise. For instance, 9,900 would display as 9.9K, while 10,000 would
     * display as 10K, when no places or decimalPlaces are provided.
     */
    decimalPlaces?: number;
    /**
     * The number of places to display in the final number, including digits before and after the
     * decimal. This prop overrides {@link DisplayNumberProps.decimalPlaces}.
     */
    places?: number;
    /**
     * Determines whether to use the metric unit for billions (giga, "G") or not ("B").
     *
     * Defaults to false.
     */
    useMetricUnit?: boolean;
    /**
     * Whether to insert a space between the number and the unit.
     *
     * Defaults to false.
     */
    addSpace?: boolean;
    /**
     * If provided, will cause the units to always be lowercase (if false) or uppercase (if true).
     *
     * When left undefined (the default), "k" will be lowercase, while all other units ("M",
     * "G"/"B") will be uppercase.
     */
    uppercase?: boolean;
    /**
     * If true, will set the minimum number of decimal places to appear to be 0, rather than
     * whatever is determined by {@link DisplayNumberProps.decimalPlaces}/
     * {@link DisplayNumberProps.places}. For instance, if decimalPlaces is 1, and the number to
     * be displayed is between 0 and 0.04999..., "0" will be the result, rather than "0.0".
     * Similarly, if decimalPlaces is 2, and the number to be displayed is between 0.1 and
     * 0.104999..., "0.1" will be the result, rather than "0.10".
     *
     * Defaults to false.
     */
    collapseDecimalZero?: boolean;
}

/**
 * Format/abbreviate num for display using Math.abs(num) to handle negative numbers, and prepending
 * a "-" to the results for negative nums.
 * 0-999: as is (fractions get rounded)
 * 1_000-9_999: 0.0k (e.g. 1.9k)
 * 10_000-999_999: 0k (e.g. 10k, 100k)
 * 1_000_000-9_999_999: 0.0M
 * 10_000_000+: 0M
 */
export function displayNumber(
    number: number,
    {
        base2 = false,
        places,
        decimalPlaces,
        useMetricUnit = false,
        addSpace = false,
        uppercase,
        collapseDecimalZero = false,
    }: DisplayNumberProps = {},
): string {
    const K = base2 ? KiB : KB;
    const M = base2 ? MiB : MB;
    const G = base2 ? GiB : GB;
    const T = base2 ? TiB : TB;

    let unitName = "T";
    let unit = T;
    const checkNum = Math.abs(number);

    // We want to set this limit to a power of 10, even if we're still displaying
    // filesizes (which are powers of 2).  This prevents 4 digits being used in edge
    // cases (ex display "0.9 KB" instead of "1021 B").
    if (checkNum < K) {
        // [0, 1K)
        unit = 1;
        unitName = "";
    } else if (checkNum < M) {
        // [1K, 1M)
        unitName = "K";
        unit = K;
    } else if (checkNum < G) {
        // [1M, 1G)
        unitName = "M";
        unit = M;
    } else if (checkNum < T) {
        // [1G, 1T)
        unitName = useMetricUnit ? "G" : "B";
        unit = G;
    } // else [1T, INF)
    if (uppercase !== undefined) {
        unitName = uppercase ? unitName.toUpperCase() : unitName.toLowerCase();
    }

    const numPerUnit = checkNum / unit;
    // Count digits before the decimal point towards places.
    let actualPlaces: number;
    let tmp = 1;
    if (places !== undefined) {
        while (tmp <= numPerUnit && places > 0) {
            tmp *= 10;
            places -= 1;
        }
        actualPlaces = places;
    } else if (decimalPlaces !== undefined) {
        actualPlaces = decimalPlaces;
    } else {
        actualPlaces = numPerUnit < 10 && unit > 1 ? 1 : 0;
    }
    return (
        (number < 0 ? "-" : "")
        + num(numPerUnit, {
            minimumFractionDigits: collapseDecimalZero ? 0 : actualPlaces,
            maximumFractionDigits: actualPlaces,
        })
        + (addSpace ? " " : "")
        + unitName
    );
}

export function displaySize(
    bytes: number,
    { addSpace = true, ...props }: Omit<DisplayNumberProps, "useMetricUnit" | "uppercase"> = {},
): string {
    return (
        displayNumber(bytes, {
            ...props,
            addSpace,
            useMetricUnit: true,
        }) + "B"
    );
}

interface DateSinceOrUntilProps {
    /**
     * A minimum value to clamp the given date to, specified in milliseconds. If provided,
     * and the date provided to {@link displayDateSinceOrUntil} is less than the given minValue,
     * will instead display, for instance, "<1d" (if the minValue is 1 * DAY).
     */
    minValue?: number;
    /**
     * A maximum value to clamp the given date to, specified in milliseconds. If provided,
     * and the date provided to {@link displayDateSinceOrUntil} is greater than the given minValue,
     * will instead display, for instance, ">1d" (if the maxValue is 1 * DAY).
     */
    maxValue?: number;
    /**
     * The prefix to place before the given date if the given date is in the past. Defaults to
     * undefined.
     */
    sincePrefix?: string;
    /**
     * The suffix to place after the given date if the given date is in the past. Defaults to
     * " ago".
     */
    sinceSuffix?: string;
    /**
     * The prefix to place before the given date if the given date is in the future. Defaults to
     * "in ".
     */
    untilPrefix?: string;
    /**
     * The prefix to place after the given date if the given date is in the future. Defaults to
     * undefined.
     */
    untilSuffix?: string;
    /**
     * Whether to place a space between the displayed value and the calculated unit (e.g. 1 day
     * vs. 1day). Defaults to false.
     */
    addSpace?: boolean;
    /**
     * Whether to pluralize the units when displaying the given date. If plurals are provided to
     * the units (see {@link DateSinceOrUntilProps.units}), will use the provided plural, otherwise
     * just adds an "s". Defaults to false.
     */
    pluralize?: boolean;
    /**
     * If provided, and the calculated display value is less than the smallest unit provided,
     * this string prop will be returned rather than, for instance, 0d.
     *
     * E.g. if nowDisplay = "today" and the smallest unit provided is DAY, and the given value
     * is < 1 day ago / < 1 day in the future, will instead return "today".
     */
    nowDisplay?: string;
    /**
     * The units to use when calculating the display. Unit values are specified in the keys
     * of the given object in ms (for instance, Constants.SEC, Constants.DAY, etc.), and unit names
     * are specified as strings, or optionally as a tuple of [string, string] where the first
     * string is the singular and the second is the plural of the unit. Plurals have no effect
     * unless {@link DateSinceOrUntilProps.pluralize} is true.
     *
     * Defaults to {@link DATE_UNITS}.
     */
    units?: Record<number, string | [string, string]>;
}

const DATE_UNITS: Record<number, string | [string, string]> = {
    [SEC]: "s",
    [MIN]: "min",
    [HR]: "h",
    [DAY]: "d",
    [WK]: "w",
    [MO]: "mo",
    [YR]: "y",
};

/**
 * A function that, given a date, displays the time since (if in the past) or until (if in the
 * future).
 *
 * Can be customized to calculate in terms of seconds, weeks, years, etc., and will
 * choose the smallest possible unit of those provided, rounding to the nearest value.
 *
 * If the time since/until, when rounded to the nearest unit, is 0, and a value is provided for
 * nowDisplay, will display that string instead.
 *
 * @param date the date to base the computation on, either as a Date or epoch ms.
 */
export function displayDateSinceOrUntil(
    date: number | Date,
    {
        minValue,
        maxValue,
        sincePrefix,
        sinceSuffix = " ago",
        untilPrefix = "in ",
        untilSuffix,
        addSpace = false,
        nowDisplay,
        units = DATE_UNITS,
        pluralize: plural = false,
    }: DateSinceOrUntilProps = {},
): string {
    if (typeof date !== "number") {
        date = date.getTime();
    }
    const diff = Math.abs(Date.now() - date);
    const since = Date.now() > date;
    // Find the smallest unit to start with
    let unit = Math.min(...Object.keys(units).map(Number.parseFloat));
    let value = Num.clamp(diff, minValue, maxValue);
    // Iterate through all the given units to find the largest unit for which the value is greater
    for (const u of Object.keys(units)) {
        const parsedUnit = Number.parseFloat(u);
        // If the value is greater than the given unit, and the given unit is greater than the
        // current unit, use the given unit instead.
        if (value >= parsedUnit && parsedUnit > unit) {
            unit = parsedUnit;
        }
    }
    let unitName = units[unit];
    value = Math.round(value / unit);
    let display: string;
    if (nowDisplay !== undefined && value === 0) {
        // This can happen if the units specified start at, for instance, days, and the given
        // date is within 24 hours from now. In this case, we will use the given nowDisplay
        // (in this example, probably "Today") instead of displaying 0d.
        return nowDisplay;
    } else if (maxValue !== undefined && diff > maxValue) {
        // If a max value is provided (for instance, 1 * YEAR), and the given date was more than
        // a year ago/a year in the future, we will display ">1y ago"/"in >1y".
        display = `>${value}`;
    } else if (minValue !== undefined && diff < minValue) {
        // If a max value is provided (for instance, 1 * DAY), and the given date was more than
        // a day ago/a day in the future, we will display "<1d ago"/"in <1d".
        display = `<${value}`;
    } else {
        display = value.toString();
    }
    let pluralUnitName: string | undefined;
    // Callers of this function can optionally provide plural unit names (for instance, week
    // vs. weeks). In cases where units are shortened (e.g. wk, hr, min), this is usually not
    // desired, however.
    if (Array.isArray(unitName)) {
        pluralUnitName = unitName[1];
        unitName = unitName[0];
    } else {
        pluralUnitName = undefined;
    }
    display += `${addSpace ? " " : ""}${
        plural ? pluralForm(unitName, value, pluralUnitName) : unitName
    }`;
    display = `${(since ? sincePrefix : untilPrefix) || ""}${display}${
        (since ? sinceSuffix : untilSuffix) || ""
    }`;
    return display;
}

/**
 * Displays the given date in YYYY/MM/DD format.
 *
 * @param date the date to display, as either a Date or in epoch ms.
 */
export function displayDate(date: number | Date): string {
    if (typeof date === "number") {
        date = new Date(date);
    }
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}/${month}/${day}`;
}
