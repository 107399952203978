import clsx from "clsx";
import { IconButton } from "components/Button";
import * as Icon from "components/Icon";
import { PopoverMenu } from "components/Menu";
import { Tooltip } from "components/Tooltip";
import { PopoverPlacement } from "components/util/BasePopover";
import { ArrowNavDirection, useArrowNav } from "hooks/useArrowNav";
import { Memo } from "hooks/useBranded";
import { useCombinedRef } from "hooks/useCombinedRef";
import { useLatest } from "hooks/useLatest";
import { useResizeObserver } from "hooks/useResizeObserver";
import React, {
    cloneElement,
    CSSProperties,
    Dispatch,
    ReactElement,
    ReactNode,
    SetStateAction,
    useEffect,
    useId,
    useRef,
    useState,
} from "react";
import { CSSTransition } from "react-transition-group";
import * as NavigationBarTokens from "tokens/typescript/NavigationBarTokens";
import * as SpacingTokens from "tokens/typescript/SpacingTokens";
import { SEC } from "util/constants";
import { getSizePx } from "util/css";
import "./NavigationBar.scss";

const SELECT_TRANSITION_TIME = 250;

export enum NavigationTabSize {
    SMALL = "small",
    LARGE = "large",
}

const TAB_SIZE_SPACING_MAP = {
    [NavigationTabSize.SMALL]: getSizePx(SpacingTokens.NAVIGATION_TAB_SMALL_BETWEEN),
    [NavigationTabSize.LARGE]: getSizePx(SpacingTokens.NAVIGATION_TAB_LARGE_BETWEEN),
};

export interface NavigationBarProps<K extends string = string> {
    /**
     * An optional class name to add to the component.
     */
    className?: string;
    /**
     * An ordered list of {@link Tab}s to render inside the navigation bar.
     *
     * Note: this array must be memoized or defined outside any functions so that it retains
     * referential equality between renders.
     */
    children: Memo<ReactElement<NavigationTabProps<K>>[]>;
    /**
     * A state variable containing the id of the selected tab.
     */
    selected: K;
    /**
     * The setter for the {@link selected} state variable, or a function to call when a
     * different tab is selected.
     */
    setSelected: Dispatch<SetStateAction<K>> | ((id: K) => void);
    /**
     * The tab size variant to use. Default {@link NavigationTabSize.SMALL}.
     */
    size?: NavigationTabSize;
}

interface NavigationBarCSS extends CSSProperties {
    "--bb-navigationBar-transition-time": string;
    "--bb-navigationBar-leftLine-width-current"?: string;
    "--bb-navigationBar-leftLine-width-new"?: string;
    "--bb-navigationBar-selectedLine-width-current"?: string;
    "--bb-navigationBar-selectedLine-width-new"?: string;
    "--bb-navigationBar-rightLine-width-current"?: string;
    "--bb-navigationBar-rightLine-width-new"?: string;
}

interface LineWidths {
    left: number;
    selected: number;
    right: number;
}

function getLineWidths(
    tabWidths: number[],
    selectedIndex: number,
    spaceBetweenTabs: number,
): LineWidths {
    let left = 0;
    tabWidths.slice(0, selectedIndex).forEach((width) => {
        left += width + spaceBetweenTabs;
    });
    const selected = tabWidths[selectedIndex];
    let right = 0;
    tabWidths.slice(selectedIndex + 1).forEach((width) => {
        right += width + spaceBetweenTabs;
    });
    return {
        left,
        selected,
        right,
    };
}

/**
 * A horizontal tab bar consisting of multiple {@link Tab}s for navigating between views.
 */
export function NavigationBar<K extends string = string>({
    className,
    children,
    selected,
    setSelected,
    size = NavigationTabSize.SMALL,
}: NavigationBarProps<K>) {
    const navigationBarRef = useRef<HTMLDivElement>(null);
    const [resizeRef, resizeEntry] = useResizeObserver<HTMLDivElement>();
    const combinedRef = useCombinedRef(navigationBarRef, resizeRef);
    const tabContainerRef = useRef<HTMLDivElement>(null);
    const moreButtonRef = useRef<HTMLButtonElement>(null);

    // Whether the bar should be shown. When some tabs are hidden and tab width need to be
    // remeasured, we hide the entire bar while we render all tabs and measure their widths.
    const [showBar, setShowBar] = useState(false);
    const [measuringWidths, setMeasuringWidths] = useState(true);
    // An array of all the tab widths in the same order as the children array.
    const [allTabWidths, setAllTabWidths] = useState<number[] | null>(null);
    const [tabOrder, setTabOrder] = useState<K[]>(children.map((tab) => tab.props.id));
    const tabOrderRef = useLatest(tabOrder);
    const [hiddenTabs, setHiddenTabs] = useState<K[]>([]);
    const hiddenTabsRef = useLatest(hiddenTabs);
    const sizeRef = useLatest(size);

    const [currentLineWidths, setCurrentLineWidths] = useState<LineWidths | null>(null);
    const [newLineWidths, setNewLineWidths] = useState<LineWidths | null>(null);
    const newLineWidthsRef = useLatest(newLineWidths);
    const [inTransition, setInTransition] = useState(false);

    const [showMenu, setShowMenu] = useState(false);
    const [focusInBar, setFocusInBar] = useState(false);

    const selectedIndex = children.findIndex((tab) => tab.props.id === selected);
    const hasSize = !!resizeEntry.target?.clientWidth;

    // When the tabs change, we need to remeasure their widths so that we can properly
    // render the indicator line segments. If not all tabs are shown (at least one is in the
    // menu), then we need to render all tabs and measure their widths. To minimize the buggy
    // appearance, we hide the bar while rendering all tabs for measuring.
    useEffect(() => {
        if (!tabContainerRef.current?.clientWidth) {
            return;
        }
        const visibleTabs = tabContainerRef.current.querySelectorAll(".bb-navigation-bar__tab");
        if (visibleTabs.length < children.length && !measuringWidths) {
            // Reset tab widths, render all tabs.
            setShowBar(false);
            setAllTabWidths(null);
            setTabOrder(children.map((tab) => tab.props.id));
            setMeasuringWidths(true);
        } else if (visibleTabs.length === children.length) {
            // While all tabs are rendered, measure tab widths.
            const newTabWidths: number[] = [...visibleTabs].map((tab) => tab.clientWidth);
            setAllTabWidths(newTabWidths);
        } else if (measuringWidths) {
            // Reset state.
            setMeasuringWidths(false);
        }
    }, [children, size, measuringWidths, hasSize]);

    // Determine which (if any) tabs should go into the overflow popover menu. The selected
    // tab should always be shown, along with any tabs that fit, in the original tab order.
    useEffect(() => {
        if (!navigationBarRef.current?.clientWidth || !allTabWidths) {
            return;
        }
        let allWidthsSum = allTabWidths.reduce((sum, current) => sum + current, 0);
        allWidthsSum += TAB_SIZE_SPACING_MAP[size] * (allTabWidths.length - 1);
        if (allWidthsSum <= navigationBarRef.current.clientWidth) {
            // If everything fits, reset the tabOrder and hiddenTabs.
            setTabOrder(children.map((tab) => tab.props.id));
            hiddenTabsRef.current.length && setHiddenTabs([]);
            setShowBar(true);
            return;
        }
        const newTabOrder: K[] = [];
        // Accumulator for the widths of elements that can fit into the nav bar. This must always
        // include the selected tab and the menu icon button.
        let visibleItemsWidth = allTabWidths[selectedIndex] + TAB_SIZE_SPACING_MAP[size] + 24;
        let index = 0;
        while (
            visibleItemsWidth < navigationBarRef.current.clientWidth
            && index < children.length
        ) {
            if (index === selectedIndex) {
                newTabOrder.push(children[selectedIndex].props.id);
                index += 1;
                continue;
            }
            if (
                visibleItemsWidth + TAB_SIZE_SPACING_MAP[size] + allTabWidths[index]
                <= navigationBarRef.current.clientWidth
            ) {
                newTabOrder.push(children[index].props.id);
                visibleItemsWidth += TAB_SIZE_SPACING_MAP[size] + allTabWidths[index];
                index += 1;
            } else {
                break;
            }
        }
        if (newTabOrder.indexOf(children[selectedIndex].props.id) < 0) {
            newTabOrder.push(children[selectedIndex].props.id);
        }
        const newHiddenTabs: K[] = [];
        children.forEach((tab) => {
            if (newTabOrder.indexOf(tab.props.id) < 0) {
                newHiddenTabs.push(tab.props.id);
            }
        });
        setShowBar(true);
        setTabOrder(newTabOrder);
        setHiddenTabs(newHiddenTabs);
    }, [
        resizeEntry.target?.clientWidth,
        size,
        selectedIndex,
        children,
        allTabWidths,
        tabOrderRef,
        hiddenTabsRef,
    ]);

    // Update the left, selected, and right line widths and begin the CSS animation.
    useEffect(() => {
        if (!tabContainerRef.current?.clientWidth) {
            return;
        }
        if (newLineWidthsRef.current) {
            setCurrentLineWidths(newLineWidthsRef.current);
        }
        const newTabWidths: number[] = [...tabContainerRef.current.children].map(
            (tab) => tab.clientWidth,
        );
        const currentTabOrderSelectedIndex = tabOrder.indexOf(selected);
        const newLineWidths = getLineWidths(
            newTabWidths,
            currentTabOrderSelectedIndex,
            TAB_SIZE_SPACING_MAP[sizeRef.current],
        );
        setNewLineWidths(newLineWidths);
        setInTransition(true);
    }, [selected, tabOrder, sizeRef, newLineWidthsRef]);

    const cssVars: NavigationBarCSS = {
        "--bb-navigationBar-transition-time": SELECT_TRANSITION_TIME / SEC + "s",
    };
    if (currentLineWidths) {
        cssVars["--bb-navigationBar-leftLine-width-current"] = currentLineWidths.left + "px";
        cssVars["--bb-navigationBar-selectedLine-width-current"] =
            currentLineWidths.selected + "px";
        cssVars["--bb-navigationBar-rightLine-width-current"] = currentLineWidths.right + "px";
    }
    if (newLineWidths) {
        cssVars["--bb-navigationBar-leftLine-width-new"] = newLineWidths.left + "px";
        cssVars["--bb-navigationBar-selectedLine-width-new"] = newLineWidths.selected + "px";
        cssVars["--bb-navigationBar-rightLine-width-new"] = newLineWidths.right + "px";
    }

    useArrowNav(tabContainerRef, {
        direction: ArrowNavDirection.LEFT_RIGHT,
        tabbableElementsOnly: false,
    });

    return (
        <div
            ref={combinedRef}
            className={clsx("bb-navigation-bar", `bb-navigation-bar--${size}`, className, {
                "bb-navigation-bar--hidden": !showBar,
            })}
            style={cssVars}
            onKeyDown={(e) => {
                if (e.key === "Home") {
                    const firstItem = tabContainerRef.current?.firstChild;
                    firstItem && (firstItem as HTMLElement).focus();
                } else if (e.key === "End") {
                    const lastItem = tabContainerRef.current?.lastChild;
                    lastItem && (lastItem as HTMLElement).focus();
                }
            }}
            onFocus={() => setFocusInBar(true)}
            onBlur={() => setFocusInBar(false)}
        >
            <div
                ref={tabContainerRef}
                role={"tablist"}
                aria-orientation={"horizontal"}
                className={"bb-navigation-bar__tab-container"}
            >
                {tabOrder.map((tabId) => {
                    const tab = children.find((tab) => tab.props.id === tabId);
                    if (!tab) {
                        return;
                    }
                    return cloneElement(tab, {
                        key: tabId,
                        selected: selected === tabId,
                        onClick: () => setSelected(tabId),
                        focusInBar: focusInBar,
                    });
                })}
                {!!hiddenTabs.length && (
                    <>
                        <IconButton
                            ref={moreButtonRef}
                            className={"bb-navigation-bar__item"}
                            aria-label={"More tabs"}
                            onClick={() => setShowMenu(true)}
                            // The menu button should behave like a tab and should only be
                            // focused using the left and right arrows.
                            tabFocusable={false}
                        >
                            <Icon.Dots size={20} />
                        </IconButton>
                        <PopoverMenu
                            trigger={moreButtonRef}
                            show={showMenu}
                            setShow={setShowMenu}
                            placement={[PopoverPlacement.BOTTOM_END, PopoverPlacement.BOTTOM_START]}
                        >
                            <PopoverMenu.Section>
                                {hiddenTabs.map((hiddenTab) => {
                                    const tabProps = children.find(
                                        (tab) => tab.props.id === hiddenTab,
                                    );
                                    return tabProps ? (
                                        <PopoverMenu.Option
                                            key={hiddenTab}
                                            label={tabProps.props.label}
                                            disabled={!!tabProps.props.disabledReason}
                                            tooltip={
                                                tabProps.props.disabledReason ? (
                                                    <Tooltip>
                                                        {tabProps.props.disabledReason}
                                                    </Tooltip>
                                                ) : undefined
                                            }
                                            onClick={() => {
                                                setShowMenu(false);
                                                setSelected(hiddenTab);
                                            }}
                                        />
                                    ) : undefined;
                                })}
                            </PopoverMenu.Section>
                        </PopoverMenu>
                    </>
                )}
            </div>
            <CSSTransition
                in={inTransition}
                timeout={SELECT_TRANSITION_TIME}
                classNames="bb-navigation-bar__line-container-"
                onEntered={() => setInTransition(false)}
            >
                <div className={"bb-navigation-bar__line-container"}>
                    <div className={"bb-navigation-bar__left-line"} />
                    <div className={"bb-navigation-bar__selected-line"} />
                    <div className={"bb-navigation-bar__right-line"} />
                </div>
            </CSSTransition>
        </div>
    );
}

export interface NavigationTabProps<K extends string = string> {
    /**
     * A unique id for the tab.
     */
    id: K;
    /**
     * The text to display on the tab.
     */
    label: string;
    /**
     * Additional content to display to the right of the label. These elements should be no
     * taller than 20px. For multiple elements, you can pass in an array of ReactNodes,
     * and in most cases styling will be handled automatically.
     */
    additionalContent?: ReactNode;
    /**
     * If provided, disables the tab and renders a tooltip on the tab with the given explanation.
     */
    disabledReason?: string;
    /**
     * This prop will be overwritten by {@link NavigationBar} and can be left empty.
     *
     * Whether this tab is the currently selected tab.
     */
    selected?: boolean;
    /**
     * This prop will be overwritten by {@link NavigationBar} and can be left empty.
     *
     * The function to call when this tab is selected.
     */
    onClick?: () => void;
    /**
     * This prop will be overwritten by {@link NavigationBar} and can be left empty.
     *
     * Whether the {@link NavigationBar} containing this tab currently has focus.
     */
    focusInBar?: boolean;
}

/**
 * A single navigation tab that can be placed in
 */
function Tab<K extends string = string>({
    label,
    additionalContent,
    disabledReason,
    selected,
    onClick,
    focusInBar,
}: NavigationTabProps<K>) {
    const tooltipId = useId();
    const tabRef = useRef<HTMLDivElement>(null);
    return (
        <>
            <div
                ref={tabRef}
                className={clsx("bb-navigation-bar__tab", "bb-navigation-bar__item", {
                    "bb-navigation-bar__tab--selected": selected,
                    "bb-navigation-bar__tab--disabled": disabledReason,
                })}
                role={"tab"}
                aria-selected={selected}
                aria-describedby={disabledReason ? tooltipId : undefined}
                onClick={!disabledReason ? onClick : undefined}
                onKeyDown={(e) => {
                    if (!disabledReason && e.key === "Enter") {
                        e.preventDefault();
                        onClick?.();
                    }
                }}
                onKeyUp={(e) => {
                    if (!disabledReason && e.key === " ") {
                        e.preventDefault();
                        onClick?.();
                    }
                }}
                // If the bar isn't focused and this is the selected tab, then tabIndex should
                // be 0 so that when focus moves into the tab list, this tab is focused.
                tabIndex={selected && !focusInBar ? 0 : -1}
            >
                {label}
                {additionalContent}
            </div>
            {disabledReason && (
                <Tooltip
                    id={tooltipId}
                    target={tabRef}
                    offset={
                        // Add extra space above tooltip so that it points to the line.
                        getSizePx(NavigationBarTokens.LINE_HEIGHT)
                        + getSizePx(SpacingTokens.NAVIGATION_TAB_BELOW)
                    }
                    placement={[
                        PopoverPlacement.BOTTOM,
                        PopoverPlacement.BOTTOM_START,
                        PopoverPlacement.BOTTOM_END,
                    ]}
                >
                    {disabledReason}
                </Tooltip>
            )}
        </>
    );
}

NavigationBar.Tab = Tab;
