import { FFC } from "util/type";

import {
    ToggleSwitch as ToggleSwitchWithHiddenProps,
    ToggleSwitchProps as ToggleSwitchPropsWithHiddenProps,
} from "./ToggleSwitch";
type ToggleSwitchProps = Omit<ToggleSwitchPropsWithHiddenProps, "isMenuToggleSwitch">;
const ToggleSwitch: FFC<HTMLInputElement, ToggleSwitchProps> = ToggleSwitchWithHiddenProps;
export { ToggleSwitch };
export type { ToggleSwitchProps };
