import clsx from "clsx";
import * as Icon from "components/Icon";
import * as CommonIcon from "components/Icon/CommonIcon";
import { TooltipProps } from "components/Tooltip";
import React, {
    AriaAttributes,
    ReactNode,
    useRef,
    FC,
    ReactElement,
    cloneElement,
    useId,
} from "react";
import "./InputWrapper.scss";

export function describedBy(
    inputId: string,
    hasErrorMessage: boolean,
    hasHelper: boolean,
    ariaErrorMessage?: string,
): string | undefined {
    let ids = clsx({
        [`${inputId}__error-alert`]: hasErrorMessage && !ariaErrorMessage,
        [`${inputId}__helper`]: hasHelper,
    });
    if (ariaErrorMessage) {
        ids = clsx(ids, ariaErrorMessage);
    }
    return ids || undefined;
}

export function accessibilityProps(
    inputId: string,
    hasError: boolean,
    hasErrorMessage: boolean,
    required: boolean,
    ariaErrorMessage?: string,
): AriaAttributes {
    const result: AriaAttributes = {
        "aria-invalid": hasError,
        "aria-required": required,
    };
    // Note: aria-errormessage is not sufficient for accessibility for errors. Most screen
    // reader/browser combinations will not correctly fulfill the spec for aria-errormessage
    // (to read the error message aloud when it is added);
    // see https://a11ysupport.io/tech/aria/aria-errormessage_attribute
    // This being the case, you must also add the id to the describedby (handled in
    // describedBy above) and mark the input as aria-invalid (handled in this function above).
    if (ariaErrorMessage) {
        result["aria-errormessage"] = ariaErrorMessage;
    } else if (hasErrorMessage) {
        result["aria-errormessage"] = `${inputId}__error-alert`;
    }
    result["aria-labelledby"] = `${inputId}__label`;
    return result;
}

export interface InputWrapperProps {
    children: ReactNode;
    errorMessage?: ReactNode;
    helper?: ReactNode;
    hideLabel?: boolean;
    info?: ReactElement<TooltipProps>;
    inputId: string;
    label: ReactNode;
    required?: boolean;
    subLabel?: ReactNode;
    horizontal?: boolean;
}

export const InputWrapper: FC<InputWrapperProps> = ({
    children,
    inputId,
    label,
    subLabel,
    info,
    errorMessage,
    helper,
    hideLabel,
    required,
    horizontal,
}) => {
    const infoIconRef = useRef<SVGSVGElement>(null);
    const infoTooltipId = useId();
    const labelClassName = clsx("bb-input-wrapper__label", {
        "bb-input-wrapper__label--required": required,
        "bb-input-wrapper__label--horizontal": horizontal,
        "bb-input-wrapper__label--hidden": hideLabel,
    });
    return (
        <>
            <label id={`${inputId}__label`} htmlFor={inputId} className={labelClassName}>
                <span className="bb-input-wrapper__main-label">{label}</span>
                {subLabel && (
                    <>
                        &nbsp;<span className="bb-input-wrapper__sub-label">{subLabel}</span>
                    </>
                )}
                {info && (
                    <>
                        <Icon.InfoCircle
                            ref={infoIconRef}
                            id={`${inputId}__info-icon`}
                            className="bb-input-wrapper__info-icon"
                            size={20}
                            aria-label={"More info"}
                            aria-describedby={infoTooltipId}
                            tabIndex={0}
                        />
                        {cloneElement(info, {
                            id: infoTooltipId,
                            target: infoIconRef,
                            className: "bb-input-wrapper__info-tooltip",
                        })}
                    </>
                )}
            </label>
            <div className="bb-input-wrapper__footer-wrapper">
                {children}
                {errorMessage && (
                    <CommonIcon.ErrorTriangle
                        size={20}
                        className={"bb-input-wrapper__error"}
                        aria-hidden={true}
                    >
                        <span id={`${inputId}__error-alert`}>{errorMessage}</span>
                    </CommonIcon.ErrorTriangle>
                )}
                {helper && (
                    <div id={`${inputId}__helper`} className="bb-input-wrapper__helper">
                        {helper}
                    </div>
                )}
            </div>
        </>
    );
};
