import { FFC } from "util/type";
import {
    DropdownMenu as DropdownMenuWithRef,
    DropdownMenuSubComponentsAndHooks,
    DropdownMenuProps as DropdownMenuWithRefProps,
    InlineDropdownMenu,
    InlineDropdownMenuProps,
    DropdownMenuPlacement,
    DropdownMenuCheckboxProps,
    DropdownMenuCreateOptionProps,
    DropdownMenuInfoProps,
    DropdownMenuLoadProps,
    DropdownMenuOptionProps,
    DropdownMenuSectionProps,
    DropdownMenuSupplementProps,
    UseSingleDropdownMenuResult,
    UseSingleDropdownMenuResultDropdownProps,
    UseMultiDropdownMenuResult,
    UseMultiDropdownMenuResultDropdownProps,
} from "./DropdownMenu";

type DropdownMenuProps = Omit<DropdownMenuWithRefProps, "detectClickOrFocusOutsideRef">;

const DropdownMenu: FFC<HTMLInputElement, DropdownMenuProps> & DropdownMenuSubComponentsAndHooks =
    DropdownMenuWithRef;

export type {
    DropdownMenuProps,
    InlineDropdownMenuProps,
    DropdownMenuCheckboxProps,
    DropdownMenuCreateOptionProps,
    DropdownMenuInfoProps,
    DropdownMenuLoadProps,
    DropdownMenuOptionProps,
    DropdownMenuSectionProps,
    DropdownMenuSupplementProps,
    UseSingleDropdownMenuResult,
    UseSingleDropdownMenuResultDropdownProps,
    UseMultiDropdownMenuResult,
    UseMultiDropdownMenuResultDropdownProps,
};

export { DropdownMenu, DropdownMenuPlacement, InlineDropdownMenu };
