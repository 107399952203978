import React, {
    ReactElement,
    ReactNode,
    CSSProperties,
    cloneElement,
    createElement,
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
    useCallback,
} from "react";

import { getScrollbarWidth, getInnerWidth, getInnerHeight } from "./utils";

import {
    containerStyleDefault,
    containerStyleAutoHeight,
    viewStyleDefault,
    viewStyleAutoHeight,
    viewStyleUniversalInitial,
    trackHorizontalStyleDefault,
    trackVerticalStyleDefault,
    thumbHorizontalStyleDefault,
    thumbVerticalStyleDefault,
} from "./styles";

import {
    renderViewDefault,
    renderTrackHorizontalDefault,
    renderTrackVerticalDefault,
    renderThumbHorizontalDefault,
    renderThumbVerticalDefault,
} from "./defaultRenderElements";

/**
 * A custom scrollbar component that can be used wherever appearance or behavior needs to be
 * customized. This component also provides the ability to respond to scroll events as well as
 * manually manipulate scrolling via methods like scrollToTop and scrollToBottom.
 *
 * This scrollbar component (including utility/helper functions, styles, etc.) was copied directly
 * from the react-custom-scrollbars package and modified here. Since the package is stale, we are
 * using this copy of the code so that we can maintain it ourselves.
 *
 * Website: https://malte-wessel.com/react-custom-scrollbars/
 * GitHub: https://github.com/malte-wessel/react-custom-scrollbars
 * License: https://github.com/malte-wessel/react-custom-scrollbars/blob/master/LICENSE.md
 */
export const Scrollbar = forwardRef(
    (
        {
            renderView = renderViewDefault,
            renderTrackHorizontal = renderTrackHorizontalDefault,
            renderTrackVertical = renderTrackVerticalDefault,
            renderThumbHorizontal = renderThumbHorizontalDefault,
            renderThumbVertical = renderThumbVerticalDefault,
            tagName = "div",
            hideTracksWhenNotNeeded = false,
            autoHide = false,
            autoHideTimeout = 1000,
            autoHideDuration = 200,
            thumbMinSize = 30,
            universal = false,
            autoHeight = false,
            autoHeightMin = 0,
            autoHeightMax = 200,
            onScroll,
            onScrollFrame,
            onScrollStart,
            onScrollStop,
            onUpdate,
            thumbSize,
            style,
            children,
            ...props
        }: ScrollbarProps,
        ref,
    ) => {
        const view = useRef<HTMLDivElement>(null);
        const trackHorizontal = useRef<HTMLDivElement>(null);
        const trackVertical = useRef<HTMLDivElement>(null);
        const thumbHorizontal = useRef<HTMLDivElement>(null);
        const thumbVertical = useRef<HTMLDivElement>(null);
        const trackMouseOver = useRef<boolean>(false);
        const requestFrame = useRef<number>(0);
        const hideTracksTimeout = useRef<number>(0);
        const viewScrollLeft = useRef<number>(0);
        const viewScrollTop = useRef<number>(0);
        const prevPageX = useRef<number>(0);
        const prevPageY = useRef<number>(0);
        const detectScrollingInterval = useRef<number>(0);
        const dragging = useRef<boolean>(false);
        const scrolling = useRef<boolean>(false);
        const lastViewScrollLeft = useRef<number>(0);
        const lastViewScrollTop = useRef<number>(0);

        const getValues = () => {
            const {
                scrollLeft = 0,
                scrollTop = 0,
                scrollWidth = 0,
                scrollHeight = 0,
                clientWidth = 0,
                clientHeight = 0,
            } = view.current || {};

            return {
                left: scrollLeft / (scrollWidth - clientWidth) || 0,
                top: scrollTop / (scrollHeight - clientHeight) || 0,
                scrollLeft,
                scrollTop,
                scrollWidth,
                scrollHeight,
                clientWidth,
                clientHeight,
            };
        };

        useImperativeHandle(ref, () => ({
            // The following methods are exposed according to the API of the original package.
            // See the "Methods" section here:
            // https://github.com/malte-wessel/react-custom-scrollbars/blob/master/docs/API.md
            getValues,
            getScrollLeft: () => {
                if (!view.current) {
                    return 0;
                }
                return view.current.scrollLeft;
            },
            getScrollTop: () => {
                if (!view.current) {
                    return 0;
                }
                return view.current.scrollTop;
            },
            getScrollWidth: () => {
                if (!view.current) {
                    return 0;
                }
                return view.current.scrollWidth;
            },
            getScrollHeight: () => {
                if (!view.current) {
                    return 0;
                }
                return view.current.scrollHeight;
            },
            getClientWidth: () => {
                if (!view.current) {
                    return 0;
                }
                return view.current.clientWidth;
            },
            getClientHeight: () => {
                if (!view.current) {
                    return 0;
                }
                return view.current.clientHeight;
            },
            scrollLeft: (left = 0) => {
                if (!view.current) {
                    return;
                }
                view.current.scrollLeft = left;
            },
            scrollTop: (top = 0) => {
                if (!view.current) {
                    return;
                }
                view.current.scrollTop = top;
            },
            scrollToLeft: () => {
                if (!view.current) {
                    return;
                }
                view.current.scrollLeft = 0;
            },
            scrollToTop: () => {
                if (!view.current) {
                    return;
                }
                view.current.scrollTop = 0;
            },
            scrollToRight: () => {
                if (!view.current) {
                    return;
                }
                view.current.scrollLeft = view.current.scrollWidth;
            },
            scrollToBottom: () => {
                if (!view.current) {
                    return;
                }
                view.current.scrollTop = view.current.scrollHeight;
            },
        }));

        const [didMountUniversal, setDidMountUniversal] = useState(false);

        const getThumbHorizontalWidth = useCallback(() => {
            if (!view.current || !trackHorizontal.current) {
                return 0;
            }
            const { scrollWidth, clientWidth } = view.current;
            const trackWidth = getInnerWidth(trackHorizontal.current);
            const width = Math.ceil((clientWidth / scrollWidth) * trackWidth);
            if (trackWidth === width) {
                return 0;
            }
            if (thumbSize) {
                return thumbSize;
            }
            return Math.max(width, thumbMinSize);
        }, [thumbMinSize, thumbSize]);

        const getThumbVerticalHeight = useCallback(() => {
            if (!view.current || !trackVertical.current) {
                return 0;
            }
            const { scrollHeight, clientHeight } = view.current;
            const trackHeight = getInnerHeight(trackVertical.current);
            const height = Math.ceil((clientHeight / scrollHeight) * trackHeight);
            if (trackHeight === height) {
                return 0;
            }
            if (thumbSize) {
                return thumbSize;
            }
            return Math.max(height, thumbMinSize);
        }, [thumbMinSize, thumbSize]);

        const getScrollLeftForOffset = useCallback(
            (offset: number) => {
                if (!view.current || !trackHorizontal.current) {
                    return 0;
                }
                const { scrollWidth, clientWidth } = view.current;
                const trackWidth = getInnerWidth(trackHorizontal.current);
                const thumbWidth = getThumbHorizontalWidth();
                return (offset / (trackWidth - thumbWidth)) * (scrollWidth - clientWidth);
            },
            [getThumbHorizontalWidth],
        );

        const getScrollTopForOffset = useCallback(
            (offset: number) => {
                if (!view.current || !trackVertical.current) {
                    return 0;
                }
                const { scrollHeight, clientHeight } = view.current;
                const trackHeight = getInnerHeight(trackVertical.current);
                const thumbHeight = getThumbVerticalHeight();
                return (offset / (trackHeight - thumbHeight)) * (scrollHeight - clientHeight);
            },
            [getThumbVerticalHeight],
        );

        const showTracks = useCallback(() => {
            hideTracksTimeout.current && clearTimeout(hideTracksTimeout.current);
            if (trackHorizontal.current) {
                trackHorizontal.current.style.opacity = "1";
            }
            if (trackVertical.current) {
                trackVertical.current.style.opacity = "1";
            }
        }, []);

        const hideTracks = useCallback(() => {
            if (dragging.current || scrolling.current || trackMouseOver.current) {
                return;
            }
            hideTracksTimeout.current && clearTimeout(hideTracksTimeout.current);
            hideTracksTimeout.current = window.setTimeout(() => {
                if (trackHorizontal.current) {
                    trackHorizontal.current.style.opacity = "0";
                }
                if (trackVertical.current) {
                    trackVertical.current.style.opacity = "0";
                }
            }, autoHideTimeout);
        }, [autoHideTimeout]);

        const handleDrag = useCallback(
            (event: MouseEvent) => {
                if (view.current) {
                    if (prevPageX.current && trackHorizontal.current) {
                        const { clientX } = event;
                        const { left: trackLeft } = trackHorizontal.current.getBoundingClientRect();
                        const thumbWidth = getThumbHorizontalWidth();
                        const clickPosition = thumbWidth - prevPageX.current;
                        const offset = -trackLeft + clientX - clickPosition;
                        view.current.scrollLeft = getScrollLeftForOffset(offset);
                    }
                    if (prevPageY.current && trackVertical.current) {
                        const { clientY } = event;
                        const { top: trackTop } = trackVertical.current.getBoundingClientRect();
                        const thumbHeight = getThumbVerticalHeight();
                        const clickPosition = thumbHeight - prevPageY.current;
                        const offset = -trackTop + clientY - clickPosition;
                        view.current.scrollTop = getScrollTopForOffset(offset);
                    }
                }
                return false;
            },
            [
                getScrollLeftForOffset,
                getScrollTopForOffset,
                getThumbHorizontalWidth,
                getThumbVerticalHeight,
            ],
        );

        const handleDragEndAutoHide = useCallback(() => {
            if (!autoHide) {
                return;
            }
            hideTracks();
        }, [autoHide, hideTracks]);

        const handleDragEnd = useCallback(() => {
            dragging.current = false;
            prevPageX.current = prevPageY.current = 0;
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            teardownDragging();
            handleDragEndAutoHide();
            // Exclude teardownDragging below to prevent circular deps.
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [handleDragEndAutoHide]);

        const setupDragging = useCallback(() => {
            document.body.style.userSelect = "none";
            document.addEventListener("mousemove", handleDrag);
            document.addEventListener("mouseup", handleDragEnd);
            document.onselectstart = () => false;
        }, [handleDrag, handleDragEnd]);

        const teardownDragging: () => void = useCallback(() => {
            document.body.style.userSelect = "";
            document.removeEventListener("mousemove", handleDrag);
            document.removeEventListener("mouseup", handleDragEnd);
            document.onselectstart = null;
        }, [handleDrag, handleDragEnd]);

        const handleDragStart = useCallback(
            (event: MouseEvent) => {
                dragging.current = true;
                event.stopImmediatePropagation();
                setupDragging();
            },
            [setupDragging],
        );

        const handleScrollStartAutoHide = useCallback(() => {
            if (!autoHide) {
                return;
            }
            showTracks();
        }, [autoHide, showTracks]);

        const handleScrollStart = useCallback(() => {
            onScrollStart?.();
            handleScrollStartAutoHide();
        }, [handleScrollStartAutoHide, onScrollStart]);

        const handleScrollStopAutoHide = useCallback(() => {
            if (!autoHide) {
                return;
            }
            hideTracks();
        }, [autoHide, hideTracks]);

        const handleScrollStop = useCallback(() => {
            onScrollStop?.();
            handleScrollStopAutoHide();
        }, [handleScrollStopAutoHide, onScrollStop]);

        const detectScrolling = useCallback(() => {
            if (scrolling.current) {
                return;
            }
            scrolling.current = true;
            handleScrollStart();
            detectScrollingInterval.current = window.setInterval(() => {
                if (
                    lastViewScrollLeft.current === viewScrollLeft.current
                    && lastViewScrollTop.current === viewScrollTop.current
                ) {
                    clearInterval(detectScrollingInterval.current);
                    scrolling.current = false;
                    handleScrollStop();
                }
                lastViewScrollLeft.current = viewScrollLeft.current;
                lastViewScrollTop.current = viewScrollTop.current;
            }, 100);
        }, [handleScrollStart, handleScrollStop]);

        const handleTrackMouseEnterAutoHide = useCallback(() => {
            if (!autoHide) {
                return;
            }
            showTracks();
        }, [autoHide, showTracks]);

        const handleTrackMouseEnter = useCallback(() => {
            trackMouseOver.current = true;
            handleTrackMouseEnterAutoHide();
        }, [handleTrackMouseEnterAutoHide]);

        const handleTrackMouseLeaveAutoHide = useCallback(() => {
            if (!autoHide) {
                return;
            }
            hideTracks();
        }, [autoHide, hideTracks]);

        const handleTrackMouseLeave = useCallback(() => {
            trackMouseOver.current = false;
            handleTrackMouseLeaveAutoHide();
        }, [handleTrackMouseLeaveAutoHide]);

        const _update = useCallback(
            (callback?: (values: Position) => void) => {
                const values = getValues();
                if (getScrollbarWidth()) {
                    const { scrollLeft, clientWidth, scrollWidth } = values;
                    const trackHorizontalWidth = trackHorizontal.current
                        ? getInnerWidth(trackHorizontal.current)
                        : 0;
                    const thumbHorizontalWidth = getThumbHorizontalWidth();
                    const thumbHorizontalX =
                        (scrollLeft / (scrollWidth - clientWidth))
                        * (trackHorizontalWidth - thumbHorizontalWidth);
                    const { scrollTop, clientHeight, scrollHeight } = values;
                    const trackVerticalHeight = trackVertical.current
                        ? getInnerHeight(trackVertical.current)
                        : 0;
                    const thumbVerticalHeight = getThumbVerticalHeight();
                    const thumbVerticalY =
                        (scrollTop / (scrollHeight - clientHeight))
                        * (trackVerticalHeight - thumbVerticalHeight);
                    if (hideTracksWhenNotNeeded) {
                        if (trackHorizontal.current) {
                            trackHorizontal.current.style.visibility =
                                scrollWidth > clientWidth ? "visible" : "hidden";
                        }
                        if (trackVertical.current) {
                            trackVertical.current.style.visibility =
                                scrollHeight > clientHeight ? "visible" : "hidden";
                        }
                    }
                    if (thumbHorizontal.current) {
                        thumbHorizontal.current.style.width = thumbHorizontalWidth + "px";
                        thumbHorizontal.current.style.transform = `translateX(${thumbHorizontalX}px)`;
                    }
                    if (thumbVertical.current) {
                        thumbVertical.current.style.height = thumbVerticalHeight + "px";
                        thumbVertical.current.style.transform = `translateY(${thumbVerticalY}px)`;
                    }
                }
                onUpdate && onUpdate(values);
                if (typeof callback !== "function") {
                    return;
                }
                callback(values);
            },
            [getThumbHorizontalWidth, getThumbVerticalHeight, hideTracksWhenNotNeeded, onUpdate],
        );

        const raf = useCallback((callback: () => void) => {
            requestFrame.current && window.cancelAnimationFrame(requestFrame.current);
            requestFrame.current = window.requestAnimationFrame(() => {
                requestFrame.current = 0;
                callback();
            });
        }, []);

        const update = useCallback(
            (callback?: (values: Position) => void) => {
                raf(() => _update(callback));
            },
            [_update, raf],
        );

        const handleScroll = useCallback(
            (event: Event) => {
                onScroll && onScroll(event);
                update((values) => {
                    const { scrollLeft, scrollTop } = values;
                    viewScrollLeft.current = scrollLeft;
                    viewScrollTop.current = scrollTop;
                    onScrollFrame && onScrollFrame(values);
                });
                detectScrolling();
            },
            [detectScrolling, onScroll, onScrollFrame, update],
        );

        const handleHorizontalTrackMouseDown = useCallback(
            (event: MouseEvent) => {
                event.preventDefault();
                const { target, clientX } = event;
                const { left: targetLeft } = (<HTMLElement>target).getBoundingClientRect();
                const thumbWidth = getThumbHorizontalWidth();
                const offset = Math.abs(targetLeft - clientX) - thumbWidth / 2;
                if (view.current) {
                    view.current.scrollLeft = getScrollLeftForOffset(offset);
                }
            },
            [getScrollLeftForOffset, getThumbHorizontalWidth],
        );

        const handleVerticalTrackMouseDown = useCallback(
            (event: MouseEvent) => {
                event.preventDefault();
                const { target, clientY } = event;
                const { top: targetTop } = (<HTMLElement>target).getBoundingClientRect();
                const thumbHeight = getThumbVerticalHeight();
                const offset = Math.abs(targetTop - clientY) - thumbHeight / 2;
                if (view.current) {
                    view.current.scrollTop = getScrollTopForOffset(offset);
                }
            },
            [getScrollTopForOffset, getThumbVerticalHeight],
        );

        const handleHorizontalThumbMouseDown = useCallback(
            (event: MouseEvent) => {
                event.preventDefault();
                handleDragStart(event);
                const { target, clientX } = event;
                const { offsetWidth } = <HTMLElement>target;
                const { left } = (<HTMLElement>target).getBoundingClientRect();
                prevPageX.current = offsetWidth - (clientX - left);
            },
            [handleDragStart],
        );

        const handleVerticalThumbMouseDown = useCallback(
            (event: MouseEvent) => {
                event.preventDefault();
                handleDragStart(event);
                const { target, clientY } = event;
                const { offsetHeight } = <HTMLElement>target;
                const { top } = (<HTMLElement>target).getBoundingClientRect();
                prevPageY.current = offsetHeight - (clientY - top);
            },
            [handleDragStart],
        );

        const handleWindowResize = useCallback(() => {
            update();
        }, [update]);

        const addListeners = useCallback(() => {
            if (typeof document === "undefined" || !view) {
                return;
            }
            view.current?.addEventListener("scroll", handleScroll);
            if (!getScrollbarWidth()) {
                return;
            }
            trackHorizontal.current?.addEventListener("mouseenter", handleTrackMouseEnter);
            trackHorizontal.current?.addEventListener("mouseleave", handleTrackMouseLeave);
            trackHorizontal.current?.addEventListener("mousedown", handleHorizontalTrackMouseDown);
            trackVertical.current?.addEventListener("mouseenter", handleTrackMouseEnter);
            trackVertical.current?.addEventListener("mouseleave", handleTrackMouseLeave);
            trackVertical.current?.addEventListener("mousedown", handleVerticalTrackMouseDown);
            thumbHorizontal.current?.addEventListener("mousedown", handleHorizontalThumbMouseDown);
            thumbVertical.current?.addEventListener("mousedown", handleVerticalThumbMouseDown);
            window.addEventListener("resize", handleWindowResize);
        }, [
            handleHorizontalThumbMouseDown,
            handleHorizontalTrackMouseDown,
            handleScroll,
            handleTrackMouseEnter,
            handleTrackMouseLeave,
            handleVerticalThumbMouseDown,
            handleVerticalTrackMouseDown,
            handleWindowResize,
        ]);

        const removeListeners = useCallback(() => {
            if (typeof document === "undefined" || !view) {
                return;
            }
            view.current?.removeEventListener("scroll", handleScroll);
            if (!getScrollbarWidth()) {
                return;
            }
            trackHorizontal.current?.removeEventListener("mouseenter", handleTrackMouseEnter);
            trackHorizontal.current?.removeEventListener("mouseleave", handleTrackMouseLeave);
            trackHorizontal.current?.removeEventListener(
                "mousedown",
                handleHorizontalTrackMouseDown,
            );
            trackVertical.current?.removeEventListener("mouseenter", handleTrackMouseEnter);
            trackVertical.current?.removeEventListener("mouseleave", handleTrackMouseLeave);
            trackVertical.current?.removeEventListener("mousedown", handleVerticalTrackMouseDown);
            thumbHorizontal.current?.removeEventListener(
                "mousedown",
                handleHorizontalThumbMouseDown,
            );
            thumbVertical.current?.removeEventListener("mousedown", handleVerticalThumbMouseDown);
            window.removeEventListener("resize", handleWindowResize);
            // Possibly setup by `handleDragStart`
            teardownDragging();
        }, [
            handleHorizontalThumbMouseDown,
            handleHorizontalTrackMouseDown,
            handleScroll,
            handleTrackMouseEnter,
            handleTrackMouseLeave,
            handleVerticalThumbMouseDown,
            handleVerticalTrackMouseDown,
            handleWindowResize,
            teardownDragging,
        ]);

        const componentDidMountUniversal = useCallback(() => {
            if (!universal) {
                return;
            }
            setDidMountUniversal(true);
        }, [universal]);

        useEffect(() => {
            // componentDidMount
            addListeners();
            componentDidMountUniversal();
            return () => {
                // componentWillUnmount
                removeListeners();
                window.cancelAnimationFrame(requestFrame.current);
                clearTimeout(hideTracksTimeout.current);
                clearInterval(detectScrollingInterval.current);
            };
        }, [addListeners, componentDidMountUniversal, removeListeners]);

        useEffect(() => {
            // componentDidUpdate
            update();
        });

        // RENDER
        const scrollbarWidth = getScrollbarWidth();

        const containerStyle = {
            ...containerStyleDefault,
            ...(autoHeight && {
                ...containerStyleAutoHeight,
                minHeight: autoHeightMin,
                maxHeight: autoHeightMax,
            }),
            ...style,
        };

        const viewStyle = {
            ...viewStyleDefault,
            // Hide scrollbars by setting a negative margin
            marginRight: scrollbarWidth ? -scrollbarWidth : 0,
            marginBottom: scrollbarWidth ? -scrollbarWidth : 0,
            ...(autoHeight && {
                ...viewStyleAutoHeight,
                // Add scrollbarWidth to autoHeight in order to compensate negative margins
                minHeight:
                    typeof autoHeightMin === "string"
                        ? `calc(${autoHeightMin} + ${scrollbarWidth}px)`
                        : Number(autoHeightMin) + scrollbarWidth,
                maxHeight:
                    typeof autoHeightMax === "string"
                        ? `calc(${autoHeightMax} + ${scrollbarWidth}px)`
                        : Number(autoHeightMax) + scrollbarWidth,
            }),
            // Override min/max height for initial universal rendering
            ...(autoHeight
                && universal
                && !didMountUniversal && {
                    minHeight: autoHeightMin,
                    maxHeight: autoHeightMax,
                }),
            // Override
            ...(universal && !didMountUniversal && viewStyleUniversalInitial),
        };

        const trackAutoHeightStyle = {
            transition: `opacity ${autoHideDuration}ms`,
            opacity: 0,
        };

        const trackHorizontalStyle = {
            ...trackHorizontalStyleDefault,
            ...(autoHide && trackAutoHeightStyle),
            ...((!scrollbarWidth || (universal && !didMountUniversal)) && {
                display: "none",
            }),
        };

        const trackVerticalStyle = {
            ...trackVerticalStyleDefault,
            ...(autoHide && trackAutoHeightStyle),
            ...((!scrollbarWidth || (universal && !didMountUniversal)) && {
                display: "none",
            }),
        };

        return createElement(tagName, { ...props, style: containerStyle }, [
            cloneElement(renderView({ style: viewStyle }), { key: "view", ref: view }, children),
            cloneElement(
                renderTrackHorizontal({ style: trackHorizontalStyle }),
                { key: "trackHorizontal", ref: trackHorizontal },
                cloneElement(renderThumbHorizontal({ style: thumbHorizontalStyleDefault }), {
                    ref: thumbHorizontal,
                }),
            ),
            cloneElement(
                renderTrackVertical({ style: trackVerticalStyle }),
                { key: "trackVertical", ref: trackVertical },
                cloneElement(renderThumbVertical({ style: thumbVerticalStyleDefault }), {
                    ref: thumbVertical,
                }),
            ),
        ]);
    },
);
Scrollbar.displayName = "Scrollbar";

interface Position {
    /**
     * scrollTop progress, from 0 to 1.
     */
    top: number;
    /**
     * scrollLeft progress, from 0 to 1.
     */
    left: number;
    /**
     * Width of the view.
     */
    clientWidth: number;
    /**
     * Height of the view.
     */
    clientHeight: number;
    /**
     * Native scrollWidth.
     */
    scrollWidth: number;
    /**
     * Native scrollHeight.
     */
    scrollHeight: number;
    /**
     * Native scrollLeft.
     */
    scrollLeft: number;
    /**
     * Native scrollTop.
     */
    scrollTop: number;
}

export type Renderer = ({ style }: { style?: React.CSSProperties }) => ReactElement;

// For more details on props see
// https://github.com/malte-wessel/react-custom-scrollbars/blob/master/docs/API.md#props
export interface ScrollbarProps {
    onScroll?: (event: Event) => void;
    /**
     * Runs inside the animation frame.
     */
    onScrollFrame?: (values: Position) => void;
    onScrollStart?: () => void;
    onScrollStop?: () => void;
    /**
     * Called when ever the component is updated. Runs inside the animation frame.
     */
    onUpdate?: (values: Position) => void;
    /**
     * The element your content will be rendered in.
     */
    renderView?: Renderer;
    /**
     * Horizontal track element.
     */
    renderTrackHorizontal?: Renderer;
    /**
     * Vertical track element.
     */
    renderTrackVertical?: Renderer;
    /**
     * Horizontal thumb element.
     */
    renderThumbHorizontal?: Renderer;
    /**
     * Vertical thumb element.
     */
    renderThumbVertical?: Renderer;
    tagName?: string;
    /**
     * Set a fixed size for thumbs in px.
     */
    thumbSize?: number;
    /**
     * Minimal thumb size in px. Defaults to 30.
     */
    thumbMinSize?: number;
    /**
     * Hide tracks (`visibility: hidden`) when content does not overflow container. Defaults to
     * false.
     */
    hideTracksWhenNotNeeded?: boolean;
    /**
     * Enable auto-hide mode. Defaults to false.
     * When `true`, tracks will hide automatically and are only visible while scrolling.
     */
    autoHide?: boolean;
    /**
     * Hide delay in ms. Defaults to 1000.
     */
    autoHideTimeout?: number;
    /**
     * Duration for hide animation in ms. Defaults to 200.
     */
    autoHideDuration?: number;
    /**
     * Enable auto-height mode. Defaults to false.
     */
    autoHeight?: boolean;
    /**
     * Set a minimum height for auto-height mode. Defaults to 0.
     */
    autoHeightMin?: number | string;
    /**
     * Set a maximum height for auto-height mode. Defaults to 200.
     */
    autoHeightMax?: number | string;
    /**
     * Enable universal rendering. Defaults to false.
     */
    universal?: boolean;
    style?: CSSProperties;
    children?: ReactNode;
}
