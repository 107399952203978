import clsx from "clsx";
import { Popover } from "components/Popover";
import {
    ColumnFilterProps,
    FilterButton,
    FilterPopoverFooter,
    FilterPopoverHeader,
    FilterPopoverPreview,
} from "components/Table/ColumnFilter/ColumnFilter";
import { TextField, TextFieldWidth } from "components/TextInput";
import { useLatest } from "hooks/useLatest";
import React, { useEffect, useRef, useState } from "react";
import "./ColumnFilter.scss";

export type TextColumnFilterProps = ColumnFilterProps<string>;

/**
 * A simple text column filter that allows the user to enter and apply a single text value.
 */
export function TextColumnFilter({
    everId,
    columnName,
    filterValue = "",
    setFilterValue,
    previewCount,
    setPreviewFilterValue,
    previewLoading = false,
    otherActiveFilters,
    showPopover,
    setShowPopover,
    popoverPlacement,
    popoverEverId,
    tooltipPlacement,
}: TextColumnFilterProps) {
    const buttonRef = useRef(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [inputValue, setInputValue] = useState<string>(filterValue);
    const [inputValueChanged, setInputValueChanged] = useState(false);
    const disableSave = !inputValue;
    const description = `Filter by ${columnName}`;
    const filterValueRef = useLatest(filterValue);

    useEffect(() => {
        if (showPopover) {
            // Setting autoFocus on the TextInput doesn't work because autoFocus focuses the element
            // when it is mounted. Popover content is mounted even when hidden, so when the popover
            // is shown, it isn't remounted and the autoFocus won't be triggered.
            inputRef.current?.focus();
        } else {
            setInputValue(filterValueRef.current);
            setInputValueChanged(false);
        }
        // Only reset the input when the popover is hidden, not when the filter value changes.
    }, [showPopover, filterValueRef]);

    useEffect(() => {
        setInputValue(filterValue);
    }, [filterValue]);

    return (
        <>
            <FilterButton
                everId={everId}
                ref={buttonRef}
                filterApplied={!!filterValue}
                descriptionText={description}
                showPopover={showPopover}
                setShowPopover={setShowPopover}
                tooltipPlacement={tooltipPlacement}
            />
            <Popover
                everId={popoverEverId}
                show={showPopover}
                setShow={setShowPopover}
                target={buttonRef}
                aria-label={description}
                renderOutsideParent={true}
                placement={popoverPlacement}
                footer={
                    <FilterPopoverFooter
                        onCancel={() => setShowPopover(false)}
                        onDelete={() => {
                            setShowPopover(false);
                            setFilterValue(undefined);
                        }}
                        onSave={() => {
                            setShowPopover(false);
                            setFilterValue(inputValue);
                        }}
                        hasSavedValue={!!filterValue}
                        disableSave={disableSave}
                        applyCount={null}
                    />
                }
            >
                <div className={clsx("bb-column-filter", "bb-text-column-filter")}>
                    <FilterPopoverHeader
                        headingText={description}
                        disableClear={!inputValue}
                        onClear={() => {
                            setInputValue("");
                            setInputValueChanged(true);
                            setPreviewFilterValue("");
                            inputRef.current?.focus();
                        }}
                    />
                    <TextField
                        ref={inputRef}
                        label={"Value"}
                        placeholder={"Enter value"}
                        width={TextFieldWidth.FULL}
                        value={inputValue}
                        onChange={(e) => {
                            setInputValue(e.target.value);
                            setInputValueChanged(true);
                            setPreviewFilterValue(e.target.value);
                        }}
                    />
                    {(inputValue || inputValueChanged) && (
                        <FilterPopoverPreview
                            previewCount={previewCount}
                            previewLoading={previewLoading}
                            otherActiveFilters={otherActiveFilters}
                        />
                    )}
                </div>
            </Popover>
        </>
    );
}

export function getTextFilterDisplay(filterValue?: string): string {
    return filterValue || "";
}
