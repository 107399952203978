import clsx from "clsx";
import { IconButtonProps } from "components/Button";
import { IconProps } from "components/Icon/IconProps";
import { InputWrapper, describedBy as baseDescribedBy } from "components/util/InputWrapper";
import {
    baseInputProps,
    formatButton,
    formatIcon,
    TextInputAutoComplete,
    TextInputProps,
} from "components/TextInput/TextInput";
import { TooltipPlacement, useEllipsisTooltip } from "components/Tooltip";
import { everIdProp } from "EverAttribute/EverId";
import React, {
    CSSProperties,
    forwardRef,
    MutableRefObject,
    ReactElement,
    Ref,
    useEffect,
    useId,
    useRef,
    useState,
} from "react";
import * as CSS from "csstype";
import * as TextInputTokens from "tokens/typescript/TextInputTokens";
import { getTextWidth } from "util/dom";
import { getSizePx } from "util/css";

// These constants should be the same as those declared in text-input-tokens.json. This is ensured
// in build-time tests in TextField.test.tsx. If any constants should be added here,
// corresponding constants must be added to that file, and assertions added to those tests.
export enum TextFieldWidth {
    STANDARD = "256px",
    DATE_TIME = "112px",
    PERCENTAGE = "64px",
    FULL = "100%",
    FLEXIBLE = "",
}

export enum TextFieldFontSize {
    SMALL = "small",
    MEDIUM = "medium",
    LARGE = "large",
    EXTRA_LARGE = "extra-large",
}

export enum TextFieldHeight {
    SMALL = "small",
    LARGE = "large",
}

export enum TextFieldAlignment {
    LEFT = "left",
    RIGHT = "right",
}

export enum TextFieldInputType {
    PASSWORD = "password",
    TEXT = "text",
}

export interface TextFieldProps extends TextInputProps<HTMLInputElement> {
    /**
     * The aria-controls prop for the input element. Not used outside Bluebook.
     */
    "aria-controls"?: string;
    /**
     * The aria-expanded prop for the input element. Not used outside Bluebook.
     */
    "aria-expanded"?: boolean;
    /**
     * The aria-placeholder for the input element.
     */
    "aria-placeholder"?: string;
    /**
     * The aria-readonly prop for the text field. Not used outside Bluebook.
     */
    "aria-readonly"?: boolean;
    /**
     * Whether the text field is active. Usually left unset and delegated to the browser.
     */
    active?: boolean;
    /**
     * Whether to align the text in the text field to the left or right of the text field. Defaults
     * to left.
     */
    alignment?: TextFieldAlignment;
    /**
     * If true, will ellipsify the text if it extends beyond the width of the input box.
     * Defaults to false.
     */
    ellipsify?: boolean;
    /**
     * The font size of the input within the text field (not the text of the label/error/helper).
     * Defaults to {@link TextFieldFontSize.MEDIUM}.
     */
    fontSize?: TextFieldFontSize;
    /**
     * The height of the input. Defaults to 32px, but can be made 24px for specific use cases.
     */
    height?: TextFieldHeight;
    /**
     * The left icon for this text field.
     */
    leftIcon?: ReactElement<IconProps>;
    /**
     * The max width of the input element in pixels. Useful for flexible text fields.
     *
     * Default value depends on the width of the text field. See `text-input-tokens.json`
     * for details.
     */
    maxWidth?: number;
    /**
     * The right buttons for this text field. Accepts either a single IconButton, or an array of
     * icon buttons.
     *
     * Though it is possible, more than two IconButtons should not be provided.
     *
     * If an array is provided, a unique key prop must be specified for each element.
     */
    rightButtons?: ReactElement<IconButtonProps> | ReactElement<IconButtonProps>[];
    /**
     * The role for the text field. Not used outside Bluebook.
     */
    role?: "textbox" | "combobox";
    /**
     * A suffix to be placed within the text input on the right-hand side.
     */
    suffix?: string;
    /**
     * The type of the input. Defaults to TEXT.
     */
    type?: TextFieldInputType;
    /**
     * The width of the text field. Defaults to {@link TextFieldWidth.FLEXIBLE}.
     */
    width?: TextFieldWidth | CSS.Property.Width;
    /**
     * An optional ref to pass for the wrapper around the input element and its overlay.
     */
    wrapperRef?: Ref<HTMLDivElement>;
}

function describedBy(
    inputId: string,
    hasErrorMessage: boolean,
    hasHelper: boolean,
    hasSuffix: boolean,
    ariaErrorMessage?: string,
) {
    return (
        clsx(baseDescribedBy(inputId, hasErrorMessage, hasHelper, ariaErrorMessage), {
            [`${inputId}__suffix`]: hasSuffix,
        }) || undefined
    );
}

/**
 * When the text field is {@link TextFieldWidth.FLEXIBLE}, we need to update the width of the text
 * field such that the inner width of the field is as wide as the text inside it (clamped to min/max
 * width, by css rules). This calculates the width that the wrapper for the text input needs to be
 * based on the text inside the input, as well as any border and padding currently on the input.
 *
 * @param input the input for the text field
 * @param maxWidth the max-width for the text field
 */
function desiredWidth(input: HTMLInputElement, maxWidth: number): CSS.Property.Width {
    const text = input.value || input.placeholder;
    const textWidth = getTextWidth(text, input);
    const inputStyle = window.getComputedStyle(input);
    return `min(${maxWidth}px, calc(
        ${textWidth}px
        + ${inputStyle.borderLeftWidth}
        + ${inputStyle.paddingLeft}
        + ${inputStyle.paddingRight}
        + ${inputStyle.borderRightWidth}
    ))`;
}

function initialWidth(
    width?: TextFieldProps["width"],
    value?: string,
    placeholder?: string,
    suffix?: string,
): CSS.Property.Width | undefined {
    if (width === TextFieldWidth.FLEXIBLE) {
        suffix &&= suffix + " ";
        return `${(value || placeholder || "").length + (suffix || "").length}ch`;
    } else {
        return width;
    }
}

interface TextFieldStyle extends CSS.Properties {
    "--bb-textField-buttonCount": number;
    "--bb-textField-maxWidth"?: CSS.Property.MaxWidth;
    "--bb-textField-width-suffix": CSS.Property.Width;
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
    (
        {
            width = TextFieldWidth.FLEXIBLE,
            ellipsify = false,
            fontSize = TextFieldFontSize.MEDIUM,
            height = TextFieldHeight.LARGE,
            alignment = TextFieldAlignment.LEFT,
            autoComplete = TextInputAutoComplete.OFF,
            id,
            everId,
            errorMessage = "This field is required",
            type = TextFieldInputType.TEXT,
            role = "textbox",
            "aria-expanded": ariaExpanded,
            "aria-controls": ariaControls,
            "aria-placeholder": ariaPlaceholder,
            "aria-readonly": ariaReadOnly,
            maxWidth,
            ...props
        },
        ref,
    ) => {
        const generatedId = useId();
        id = id || generatedId;
        // aria-readonly is overridden by dropdown menus
        ariaReadOnly = ariaReadOnly === undefined ? props.readOnly : ariaReadOnly;

        if (!maxWidth) {
            maxWidth =
                width === TextFieldWidth.FLEXIBLE
                    ? getSizePx(TextInputTokens.MAX_WIDTH_FLEXIBLE)
                    : getSizePx(TextInputTokens.MAX_WIDTH_STANDARD);
        }

        props.leftIcon &&= formatIcon(props.leftIcon, { className: "bb-text-field__icon" });

        if (Array.isArray(props.rightButtons)) {
            props.rightButtons = props.rightButtons.map((b) =>
                formatButton(b, {
                    className: "bb-text-field__button",
                }),
            );
        } else if (props.rightButtons) {
            props.rightButtons = formatButton(props.rightButtons, {
                className: "bb-text-field__button",
            });
        }

        const rightButtonCount = Array.isArray(props.rightButtons)
            ? props.rightButtons.length
            : props.rightButtons
              ? 1
              : 0;

        const [suffixWidth, setSuffixWidth] = useState<CSS.Property.Width>(0);
        const suffixRef = useRef<HTMLSpanElement>(null);
        useEffect(() => {
            if (suffixRef.current) {
                setSuffixWidth(`${getTextWidth(props.suffix || "", suffixRef.current)}px`);
            }
        }, [props.suffix, props.className]);

        const internalRef = useRef<HTMLInputElement>();
        const inputRef = (ref || internalRef) as MutableRefObject<HTMLInputElement>;
        const { tooltipComponent, tooltipTargetProps } = useEllipsisTooltip<HTMLDivElement>({
            children: props.value,
            placement: [
                TooltipPlacement.BOTTOM,
                TooltipPlacement.BOTTOM_START,
                TooltipPlacement.BOTTOM_END,
            ],
            targetRef: inputRef,
        });
        const { className: tooltipClassName, ref: tooltipRef } = tooltipTargetProps;

        // When the input is FLEXIBLE, we have to shrink and grow the input as the user enters text.
        // So, as the user enters text, we recalculate the correct width that the input *should* be
        // based on the text currently inside it and the styles currently applied to it. Annoyingly,
        // we can't know the correct starting width of the input until we actually place it in the
        // page, as we don't know all the styles applied to the input ahead of time. So, to avoid the
        // input resizing by a great deal on render, we approximate the correct width using CSS ch
        // units, before updating it to the correct width on render.
        const [wrapperWidth, setWrapperWidth] = useState(
            initialWidth(width, props.value, props.placeholder, props.suffix),
        );
        const wrapperStyle: CSSProperties = { width: wrapperWidth };
        useEffect(() => {
            if (width === TextFieldWidth.FLEXIBLE) {
                inputRef.current && setWrapperWidth(desiredWidth(inputRef.current, maxWidth));
            } else {
                setWrapperWidth(initialWidth(width, props.value, props.placeholder, props.suffix));
            }
        }, [
            width,
            props.className,
            props.value,
            props.placeholder,
            props.rightButtons,
            props.leftIcon,
            props.suffix,
            inputRef,
            maxWidth,
        ]);

        const showOverlay = !!props.leftIcon || !!rightButtonCount || !!props.suffix;

        const rootClass = clsx(
            "bb-text-field",
            `bb-text-field--${fontSize}-font`,
            `bb-text-field--${alignment}-aligned`,
            props.className,
            {
                "bb-text-field--error": props.error,
                "bb-text-field--required": props.required,
                "bb-text-field--disabled": props.disabled,
                "bb-text-field--read-only": props.readOnly,
                "bb-text-field--with-left-icon": props.leftIcon,
                "bb-text-field--with-right-buttons": rightButtonCount,
                "bb-text-field--with-suffix": props.suffix,
                "bb-text-field--horizontal": props.horizontal,
                "bb-text-field--active": props.active,
                "bb-text-field--ellipsed": ellipsify,
                "bb-text-field--flexible-width": width === TextFieldWidth.FLEXIBLE,
                "bb-text-field--full-width": width === TextFieldWidth.FULL,
            },
        );

        const style: TextFieldStyle = {
            "--bb-textField-buttonCount": rightButtonCount,
            "--bb-textField-width-suffix": suffixWidth,
            "--bb-textField-maxWidth": maxWidth + "px",
        };

        return (
            <div className={rootClass} style={style}>
                <InputWrapper
                    inputId={id}
                    label={props.label}
                    subLabel={props.subLabel}
                    info={props.info}
                    errorMessage={props.error ? errorMessage : undefined}
                    helper={props.helper}
                    hideLabel={props.hideLabel}
                    required={props.required}
                    horizontal={props.horizontal}
                >
                    <div
                        className={clsx(
                            "bb-text-field__input-wrapper",
                            `bb-text-field--${height}-height`,
                            tooltipClassName,
                        )}
                        style={wrapperStyle}
                        ref={props.wrapperRef}
                    >
                        <input
                            {...baseInputProps({ ...props, id, errorMessage })}
                            aria-describedby={describedBy(
                                id,
                                !!errorMessage,
                                !!props.helper,
                                !!props.suffix,
                                props["aria-errormessage"],
                            )}
                            className="bb-text-field__input"
                            onChange={props.onChange}
                            onClick={props.onClick}
                            onBlur={props.onBlur}
                            onKeyDown={props.onKeyDown}
                            onKeyUp={props.onKeyUp}
                            onFocus={props.onFocus}
                            onScroll={props.onScroll}
                            ref={tooltipRef}
                            type={type}
                            role={role}
                            aria-controls={ariaControls}
                            aria-expanded={ariaExpanded}
                            aria-placeholder={ariaPlaceholder}
                            aria-readonly={ariaReadOnly}
                            {...everIdProp(everId)}
                        />
                        {type !== TextFieldInputType.PASSWORD && tooltipComponent}
                        {showOverlay && (
                            <div className="bb-text-field__input-overlay">
                                {props.leftIcon && (
                                    <div className="bb-text-field__input-left-overlay">
                                        {props.leftIcon}
                                    </div>
                                )}
                                {(!!rightButtonCount || props.suffix) && (
                                    <div className="bb-text-field__input-right-overlay">
                                        {props.suffix && (
                                            <span
                                                className="bb-text-field__suffix"
                                                id={`${id}__suffix`}
                                                ref={suffixRef}
                                            >
                                                {props.suffix}
                                            </span>
                                        )}
                                        {props.rightButtons}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </InputWrapper>
            </div>
        );
    },
);
TextField.displayName = "TextField";
