import { useRef } from "react";

/**
 * Returns a readonly RefObject-like object that always has the `current` value set to the latest
 * object passed to it. This hook is useful as a wrapper that provides a stable reference for
 * objects/arguments/props that don't maintain referential equality between renders.
 * @param value Value to store in the returned object.
 */
export function useLatest<V>(value: V): { readonly current: V } {
    const ref = useRef<V>(value);
    ref.current = value;
    return ref;
}
