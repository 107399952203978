import { useBrandedCallback } from "hooks/useBranded";
import { useEventListener } from "hooks/useEventListener";
import { MultiValueRefObject, refObjectValues } from "hooks/useMultiValueRef";
import { RefObject } from "react";
import { filterNonNullish } from "util/array";
import { getFocusableElements } from "util/dom";

export enum ArrowNavDirection {
    UP_DOWN,
    LEFT_RIGHT,
}

export interface UseArrowNavProps {
    /**
     * The direction of the arrow navigation. Defaults to {@link ArrowNavDirection.UP_DOWN}.
     */
    direction?: ArrowNavDirection;
    /**
     * If true, arrow navigation will only include the tabbable elements in the container(s).
     * If false, all focusable elements in the container(s) will be navigable by arrow keys,
     * whether or not they are tabbable. Note that elements with a tabIndex of -1 are focusable
     * but not tabbable. Defaults to true.
     */
    tabbableElementsOnly?: boolean;
    /**
     * Only applicable when {@link tabbableElementsOnly} is true.
     *
     * Whether to filter out tabbable elements hidden by CSS that would normally
     * remove them from the tabbing order. Defaults to false.
     */
    filterHidden?: boolean;
    /**
     * An array of CSS selectors to exclude from the arrow navigation. Defaults to [].
     */
    excludeSelectors?: string[];
    /**
     * Whether to exclude children of elements captured by {@link excludeSelectors}.
     *
     * Defaults to false.
     */
    excludeChildren?: boolean;
}

/**
 * Enable arrow keys to navigate through elements within a given container element.
 * Depending on the {@link UseArrowNavProps.direction}, the up/left arrow key will act like
 * `Shift + Tab`, and the down/right arrow key will act like `Tab`. Unlike Tab key navigation,
 * arrow key navigation will "wrap around" once it reaches either the first or the last
 * tabbable element in the container.
 */
export function useArrowNav<E extends Element = Element>(
    // Disabling any here, since using a type parameter would be overly wordy, confusing, and
    // pointless. We actually only care about the values.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    containerRef: RefObject<E> | MultiValueRefObject<any, E>,
    {
        filterHidden = false,
        direction = ArrowNavDirection.UP_DOWN,
        tabbableElementsOnly = true,
        excludeSelectors = [],
        excludeChildren = false,
    }: UseArrowNavProps = {},
): void {
    const arrowKeyListener = useBrandedCallback(
        (e: Event) => {
            if (!(e instanceof KeyboardEvent)) {
                return;
            }
            const currentlyFocusedElement = document.activeElement;
            const containers = filterNonNullish(refObjectValues(containerRef));
            const matchesDirection =
                (direction === ArrowNavDirection.UP_DOWN
                    && (e.key === "ArrowUp" || e.key === "ArrowDown"))
                || (direction === ArrowNavDirection.LEFT_RIGHT
                    && (e.key === "ArrowLeft" || e.key === "ArrowRight"));
            if (
                matchesDirection
                && currentlyFocusedElement
                && containers.some((c) => c.contains(currentlyFocusedElement))
            ) {
                const navigableElements = getFocusableElements(containers, {
                    excludeSelectors,
                    excludeChildren,
                    filterHidden,
                    tabbableElementsOnly,
                });
                const currentIndex =
                    navigableElements.indexOf(currentlyFocusedElement) + navigableElements.length;
                const nextIndex =
                    (currentIndex + (e.key === "ArrowUp" || e.key === "ArrowLeft" ? -1 : 1))
                    % navigableElements.length;
                (navigableElements[nextIndex] as HTMLElement).focus();
                e.preventDefault(); // prevent arrow keydown events from scrolling
                e.stopPropagation();
            }
        },
        // Disable exhaustive-deps lint rule so that we can use excludeSelectors.join() to check if any
        // of the selectors have changed. Otherwise, useCallback could be unnecessarily triggered
        // if the array does not have referential equality between renders.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [containerRef, direction, tabbableElementsOnly, filterHidden, excludeSelectors.join(",")],
    );
    useEventListener(containerRef, "keydown", arrowKeyListener);
}
