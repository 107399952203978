import { Memo } from "hooks/useBranded";
import {
    Dispatch,
    MutableRefObject,
    SetStateAction,
    useCallback,
    useMemo,
    useRef,
    useState,
} from "react";

// Default delay to trigger hold, in ms
export const HOLD_DELAY = 500;

function cancelTimeout(id: MutableRefObject<number | null>): void {
    if (id.current !== null) {
        window.clearTimeout(id.current);
        id.current = null;
    }
}

function clear(
    setSuspended: Dispatch<SetStateAction<boolean>>,
    timeoutId: MutableRefObject<number | null>,
): void {
    setSuspended(false);
    cancelTimeout(timeoutId);
}

function consume<T>(
    consumer: ((item: T) => void) | null,
    itemRef: MutableRefObject<T | null>,
): void {
    if (itemRef.current !== null) {
        consumer?.(itemRef.current);
        itemRef.current = null;
    }
}

export interface UseHoldResult<T> {
    start: (item: T) => void;
    release: () => void;
    cancel: () => void;
    suspend: () => void;
    restart: () => void;
}

/**
 * This hook takes an onHeld and onReleased handler and creates a set of callbacks
 * to handle initiate-and-hold behavior for objects. The most common use-case is click-and-hold,
 * or keypress-and-hold, where you want to keep track of the event that initiated the cycle, and
 * call some function when pressed, and a different function when pressed and held.
 *
 * Given an onHeld handler and onReleased handler (both optional), sets up and returns a set of
 * callbacks that allow the user to handle initiate-and-hold. Optionally, the caller may pass a
 * hold delay as a third parameter, which will determine how long the action be held before
 * initiating the onHeld handler.
 *
 * Returns five callbacks:
 *  - start: Initiates the hold. Must be called with the item that should be passed to the given
 *      onHeld and onReleased handlers after being held or released. If none of the other returned
 *      callbacks are called before the provided delay, onHeld will be called with the item passed
 *      to this callback.
 * - release: Ends the hold. If called without first calling start, or after the delay has passed
 *      following a call to start, this function has no effect. If called while a hold is ongoing
 *      following a call to start, will cancel the ongoing hold and call the provided onReleased
 *      with the item passed to start for the ongoing hold.
 * - cancel: Ends the hold, and clears the item passed to start, without calling any given handler.
 *      Has no effect if called without first calling start, or after the delay has passed
 *      following a call to start.
 * - suspend: Ends the hold, but does not clear the item passed to start, or call any given handler.
 *      Has no effect if called without first calling start, or after the delay has passed
 *      following a call to start. Effectively pauses the hold (without keeping track of held time)
 *      so that the hold can be restarted with the same item by calling restart.
 * - restart: Restarts the hold with the item already passed to start. Has no effect if called
 *      without first calling start, or after the delay has passed following a call to start.
 *      Used to resume a hold with the original item passed to start following a call to suspend.
 *
 * To see an example of how these all might be used, see {@link useButtonRole}.
 *
 * @param onHeld The function to call when the hold is initiated and not cancelled before the
 *      given delay expires. When called within this hook, will be called with the item most
 *      recently passed to start.
 * @param onReleased The function to call when the hold is initiated and ended through the returned
 *      end callback, before the given delay expires. When called within this hook, will be called
 *      with the item most recently passed to start.
 * @param delay The amount of time, in milliseconds, to wait after the returned start callback is
 *      call. Defaults to {@link HOLD_DELAY}.
 */
export function useHold<T>(
    onHeld?: Memo<(item: T) => void>,
    onReleased?: Memo<(item: T) => void>,
    delay: number = HOLD_DELAY,
): UseHoldResult<T> {
    const timeoutId: MutableRefObject<number | null> = useRef<number>(null);
    const item: MutableRefObject<T | null> = useRef<T>(null);
    const [suspended, setSuspended] = useState(false);
    const start = useCallback(
        (i: T) => {
            if (timeoutId.current) {
                return;
            }
            setSuspended(false);
            item.current = i;
            timeoutId.current = window.setTimeout(
                () => {
                    timeoutId.current = null;
                    if (item.current === null) {
                        return;
                    }
                    if (onHeld) {
                        // If no onHeld is specified, leave item alone and fall through to release or cancel
                        consume(onHeld, item);
                    }
                },
                onHeld ? delay : 0,
            );
        },
        [onHeld, delay],
    );
    const release = useCallback(() => {
        if (suspended) {
            clear(setSuspended, timeoutId);
            consume(null, item);
            return;
        }
        clear(setSuspended, timeoutId);
        consume(onReleased || null, item);
    }, [onReleased, suspended]);
    const cancel = useCallback(() => {
        clear(setSuspended, timeoutId);
        consume(null, item);
    }, []);
    const suspend = useCallback(() => {
        cancelTimeout(timeoutId);
        setSuspended(true);
    }, []);
    const restart = useCallback(() => {
        clear(setSuspended, timeoutId);
        if (item.current !== null) {
            start(item.current);
        }
    }, [start]);
    return useMemo(
        () => ({
            start,
            release,
            cancel,
            suspend,
            restart,
        }),
        [start, release, cancel, suspend, restart],
    );
}
