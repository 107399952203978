import { TooltipProps } from "components/Tooltip";
import { everIdProp } from "EverAttribute/EverId";
import React, {
    FC,
    ReactNode,
    useId,
    ChangeEventHandler,
    ReactElement,
    cloneElement,
    useRef,
} from "react";
import clsx from "clsx";
import "../Checkbox/Selector.scss";
import * as Icon from "components/Icon";
import { EverColor } from "tokens/typescript/EverColor";
import { EverIdProp } from "util/type";

export interface RadioProps extends EverIdProp {
    /**
     * True if selected and false if not selected.
     * Defaults to false.
     */
    value?: boolean;
    /**
     * The radio's label. Required at all times for accessibility purposes.
     * If you don't want to display a label, set {@link hideLabel} to true.
     */
    label: ReactNode;
    /**
     * If true, do not display {@link label} and {@link subLabel}. The label and sub-label will
     * still be accessible to assistive technologies.
     * Defaults to false.
     */
    hideLabel?: boolean;
    /**
     * Optional text underneath the label.
     */
    subLabel?: ReactNode;
    /**
     * The name attribute of the radio's input element, where radios in the same group have the
     * same name attribute. This name is not exposed to the user, but used to associate radios
     * in the same group for keyboard navigation purposes.
     */
    name: string;
    /**
     * A tooltip to render on the radio. The tooltip will point to the radio, and will be
     * triggered when the label is hovered or the input is focused.
     */
    tooltip?: ReactElement<TooltipProps>;
    /**
     * The action taken when the radio is clicked. Note that radios do not change their `value`
     * internally, so this function should probably update `value` via a hook.
     */
    onChange: ChangeEventHandler<HTMLInputElement>;
    /**
     * If true, the radio cannot be selected.
     * Defaults to false.
     */
    disabled?: boolean;
    /**
     * If true, unselected radios are red.
     * Defaults to false.
     */
    error?: boolean;
    /**
     * Optional content to display under the label, and sub label (if present).
     */
    children?: ReactNode;
}

/**
 * A controlled radio component
 */
export const Radio: FC<RadioProps> = ({
    everId,
    value = false,
    label,
    hideLabel = false,
    subLabel,
    name,
    tooltip,
    onChange,
    disabled = false,
    error = false,
    children,
}) => {
    const inputId = useId();
    const inputRef = useRef<HTMLInputElement>(null);
    const iconWrapperRef = useRef<HTMLDivElement>(null);
    const labelRef = useRef<HTMLLabelElement>(null);

    const topClass = clsx("bb-selector", {
        "bb-selector--disabled": disabled,
    });

    tooltip &&= cloneElement(tooltip, {
        id: `${inputId}__tooltip`,
        target: iconWrapperRef,
        hoverTrigger: tooltip?.props.hoverTrigger || labelRef,
        focusTrigger: tooltip?.props.focusTrigger || inputRef,
    });

    const describedBy = clsx({
        [`${inputId}__sublabel`]: subLabel,
        [`${inputId}__tooltip`]: tooltip,
    });

    return (
        <div className={topClass}>
            <div>
                <input
                    ref={inputRef}
                    id={inputId}
                    type={"radio"}
                    name={name}
                    aria-checked={value}
                    aria-invalid={error}
                    aria-describedby={describedBy}
                    checked={value}
                    onChange={disabled ? () => {} : onChange}
                    // Most elements automatically get scrolled into view on focus.
                    // Here, the input element which receives focus is absolutely positioned,
                    // which messes with this behavior.
                    onFocus={() => labelRef.current?.scrollIntoView({ block: "nearest" })}
                    aria-disabled={disabled}
                    className={"bb-selector__input"}
                />
                <label
                    ref={labelRef}
                    className={clsx("bb-selector__label", {
                        "bb-selector__label--hidden": hideLabel,
                    })}
                    htmlFor={inputId}
                    {...everIdProp(everId)}
                >
                    <div ref={iconWrapperRef} className={"bb-selector__icon-wrapper"}>
                        <RadioIcon value={value} disabled={disabled} error={error} />
                    </div>
                    <div className={"bb-selector__label-content"}>
                        <div className={"bb-selector__main-label"}>{label}</div>
                    </div>
                </label>
                {tooltip}
                {subLabel && (
                    <div
                        id={`${inputId}__sublabel`}
                        className={clsx("bb-selector__sublabel", {
                            "bb-selector__sublabel--hidden": hideLabel,
                        })}
                    >
                        {subLabel}
                    </div>
                )}
            </div>
            {!hideLabel && children}
        </div>
    );
};

interface RadioIconProps {
    value: boolean;
    disabled: boolean;
    error: boolean;
}

function RadioIcon({ value, disabled, error }: RadioIconProps) {
    return value ? (
        <Icon.RadioSelected
            size={20}
            color={EverColor.EVERBLUE_50}
            className={"bb-selector__icon"}
            aria-hidden={true}
        />
    ) : (
        <Icon.RadioUnselected
            size={20}
            color={error ? EverColor.RED_40 : EverColor.PARCHMENT_60}
            color2={disabled ? EverColor.PARCHMENT_30 : EverColor.WHITE}
            className={"bb-selector__icon bb-selector__icon--unselected"}
            aria-hidden={true}
        />
    );
}
