import clsx from "clsx";
import { IconButton } from "components/Button";
import { BasicChip } from "components/Chip/Chip";
import * as Icon from "components/Icon";
import { Popover } from "components/Popover";
import {
    ColumnFilterProps,
    FilterButton,
    FilterPopoverFooter,
    FilterPopoverHeader,
    FilterPopoverPreview,
    MultiValueHeading,
} from "components/Table/ColumnFilter/ColumnFilter";
import { TextField, TextFieldWidth } from "components/TextInput";
import React, { useEffect, useRef, useState } from "react";
import "./ColumnFilter.scss";

export type MultiTermColumnFilterProps = ColumnFilterProps<string[]>;

/**
 * A column filter that allows for adding multiple text values to filter across.
 */
export function MultiTermColumnFilter({
    everId,
    columnName,
    filterValue,
    setFilterValue,
    previewCount,
    setPreviewFilterValue,
    previewLoading = false,
    otherActiveFilters,
    showPopover,
    setShowPopover,
    popoverPlacement,
    popoverEverId,
    tooltipPlacement,
}: MultiTermColumnFilterProps) {
    const buttonRef = useRef(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [inputValue, setInputValue] = useState<string>("");
    const [filterTerms, setFilterTerms] = useState<string[]>(filterValue || []);
    const [termsChanged, setTermsChanged] = useState<boolean>(false);
    const description = `Filter by ${columnName}`;

    useEffect(() => {
        setFilterTerms(filterValue || []);
        if (showPopover) {
            inputRef.current?.focus();
        } else {
            setInputValue("");
            setPreviewFilterValue(filterValue || []);
            setTermsChanged(false);
        }
    }, [showPopover, filterValue, setPreviewFilterValue]);

    const addTerm = () => {
        if (!inputValue.trim().length) {
            return;
        }
        const newTerms = filterTerms.concat(inputValue.trim());
        setFilterTerms(newTerms);
        setPreviewFilterValue(newTerms);
        setInputValue("");
        setTermsChanged(true);
    };

    return (
        <>
            <FilterButton
                everId={everId}
                ref={buttonRef}
                filterApplied={!!filterValue}
                descriptionText={description}
                showPopover={showPopover}
                setShowPopover={setShowPopover}
                tooltipPlacement={tooltipPlacement}
            />
            <Popover
                everId={popoverEverId}
                show={showPopover}
                setShow={setShowPopover}
                target={buttonRef}
                aria-label={description}
                renderOutsideParent={true}
                placement={popoverPlacement}
                footer={
                    <FilterPopoverFooter
                        onCancel={() => setShowPopover(false)}
                        onDelete={() => {
                            setShowPopover(false);
                            setFilterValue(undefined);
                        }}
                        onSave={() => {
                            setShowPopover(false);
                            setFilterValue(filterTerms);
                        }}
                        hasSavedValue={!!filterValue}
                        disableSave={!filterTerms.length}
                        applyCount={filterTerms.length}
                    />
                }
            >
                <div className={clsx("bb-column-filter", "bb-multi-term-column-filter")}>
                    <FilterPopoverHeader
                        headingText={description}
                        disableClear={!filterTerms.length}
                        onClear={() => {
                            setInputValue("");
                            setFilterTerms([]);
                            setPreviewFilterValue([]);
                            inputRef.current?.focus();
                        }}
                    />
                    <TextField
                        ref={inputRef}
                        label={<MultiValueHeading />}
                        placeholder={"Enter value"}
                        width={TextFieldWidth.FULL}
                        value={inputValue}
                        onChange={(e) => {
                            setInputValue(e.target.value);
                        }}
                        // Using onKeyUp instead of onKeyDown so that addTerm isn't called
                        // repeatedly if the user holds down "Enter".
                        onKeyUp={(e) => e.key === "Enter" && addTerm()}
                        rightButtons={
                            <IconButton
                                aria-label={"Add term"}
                                disabled={!inputValue.trim().length}
                                onClick={() => {
                                    addTerm();
                                    inputRef.current?.focus();
                                }}
                            >
                                <Icon.CirclePlus />
                            </IconButton>
                        }
                    />
                    {filterTerms.length ? (
                        <div className={"bb-multi-term-column-filter__terms-container"}>
                            {filterTerms.map((term, index) => (
                                <BasicChip
                                    key={index}
                                    rightButton={
                                        <IconButton
                                            aria-label={"Remove filter"}
                                            onClick={() => {
                                                filterTerms.splice(index, 1);
                                                const newTerms = [...filterTerms];
                                                setFilterTerms(newTerms);
                                                setPreviewFilterValue(newTerms);
                                                setTermsChanged(true);
                                            }}
                                        >
                                            <Icon.X />
                                        </IconButton>
                                    }
                                >
                                    {term}
                                </BasicChip>
                            ))}
                        </div>
                    ) : (
                        <div className={"bb-multi-term-column-filter__empty-placeholder"}>
                            No values yet
                        </div>
                    )}
                    {(filterTerms.length || termsChanged) && (
                        <FilterPopoverPreview
                            previewCount={previewCount}
                            previewLoading={previewLoading}
                            otherActiveFilters={otherActiveFilters}
                        />
                    )}
                </div>
            </Popover>
        </>
    );
}

export function getMultiTermFilterDisplay(filterValue?: string[]): string {
    return filterValue ? filterValue.join(", ") : "";
}
