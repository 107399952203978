import clsx from "clsx";
import { IconButton, IconButtonProps } from "components/Button";
import * as Icon from "components/Icon";
import "./ObjectCard.scss";
import * as CommonIcon from "components/Icon/CommonIcon";
import { EllipsisDirection, useEllipsisTooltip } from "components/Tooltip";
import { PopoverPlacement } from "components/util/BasePopover";
import { everIdProp } from "EverAttribute/EverId";
import { Memo } from "hooks/useBranded";
import { useButtonRole } from "hooks/useButtonRole";
import { useCombinedRef } from "hooks/useCombinedRef";
import React, { CSSProperties, forwardRef, ReactNode, UIEventHandler, useRef } from "react";
import * as ColorTokens from "tokens/typescript/ColorTokens";
import { EverColor } from "tokens/typescript/EverColor";
import { inert } from "util/dom";
import { EverIdProp, FFC } from "util/type";

export type ObjectCardColor =
    | typeof ColorTokens.OBJECT_CARD_ASSIGNMENT
    | typeof ColorTokens.OBJECT_CARD_ASSIGNMENT_GROUP
    | typeof ColorTokens.OBJECT_CARD_BATCH_ACTION
    | typeof ColorTokens.OBJECT_CARD_BINDER
    | typeof ColorTokens.OBJECT_CARD_DEPOSITION
    | typeof ColorTokens.OBJECT_CARD_DRAFT
    | typeof ColorTokens.OBJECT_CARD_EXPORT
    | typeof ColorTokens.OBJECT_CARD_IMPORT
    | typeof ColorTokens.OBJECT_CARD_NATIVE
    | typeof ColorTokens.OBJECT_CARD_OTHER
    | typeof ColorTokens.OBJECT_CARD_PROCESSED
    | typeof ColorTokens.OBJECT_CARD_PRODUCTION
    | typeof ColorTokens.OBJECT_CARD_SEARCH
    | typeof ColorTokens.OBJECT_CARD_SEARCH_TERM_REPORT
    | typeof ColorTokens.OBJECT_CARD_STORY;

export interface ObjectCardProps extends EverIdProp {
    /**
     * Whether the card is active (comparable to :active). Note that this should usually be left
     * undefined, as active state is also handled internally.
     */
    active?: boolean;
    /**
     * The accessible label to apply to the card. Usually should be some combination of the title
     * and the text of the card.
     *
     * Defaults to the title, though in general, you should provide a better accessible name
     * for the card.
     */
    "aria-label"?: string;
    /**
     * The text content of the card.
     */
    children?: ReactNode;
    /**
     * An optional class name to add to the root element of the card.
     */
    className?: string;
    /**
     * The color to use for the title and the divider for the card.
     */
    color?: ObjectCardColor;
    /**
     * The element(s) to place in the bottom left of the card.
     */
    footerLeft?: ReactNode;
    /**
     * The element(s) to place in the bottom right of the card.
     */
    footerRight?: ReactNode;
    /**
     * The buttons to place in the top right of the card.
     */
    headerButtons?: ReactNode;
    /**
     * An optional id to apply to the card.
     */
    id?: string;
    /**
     * The action to take when the card is clicked. If this is left undefined, the card will be
     * unfocusable.
     */
    onClick?: Memo<UIEventHandler<HTMLDivElement>>;
    /**
     * The action to take when the card is clicked and held (for 500ms).
     */
    onClickHold?: Memo<UIEventHandler<HTMLDivElement>>;
    /**
     * Whether the card is currently selected.
     */
    selected?: boolean;
    /**
     * The status of the card (i.e. a progress bar, completed, success/fail doc counts).
     */
    status?: ReactNode;
    /**
     * The title of the card, displayed in the top left.
     */
    title: string;
}

interface ObjectCardCSSProperties extends CSSProperties {
    "--bb-objectCard-color": string;
}

type ObjectCardFC = FFC<HTMLDivElement, ObjectCardProps> & {
    FavoriteButton: FFC<HTMLButtonElement, ObjectCardFavoriteButtonProps>;
};

export const ObjectCard: ObjectCardFC = forwardRef(
    (
        {
            active,
            children,
            className,
            color = ColorTokens.OBJECT_CARD_OTHER,
            everId,
            footerLeft,
            footerRight,
            headerButtons,
            id,
            onClick,
            onClickHold,
            selected = false,
            status,
            title,
            "aria-label": ariaLabel = title,
        },
        externalRef,
    ) => {
        const actionable = !!(onClick || onClickHold);
        const style: ObjectCardCSSProperties = { "--bb-objectCard-color": color };
        const internalRef = useRef<HTMLDivElement>(null);
        const cardRef = useCombinedRef(internalRef, externalRef);
        const {
            hover,
            // TODO Active broken when clicking on text?
            active: internalActive,
            buttonProps,
        } = useButtonRole(internalRef, {
            "aria-label": ariaLabel,
            "aria-pressed": selected,
            onClick,
            onClickHold,
            enabled: actionable,
        });
        const { tooltipComponent: overflowTooltip, tooltipTargetProps } = useEllipsisTooltip({
            targetClassName: "bb-object-card__text",
            direction: EllipsisDirection.VERTICAL,
            children,
            "aria-hidden": true,
            ellipsisClassName: status
                ? "bb-ellipsis-overflow--one-line"
                : "bb-ellipsis-overflow--two-line",
            placement: [
                PopoverPlacement.BOTTOM,
                PopoverPlacement.TOP,
                PopoverPlacement.RIGHT,
                PopoverPlacement.LEFT,
            ],
        });
        return (
            <div
                {...buttonProps}
                aria-label={ariaLabel}
                className={clsx("bb-object-card", className, {
                    "bb-object-card--active": active ?? (actionable && internalActive),
                    "bb-object-card--selected": selected,
                    "bb-object-card--actionable": actionable,
                    "bb-object-card--hover": actionable && hover,
                })}
                style={style}
                ref={cardRef}
                id={id}
                {...everIdProp(everId)}
            >
                <div className={"bb-object-card__main"} {...inert(selected)}>
                    <div className={"bb-object-card__header"}>
                        <div className={"bb-object-card__header-title"}>
                            <div className={"bb-object-card__title"}>{title}</div>
                            <hr className={"bb-object-card__divider"} />
                        </div>
                        {headerButtons && (
                            <div className={"bb-object-card__header-buttons"}>{headerButtons}</div>
                        )}
                    </div>
                    <div {...tooltipTargetProps}>
                        {children}
                        {overflowTooltip}
                    </div>
                    {status && <div className={"bb-object-card__status"}>{status}</div>}
                    {(footerLeft || footerRight) && (
                        <div className={"bb-object-card__footer"}>
                            <div className={"bb-object-card__footer-left"}>{footerLeft}</div>
                            <div className={"bb-object-card__footer-right"}>{footerRight}</div>
                        </div>
                    )}
                </div>
                {selected && (
                    <div className={"bb-object-card__overlay"}>
                        <CommonIcon.SelectedCheck />
                    </div>
                )}
            </div>
        );
    },
) as ObjectCardFC;
ObjectCard.displayName = "ObjectCard";

export interface ObjectCardFavoriteButtonProps
    extends Omit<IconButtonProps, "onClick" | "aria-label" | "children"> {
    favorite: boolean;
    toggleFavorite: () => void;
    favoriteColor?: EverColor;
}

ObjectCard.FavoriteButton = forwardRef(
    (
        { favorite, toggleFavorite, favoriteColor = ColorTokens.ICON_BUTTON_FAVORITE, ...props },
        ref,
    ) => {
        return (
            <IconButton
                {...props}
                aria-label={`${favorite ? "Remove from" : "Add to"} favorites`}
                onClick={toggleFavorite}
                ref={ref}
            >
                {favorite ? (
                    <Icon.StarFilled size={20} color={favoriteColor} />
                ) : (
                    <Icon.Star size={20} />
                )}
            </IconButton>
        );
    },
);
ObjectCard.FavoriteButton.displayName = "ObjectCard.FavoriteButton";
