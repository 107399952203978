import clsx from "clsx";
import { IconProps } from "components/Icon/IconProps";
import * as Icon from "components/Icon/";
import React, { forwardRef } from "react";
import * as ColorTokens from "tokens/typescript/ColorTokens";
import { EverColor } from "tokens/typescript/EverColor";
import { FFC } from "util/type";
import "./CommonIcon.scss";

// The icons in this file are icons that we use in many different components. They are defined here
// with the key parameters so that they will look consistent in all of our usages. The user can
// still pass in all the other `IconProps` they wish, but won't be able to overwrite the important
// ones, like color for SuccessIcon.

export const AIAssist: FFC<SVGSVGElement, Omit<IconProps, "color">> = forwardRef<
    SVGSVGElement,
    Omit<IconProps, "color">
>(({ children, ...props }, ref) => {
    return <Icon.Sparkles {...props} ref={ref} color={ColorTokens.PRIMARY} children={children} />;
});
AIAssist.displayName = "AIAssist";

export const AutoCode: FFC<SVGSVGElement, Omit<IconProps, "color">> = forwardRef<
    SVGSVGElement,
    Omit<IconProps, "color">
>(({ children, ...props }, ref) => {
    return <Icon.Sparkle {...props} ref={ref} color={ColorTokens.PRIMARY} children={children} />;
});
AutoCode.displayName = "AutoCode";

export const AutoGenerating: FFC<SVGSVGElement, Omit<IconProps, "color">> = forwardRef<
    SVGSVGElement,
    Omit<IconProps, "color">
>(({ children, className, ...props }, ref) => {
    return (
        <Icon.Sparkles
            {...props}
            ref={ref}
            className={clsx("bb-icon--sparkling", className)}
            color={ColorTokens.PRIMARY}
            children={children}
        />
    );
});
AutoGenerating.displayName = "AutoGenerating";

export const Bookmarked: FFC<SVGSVGElement, Omit<IconProps, "color">> = forwardRef<
    SVGSVGElement,
    Omit<IconProps, "color">
>(({ children, ...props }, ref) => {
    return (
        <Icon.BookmarkFilled {...props} ref={ref} color={ColorTokens.PRIMARY} children={children} />
    );
});
Bookmarked.displayName = "Bookmarked";

export const Error: FFC<SVGSVGElement, Omit<IconProps, "color">> = forwardRef<
    SVGSVGElement,
    Omit<IconProps, "color">
>(({ children, ...props }, ref) => {
    return (
        <Icon.XCircleFilled {...props} ref={ref} color={ColorTokens.DANGER} children={children} />
    );
});
Error.displayName = "Error";

export const ErrorTriangle: FFC<SVGSVGElement, Omit<IconProps, "color">> = forwardRef<
    SVGSVGElement,
    Omit<IconProps, "color">
>(({ children, ...props }, ref) => {
    if (typeof children === "string") {
        children = <span className={"bb-text--color-danger"}>{children}</span>;
    }
    return (
        <Icon.AlertTriangle {...props} ref={ref} color={ColorTokens.DANGER} children={children} />
    );
});
ErrorTriangle.displayName = "ErrorTriangle";

export const Favorited: FFC<SVGSVGElement, Omit<IconProps, "color">> = forwardRef<
    SVGSVGElement,
    Omit<IconProps, "color">
>(({ children, ...props }, ref) => {
    return <Icon.StarFilled {...props} ref={ref} color={EverColor.YELLOW_40} children={children} />;
});
Favorited.displayName = "Favorited";

export const Loading: FFC<SVGSVGElement, IconProps> = forwardRef<SVGSVGElement, IconProps>(
    ({ children, className, ...props }, ref) => {
        return (
            <Icon.AnimatedLoader
                {...props}
                ref={ref}
                className={clsx("bb-icon--spinning", className)}
                children={children}
            />
        );
    },
);
Loading.displayName = "Loading";

export const SBBERestricted: FFC<SVGSVGElement, Omit<IconProps, "color">> = forwardRef<
    SVGSVGElement,
    Omit<IconProps, "color">
>(({ children, ...props }, ref) => {
    return (
        <Icon.CrownFilled
            {...props}
            ref={ref}
            color={ColorTokens.STORYBUILDER_PRIMARY}
            children={children}
        />
    );
});
SBBERestricted.displayName = "SBBERestricted";

export const SelectedCheck: FFC<SVGSVGElement, IconProps> = forwardRef(
    (
        {
            size = 48,
            color = EverColor.EVERBLUE_60,
            "aria-label": ariaLabel = "selected",
            ...props
        },
        ref,
    ) => {
        return <Icon.Check size={size} color={color} aria-label={ariaLabel} {...props} ref={ref} />;
    },
);
SelectedCheck.displayName = "SelectedCheck";

export const SmartSearch: FFC<SVGSVGElement, Omit<IconProps, "color">> = forwardRef<
    SVGSVGElement,
    Omit<IconProps, "color">
>(({ children, ...props }, ref) => {
    return <Icon.BoltFilled {...props} ref={ref} color={ColorTokens.PRIMARY} children={children} />;
});
SmartSearch.displayName = "SmartSearch";

export const SortAsc: FFC<SVGSVGElement, Omit<IconProps, "color" | "color2">> = forwardRef<
    SVGSVGElement,
    Omit<IconProps, "color" | "color2">
>(({ children, ...props }, ref) => {
    return (
        <Icon.SortAsc
            {...props}
            ref={ref}
            color={EverColor.PARCHMENT_70}
            color2={EverColor.PARCHMENT_50}
            children={children}
        />
    );
});
SortAsc.displayName = "SortAsc";

export const SortAscHover: FFC<SVGSVGElement, Omit<IconProps, "color" | "color2">> = forwardRef<
    SVGSVGElement,
    Omit<IconProps, "color" | "color2">
>(({ children, ...props }, ref) => {
    return (
        <Icon.SortAsc
            {...props}
            ref={ref}
            color={ColorTokens.PRIMARY}
            color2={EverColor.EVERBLUE_30}
            children={children}
        />
    );
});
SortAscHover.displayName = "SortAscHover";

export const SortDesc: FFC<SVGSVGElement, Omit<IconProps, "color" | "color2">> = forwardRef<
    SVGSVGElement,
    Omit<IconProps, "color" | "color2">
>(({ children, ...props }, ref) => {
    return (
        <Icon.SortDesc
            {...props}
            ref={ref}
            color={EverColor.PARCHMENT_70}
            color2={EverColor.PARCHMENT_50}
            children={children}
        />
    );
});
SortDesc.displayName = "SortDesc";

export const SortDescHover: FFC<SVGSVGElement, Omit<IconProps, "color" | "color2">> = forwardRef<
    SVGSVGElement,
    Omit<IconProps, "color" | "color2">
>(({ children, ...props }, ref) => {
    return (
        <Icon.SortDesc
            {...props}
            ref={ref}
            color={ColorTokens.PRIMARY}
            color2={EverColor.EVERBLUE_30}
            children={children}
        />
    );
});
SortDescHover.displayName = "SortDescHover";

export const SortHover: FFC<SVGSVGElement, Omit<IconProps, "color">> = forwardRef<
    SVGSVGElement,
    Omit<IconProps, "color">
>(({ children, ...props }, ref) => {
    return <Icon.SortHover {...props} ref={ref} color={ColorTokens.PRIMARY} children={children} />;
});
SortHover.displayName = "SortHover";

export const Success: FFC<SVGSVGElement, Omit<IconProps, "color">> = forwardRef<
    SVGSVGElement,
    Omit<IconProps, "color">
>(({ children, ...props }, ref) => {
    return (
        <Icon.CircleCheckFilled
            {...props}
            ref={ref}
            color={ColorTokens.SUCCESS}
            children={children}
        />
    );
});
Success.displayName = "Success";

export const Warning: FFC<SVGSVGElement, Omit<IconProps, "color">> = forwardRef<
    SVGSVGElement,
    Omit<IconProps, "color">
>(({ children, ...props }, ref) => {
    return (
        <Icon.AlertCircleFilled
            {...props}
            ref={ref}
            color={EverColor.YELLOW_40}
            children={children}
        />
    );
});
Warning.displayName = "Warning";
