import { useStorage } from "hooks/useStorage";
import { Dispatch, SetStateAction } from "react";

/**
 * This hook acts like {@link useState}, but persists the state to {@link sessionStorage}. The
 * stored value must be serializable to JSON.The next time the component is rendered, the state will
 * be initialized from {@link sessionStorage} (if it exists there) instead of using
 * {@link initialValue}.
 * @param key The key that the value is saved under in {@link sessionStorage}.
 * @param initialValue The initial value to saved to {@link sessionStorage} and returned if there is
 * no value for {@link key} in {@link sessionStorage}. The value must be serializable to JSON.
 */
export function useSessionStorage<T>(
    key: string,
    initialValue: T | (() => T),
): [T, Dispatch<SetStateAction<T>>] {
    return useStorage("sessionStorage", key, initialValue);
}
