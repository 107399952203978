import clsx from "clsx";
import { ButtonSize, TextButton } from "components/Button";
import { Checkbox } from "components/Checkbox";
import { Popover } from "components/Popover";
import {
    ColumnFilterProps,
    FilterButton,
    FilterPopoverFooter,
    FilterPopoverHeader,
    MultiValueHeading,
} from "components/Table/ColumnFilter/ColumnFilter";
import { Span } from "components/Text";
import { TextField, TextFieldWidth } from "components/TextInput";
import { Tooltip, TooltipPlacement } from "components/Tooltip";
import { useResizeObserver } from "hooks/useResizeObserver";
import React, { useEffect, useRef, useState } from "react";
import "./ColumnFilter.scss";

// The max number of options that can be displayed before the list becomes scrollable.
// Normally, we would use useResizeObserver to detect overflow, but that causes the UI to
// shift when the popover opens.
const MAX_OPTIONS_BEFORE_OVERFLOW = 6;

export interface CheckboxColumnFilterOption {
    /**
     * A string or number that identifies the option.
     */
    id: string | number;
    /**
     * The string to use as the checkbox label.
     */
    display: string;
    /**
     * The number of rows that match this filter option as well as all the other
     * currently applied filters.
     */
    count?: number;
}

interface CheckboxOptionProps {
    option: CheckboxColumnFilterOption;
    selected: boolean;
    onToggle: () => void;
}

function CheckboxOption({ option, selected, onToggle }: CheckboxOptionProps) {
    const [resizeRef, contentEntry] = useResizeObserver<HTMLDivElement>();
    const label = (
        <>
            <Span ref={resizeRef} className={"bb-checkbox-column-filter__option-display"}>
                {option.display}
            </Span>
            {option.count && (
                <Span className={"bb-checkbox-column-filter__option-count"}>{option.count}</Span>
            )}
        </>
    );
    const needsTooltip =
        contentEntry.target && contentEntry.target.scrollWidth > contentEntry.target.clientWidth;
    return (
        <div className={"bb-checkbox-column-filter__option"} key={option.id}>
            <Checkbox
                label={label}
                value={selected}
                tooltip={
                    needsTooltip ? (
                        <Tooltip placement={TooltipPlacement.BOTTOM_START}>
                            {option.display}
                        </Tooltip>
                    ) : undefined
                }
                onChange={onToggle}
            />
        </div>
    );
}

export type CheckboxColumnFilterProps = Omit<
    ColumnFilterProps<Set<string | number>>,
    "previewCount" | "setPreviewFilterValue" | "previewLoading" | "otherActiveFilters"
> & {
    /**
     * A list of all possible value options for the column.
     */
    options: CheckboxColumnFilterOption[];
};

/**
 * A column filter with checkboxes that allows filtering by multiple text values. Only use this
 * column filter when the possible values are enumerable and relatively few (<100).
 */
export function CheckboxColumnFilter({
    everId,
    columnName,
    filterValue,
    setFilterValue,
    showPopover,
    setShowPopover,
    popoverPlacement,
    popoverEverId,
    tooltipPlacement,
    options,
}: CheckboxColumnFilterProps) {
    const buttonRef = useRef(null);
    const filterInputRef = useRef<HTMLInputElement>(null);
    const [filterInputValue, setFilterInputValue] = useState("");
    const [selections, setSelections] = useState<Set<string | number>>(new Set(filterValue));
    const description = `Filter by ${columnName}`;

    const filteredOptions = filterInputValue.trim().length
        ? options.filter((option) => {
              return option.display.toLowerCase().includes(filterInputValue.trim().toLowerCase());
          })
        : options;

    useEffect(() => {
        setSelections(new Set(filterValue));
        if (showPopover) {
            filterInputRef.current?.focus();
        } else {
            setFilterInputValue("");
        }
    }, [showPopover, filterValue]);

    return (
        <>
            <FilterButton
                everId={everId}
                ref={buttonRef}
                filterApplied={!!filterValue}
                descriptionText={description}
                showPopover={showPopover}
                setShowPopover={setShowPopover}
                tooltipPlacement={tooltipPlacement}
            />
            <Popover
                everId={popoverEverId}
                show={showPopover}
                setShow={setShowPopover}
                target={buttonRef}
                aria-label={description}
                renderOutsideParent={true}
                placement={popoverPlacement}
                footer={
                    <FilterPopoverFooter
                        onCancel={() => setShowPopover(false)}
                        onDelete={() => {
                            setShowPopover(false);
                            setFilterValue(undefined);
                        }}
                        onSave={() => {
                            setShowPopover(false);
                            setFilterValue(selections);
                        }}
                        applyCount={selections?.size || 0}
                        hasSavedValue={!!filterValue}
                        disableSave={!selections?.size}
                    />
                }
            >
                <div className={clsx("bb-column-filter", "bb-checkbox-column-filter")}>
                    <FilterPopoverHeader headingText={description} hideClear={true} />
                    <div className={"bb-checkbox-column-filter__input-header"}>
                        <div>
                            <MultiValueHeading />
                        </div>
                        <div>
                            <TextButton
                                size={ButtonSize.SMALL}
                                disabled={false}
                                onClick={() => {
                                    setSelections(new Set(options.map((option) => option.id)));
                                }}
                            >
                                Select all
                            </TextButton>
                            /
                            <TextButton
                                size={ButtonSize.SMALL}
                                disabled={false}
                                onClick={() => setSelections(new Set())}
                            >
                                Select none
                            </TextButton>
                        </div>
                    </div>
                    <TextField
                        ref={filterInputRef}
                        label={"Filter values"}
                        hideLabel={true}
                        placeholder={"Filter values"}
                        width={TextFieldWidth.FULL}
                        value={filterInputValue}
                        onChange={(e) => setFilterInputValue(e.target.value)}
                    />
                    {filteredOptions.length ? (
                        <div
                            className={clsx("bb-checkbox-column-filter__option-list", {
                                "bb-checkbox-column-filter__option-list--overflow":
                                    filteredOptions.length > MAX_OPTIONS_BEFORE_OVERFLOW,
                            })}
                        >
                            {filteredOptions.map((option) => {
                                return (
                                    <CheckboxOption
                                        key={option.id}
                                        option={option}
                                        selected={selections.has(option.id)}
                                        onToggle={() => {
                                            if (selections.has(option.id)) {
                                                selections.delete(option.id);
                                            } else {
                                                selections.add(option.id);
                                            }
                                            setSelections(new Set(selections));
                                        }}
                                    />
                                );
                            })}
                        </div>
                    ) : (
                        <div className={"bb-checkbox-column-filter__empty-placeholder"}>
                            No results
                        </div>
                    )}
                </div>
            </Popover>
        </>
    );
}

export function getCheckboxFilterDisplay(filterValue?: Set<string | number>): string {
    return filterValue ? Array.from(filterValue).join(", ") : "";
}
