import { ForwardedRef, MutableRefObject, RefCallback } from "react";

/**
 * A simple hook which allows you to combine multiple refs into a single ref callback. Useful if
 * you have an internal ref and a forwarded ref in the same component.
 */
export function useCombinedRef<T>(
    ...refs: (MutableRefObject<T> | ForwardedRef<T> | undefined | null)[]
): RefCallback<T> {
    return (element: T) => {
        for (const ref of refs) {
            if (ref === null || ref === undefined) {
                continue;
            }
            if (typeof ref === "function") {
                ref(element);
            } else {
                ref.current = element;
            }
        }
    };
}
