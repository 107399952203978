import clsx from "clsx";
import { Scrollbar } from "components/Scrollbar";
import {
    BasePopover,
    POPOVER_DEFAULT_ARROW_HEIGHT,
    POPOVER_DEFAULT_ARROW_MARGIN,
    POPOVER_DEFAULT_ARROW_WIDTH,
    PopoverNesting,
    PopoverPlacement as PopoverMenuPlacement,
} from "components/util/BasePopover";
import * as CSS from "csstype";
import React, {
    createContext,
    CSSProperties,
    FC,
    ReactNode,
    RefCallback,
    RefObject,
    useLayoutEffect,
    useState,
} from "react";
import { EverIdProp } from "util/type";

export interface MenuType {
    isDropdown: boolean;
    isFilterable?: boolean;
}

export const MenuContext = createContext<MenuType>({
    isDropdown: false,
    isFilterable: false,
});

export interface BasePopoverMenuProps extends EverIdProp {
    /**
     * The label for the popover menu. Only applicable to listboxes.
     */
    "aria-labelledby"?: string;
    /**
     * Whether multiple elements can be selected for this popover menu. Only applicable for listbox
     * menus.
     */
    "aria-multiselectable"?: boolean;
    /**
     * Whether the popover menu is read only. Only applicable to listboxes.
     */
    "aria-readonly"?: boolean;
    /**
     * Whether an option is required to be selected from the menu. Only applicable to listboxes.
     */
    "aria-required"?: boolean;
    /**
     * Whether to render an arrow between the popover and the trigger element. Default true.
     */
    arrow?: boolean;
    /**
     * Height of the arrow in px. This is the distance between the popover and the target element.
     * Defaults to POPOVER_DEFAULT_ARROW_HEIGHT.
     */
    arrowHeight?: number;
    /**
     * The margin of the arrow in px. This is the distance between the arrow and the side of
     * the popover when the popover has an alignment and the arrow is not centered.
     * Defaults to POPOVER_DEFAULT_ARROW_MARGIN.
     */
    arrowMargin?: number;
    /**
     * Width of the arrow in px. This is the length of the edge where the arrow touches the popover.
     * Defaults to POPOVER_DEFAULT_ARROW_WIDTH.
     */
    arrowWidth?: number;
    /**
     * Contents of the menu.
     */
    children?: ReactNode;
    /**
     * Class for the menu.
     */
    className?: string;
    /**
     * The maximum width for the popover menu. Generally should be left unset.
     */
    maxWidth?: CSS.Property.MaxWidth;
    /**
     * The id property for the main element of the PopoverMenu.
     */
    menuId?: string;
    /**
     * The minimum width for the popover menu. Generally should be left unset.
     */
    minWidth?: CSS.Property.MinWidth;
    /**
     * Whether the popover should behave as a modal dialog for keyboard navigation. If true, focus
     * will be trapped within the popover. Defaults to false.
     */
    modal?: boolean;
    /**
     * See {@link BasePopoverProps.nesting}.
     */
    nesting?: PopoverNesting;
    /**
     * See {@link BasePopoverProps.renderOutsideParent}.
     */
    renderOutsideParent?: boolean;
    /**
     * Placement(s) of the menu relative to trigger. If an array of placements is given and
     * there is not enough space on screen for the first placement, one of the placements listed
     * after the first will be chosen based on whichever one has the least amount of overflow. This
     * means that placement ordering from index 1 and greater will not necessarily be respected.
     *
     * Default `PopoverPlacement.BOTTOM`.
     */
    placement?: PopoverMenuPlacement | PopoverMenuPlacement[];
    /**
     * The role for the main element of the PopoverMenu.
     */
    role: "menu" | "listbox";
    /**
     * A ref to assign to the root element.
     */
    rootRef?: RefObject<HTMLDivElement> | RefCallback<HTMLDivElement>;
    /**
     * If true, show the popover.
     */
    show: boolean;
    /**
     * An optional element to place at the bottom of the menu, which stays displayed regardless of
     * scrolling.
     */
    stickyFooter?: ReactNode;
    /**
     * An optional element to place at the top of the menu, which stays displayed regardless
     * of scrolling.
     */
    stickyHeader?: ReactNode;
    /**
     * Style for the popover component. It may be better to apply custom styles through CSS or
     * through contentStyle/arrowStyle than through this prop.
     */
    style?: CSSProperties;
    /**
     * Ref of the element that serves as the trigger for the popover. This is used to position the
     * popover.
     */
    trigger: RefObject<Element>;
}

export const BasePopoverMenu: FC<BasePopoverMenuProps> = ({
    className,
    everId,
    children,
    trigger,
    placement = PopoverMenuPlacement.BOTTOM,
    arrow = true,
    arrowWidth = POPOVER_DEFAULT_ARROW_WIDTH,
    arrowHeight = POPOVER_DEFAULT_ARROW_HEIGHT,
    arrowMargin = POPOVER_DEFAULT_ARROW_MARGIN,
    role,
    rootRef,
    show = false,
    stickyFooter,
    stickyHeader,
    menuId,
    modal = false,
    renderOutsideParent = false,
    style,
    "aria-labelledby": arialLabelledby,
    "aria-multiselectable": ariaMultiselectable,
    "aria-readonly": ariaReadOnly,
    "aria-required": ariaRequired,
    maxWidth,
    minWidth,
    nesting,
}) => {
    const [stickyHeaderEl, setStickyHeaderEl] = useState<HTMLDivElement | null>(null);
    const [stickyFooterEl, setStickyFooterEl] = useState<HTMLDivElement | null>(null);
    const [autoHeightMax, setAutoHeightMax] = useState<CSS.Property.MaxHeight>("400px");
    useLayoutEffect(() => {
        if (!show || (!stickyHeaderEl && !stickyFooterEl)) {
            return;
        }
        const headerHeight: number = stickyHeaderEl?.clientHeight || 0;
        const footerHeight: number = stickyFooterEl?.clientHeight || 0;
        setAutoHeightMax(400 - headerHeight - footerHeight + "px");
    }, [show, stickyFooterEl, stickyHeaderEl]);

    return (
        <BasePopover
            ref={rootRef}
            target={trigger}
            show={show}
            placement={placement}
            arrow={arrow}
            arrowWidth={arrowWidth}
            arrowHeight={arrowHeight}
            arrowMargin={arrowMargin}
            className={clsx("bb-popover-menu", className)}
            everId={everId}
            modal={modal}
            renderOutsideParent={renderOutsideParent}
            nesting={nesting}
            style={
                {
                    "--bb-popoverMenu-maxWidth": maxWidth,
                    "--bb-popoverMenu-minWidth": minWidth,
                    ...style,
                } as CSSProperties
            }
        >
            {stickyHeader && (
                <div className={"bb-popover-menu__sticky-header"} ref={setStickyHeaderEl}>
                    {stickyHeader}
                </div>
            )}
            <Scrollbar
                autoHeight={true}
                autoHeightMax={autoHeightMax}
                hideTracksWhenNotNeeded={true}
                thumbSize={158}
            >
                <div
                    aria-labelledby={arialLabelledby}
                    aria-multiselectable={ariaMultiselectable}
                    aria-readonly={ariaReadOnly}
                    aria-required={ariaRequired}
                    role={role}
                    id={menuId}
                >
                    {children}
                </div>
            </Scrollbar>
            {stickyFooter && (
                <div className={"bb-popover-menu__sticky-footer"} ref={setStickyFooterEl}>
                    {stickyFooter}
                </div>
            )}
        </BasePopover>
    );
};
