import { DependencyList, useCallback, useMemo } from "react";

declare const __memoBrand: unique symbol;
/**
 * This type exists to allow hooks and components to enforce that props or parameters be memoized.
 * While nothing about this type technically checks whether an element is memoized, it adds
 * compile-time checks that variables provided to this type are labeled as memoized, forcing
 * users of these hooks and components to memoize their variables (or, at least, cast them to a
 * memoized type).
 *
 * This type is returned from {@link useBrandedCallback} and {@link useBrandedMemo}, which are
 * simple wrappers on their React counterparts that just type the returned values of those hooks
 * as memoized.
 *
 * This is useful if your hook or component does some heavy calculations, or other things that
 * benefit from memoization, but they rely on variables provided from outside of your hook or
 * component; such variables can be typed as Memoized to require users to provide memoized
 * instances of those variables.
 *
 * This should usually not be used for simple primitives like numbers or strings, for the same
 * reasons memoization should usually not be used for such types.
 */
export type Memo<T> = T & { [__memoBrand]: true };

/**
 * Just like {@link useMemo}, but types the returned value as a {@link Memo} so that it
 * can be used in hooks and components that require memoized parameters and props.
 *
 * @param factory see {@link useMemo}'s parameters
 * @param dependencies see {@link useMemo}'s parameters
 */
export function useBrandedMemo<T>(factory: () => T, dependencies: DependencyList): Memo<T> {
    // Disabling exhaustive deps because it will have already been applied on the call to this hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(factory, dependencies) as Memo<T>;
}

/**
 * Just like {@link useCallback}, but types the returned value as a {@link Memo} so that it
 * can be used in hooks and components that require memoized parameters and props.
 *
 * @param callback see {@link useCallback}'s parameters
 * @param dependencies see {@link useCallback}'s parameters
 */
export function useBrandedCallback<T extends (...params: Parameters<T>) => ReturnType<T>>(
    callback: T,
    dependencies: DependencyList,
): Memo<T> {
    // Disabling exhaustive deps because that will already be handled in the call to this
    // function
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(callback, dependencies) as Memo<T>;
}
