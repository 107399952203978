import { useState, useEffect } from "react";

export interface WindowSize {
    width?: number;
    height?: number;
}

// Hook from https://usehooks.com/useWindowSize/
export function useWindowSize(resize?: (size: WindowSize) => void): WindowSize {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        // Handler to call on window resize
        const handleResize = () => {
            // Set window width/height to state
            const size = {
                width: window.innerWidth,
                height: window.innerHeight,
            };
            setWindowSize(size);
            resize && resize(size);
        };

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, [resize]); // Empty array ensures that effect is only run on mount

    return windowSize;
}
