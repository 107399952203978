/**
 * Constants - Various numeric, string, and other primitive constants used throughout our codebase.
 *  Convention: This module should be imported under the binding 'C'.
 */

// The following are expressed in terms of milliseconds.
export const SEC = 1000;
export const MIN = 60 * SEC;
export const HR = 60 * MIN;
export const DAY = 24 * HR;
export const WK = 7 * DAY;
export const MO = 30 * DAY;
export const YR = 365 * DAY;

// The following are expressed in terms of seconds (hence the trailing S).
export const MINS = 60;
export const HRS = 60 * MINS;
export const DAYS = 24 * HRS;
export const WKS = 7 * DAYS;
export const MOS = 30 * DAYS;
export const YRS = 365 * DAYS;

// The following are expressed in terms of bytes, using 10^3 units.
export const KB = 1000;
export const MB = 1000 * KB;
export const GB = 1000 * MB;
export const TB = 1000 * GB;

// The following are expressed in terms of bytes, using 2^10 units.
export const KiB = 1024;
export const MiB = 1024 * KiB;
export const GiB = 1024 * MiB;
export const TiB = 1024 * GiB;

// These represent the point at which integer precision is lost due to the floating point
// representation.
export const INTMAX = Math.pow(2, 53);
export const INTMIN = -INTMAX;
