import CSS from "csstype";

/**
 * When {@link size} is of the form `npx` or `n` where n is a number, returns n.
 * Otherwise, returns the given {@link defaultSize}.
 */
export function getSizePx(size: string, defaultSize = 0): number {
    const splitSize = size.split("px");
    if (splitSize.length > 2 || splitSize[1]) {
        // size is not in px units.
        return defaultSize;
    }
    const numberSize = parseFloat(splitSize[0]);
    if (numberSize.toString() !== splitSize[0].trim()) {
        // We have some string of the form npx or n, where n is not a number.
        return defaultSize;
    }
    // size is a string of the form npx, where n is a number. Return n.
    return numberSize;
}

type CSSSize =
    | CSS.Property.Width
    | CSS.Property.MinWidth
    | CSS.Property.MaxWidth
    | CSS.Property.Height
    | CSS.Property.MinHeight
    | CSS.Property.MaxHeight;

export function calc<T extends CSSSize>(...sizes: T[]): T {
    return `calc(${sizes.join(" + ")})` as T;
}

export function min<T extends CSSSize>(...sizes: T[]): T {
    return `min(${sizes.join(", ")})` as T;
}

export function max<T extends CSSSize>(...sizes: T[]): T {
    return `max(${sizes.join(", ")})` as T;
}

export function clamp<T extends CSSSize>(size: T, minSize: T, maxSize: T): T {
    return min(max(size, minSize), maxSize);
}

/**
 * Returns a single selector that combines all the given selectors and their children.
 */
export function combineSelectors(selectors: string[]): string {
    return selectors.reduce((acc, selector) => {
        // Also include all children of selector
        selector = `${selector}, ${selector} *`;
        return acc ? `${acc}, ${selector}` : selector;
    }, "");
}
