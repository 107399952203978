const HTTP_PROTOCOL = "http:";
const HTTPS_PROTOCOL = "https:";
/**
 * Returns true if the given URL is external to the current location.
 *
 * All links with different protocols, hosts, subdomains, or ports are considered external.
 *
 * @param href The URL to check.
 */
export function isExternalUrl(href: string): boolean {
    // Construct a URL object from the given href, using the current location as the base.
    const url = new URL(href, location.href);
    if (
        (url.protocol === HTTP_PROTOCOL || url.protocol === HTTPS_PROTOCOL)
        && (location.protocol === HTTP_PROTOCOL || location.protocol === HTTPS_PROTOCOL)
    ) {
        // For http and https links, make an exception for http/https links within the same host.
        // Ideally, we should never link to http from https, but in the case that we do, we should
        // treat it as an internal link when the host is the same.
        return url.host !== location.host;
    }
    return url.protocol !== location.protocol || url.origin !== location.origin;
}
