import clsx from "clsx";
import { IconButtonProps } from "components/Button";
import * as Icon from "components/Icon";
import { IconProps } from "components/Icon/IconProps";
import { Tooltip, TooltipProps } from "components/Tooltip";
import { UserBadgeProps, UserBadgeSize } from "components/UserBadge";
import CSS from "csstype";
import { Memo, useBrandedCallback } from "hooks/useBranded";
import { useButtonRole } from "hooks/useButtonRole";
import { useCombinedRef } from "hooks/useCombinedRef";
import { useResizeObserver } from "hooks/useResizeObserver";
import React, {
    cloneElement,
    CSSProperties,
    forwardRef,
    ReactElement,
    ReactNode,
    UIEventHandler,
    useId,
    useRef,
} from "react";
import { EverColor } from "tokens/typescript/EverColor";
import { wrap } from "util/array";
import { FFC } from "util/type";
import "./Chip.scss";

export enum ChipMode {
    ADD = "add",
    REMOVE = "remove",
}

interface SharedChipProps {
    /**
     * An optional class name to apply to the chip.
     */
    className?: string;
    /**
     * The label text of the chip.
     */
    children: ReactNode;
    /**
     * Whether the chip should be disabled. The chip will still be tab-focusable even if it is
     * disabled, but any click actions will not work. Default false.
     */
    disabled?: boolean;
    /**
     * The aria-description to apply to the chip. Use this prop to provide additional
     * context about the click action.
     *
     * Note: Chips with an onClick action must provide an aria-description describing the action.
     */
    "aria-description"?: string;
    /**
     * The URL of the chip. Clicking the chip will direct the user to the given URL.
     * If provided, {@link onClick} will be ignored.
     */
    href?: string;
    /**
     * The function to call when the chip is clicked. When {@link href} is provided, this prop
     * is ignored and the given href is opened when the chip is clicked.
     *
     * If provided, you must also provide an aria-description describing the click action.
     */
    onClick?: Memo<UIEventHandler>;
    /**
     * An icon button to display to the right of the label.
     */
    rightButton?: ReactElement<IconButtonProps>;
    /**
     * The width property of the chip. Default "fit-content".
     */
    width?: CSS.Property.Width;
    /**
     * The {@link ChipMode} to display the chip in.
     */
    mode?: ChipMode;
    /**
     * A tooltip to render on the chip. If provided, the ellipis tooltip that is usually
     * rendered when the label is ellipsed will not appear.
     */
    tooltip?: ReactElement<TooltipProps>;
}

interface BaseChipProps extends SharedChipProps {
    styleProps?: CSSProperties;
    leftContent?: ReactNode;
    rightContent?: ReactNode;
}

const BaseChip: FFC<HTMLDivElement, BaseChipProps> = forwardRef(
    (
        {
            className,
            children,
            disabled = false,
            href,
            onClick,
            rightButton,
            width = "fit-content",
            mode,
            tooltip,
            "aria-description": ariaDescription,
            leftContent,
            rightContent,
            styleProps,
        }: BaseChipProps,
        ref,
    ) => {
        const chipRef = useRef<HTMLDivElement>(null);
        const [resizeRef, contentEntry] = useResizeObserver<HTMLDivElement>();
        const showEllipsisTooltip =
            contentEntry.target
            && contentEntry.target.scrollWidth > contentEntry.target.clientWidth;
        const combinedRef = useCombinedRef<HTMLDivElement>(ref, chipRef);
        const tooltipId = useId();
        tooltip &&= cloneElement(tooltip, {
            id: tooltipId,
            target: chipRef,
        });

        const onClickLink = useBrandedCallback(
            () => window.open(href, "_blank", "noopener noreferrer"),
            [href],
        );
        const chipOnClick = href ? onClickLink : onClick;
        const { buttonProps } = useButtonRole(chipRef, {
            onClick: chipOnClick,
            "aria-disabled": disabled,
            enabled: !!onClick || !!href,
            excludedSelectors: [".bb-chip__right-button"],
        });

        rightButton &&= cloneElement(rightButton, {
            className: clsx(rightButton.props.className, "bb-chip__right-button"),
            disabled: disabled || rightButton.props.disabled,
            children: cloneElement(rightButton.props.children, { size: 16 }),
        });
        return (
            <>
                <span
                    {...buttonProps}
                    ref={combinedRef}
                    className={clsx("bb-chip", className, {
                        "bb-chip--add": mode === ChipMode.ADD,
                        "bb-chip--remove": mode === ChipMode.REMOVE,
                        "bb-chip--disabled": disabled,
                        "bb-chip--link": !disabled && href,
                        "bb-chip--clickable": !disabled && !href && onClick,
                    })}
                    role={href ? "link" : onClick ? "button" : undefined}
                    aria-describedby={tooltip ? tooltipId : undefined}
                    aria-description={ariaDescription}
                    data-href={href}
                    tabIndex={
                        buttonProps?.tabIndex === 0 || tooltip || showEllipsisTooltip
                            ? 0
                            : undefined
                    }
                    style={{ ...styleProps, "--bb-chip-width": width } as CSSProperties}
                >
                    {leftContent}
                    <span ref={resizeRef} className={"bb-chip__label"}>
                        {children}
                    </span>
                    {rightContent}
                    {rightButton}
                </span>
                {tooltip}
                {!tooltip && showEllipsisTooltip && (
                    <Tooltip target={chipRef} aria-hidden={true}>
                        {children}
                    </Tooltip>
                )}
            </>
        );
    },
);

export interface BasicChipProps extends SharedChipProps {
    /**
     * If provided, displays a color dot in the given color on the left-hand side of the chip,
     * before the icon (if present) and label. If {@link matchBorderColor} is true, then the
     * chip's border will also be displayed in this color.
     */
    color?: EverColor | string;
    /**
     * Whether the chip's border color should match {@link color}. Only applicable when
     * {@link color} is provided. Default false.
     */
    matchBorderColor?: boolean;
    /**
     * An icon to display to the left of the label.
     */
    icon?: ReactElement<IconProps>;
}

export const BasicChip: FFC<HTMLDivElement, BasicChipProps> = forwardRef(
    (
        {
            className,
            children,
            color,
            matchBorderColor = false,
            icon,
            ...baseChipProps
        }: BasicChipProps,
        ref,
    ) => {
        icon &&= cloneElement(icon, {
            className: clsx(icon.props.className, "bb-basic-chip__icon"),
            size: 16,
        });
        return (
            <BaseChip
                {...baseChipProps}
                ref={ref}
                className={clsx("bb-basic-chip", className)}
                leftContent={
                    <>
                        {color && <div className={"bb-basic-chip__color-dot"} />}
                        {icon}
                    </>
                }
                styleProps={
                    {
                        "--bb-basic-chip-dot-color": color,
                        "--bb-basic-chip-border-color": matchBorderColor ? color : undefined,
                    } as CSSProperties
                }
            >
                {children}
            </BaseChip>
        );
    },
);

export interface EntityChipProps extends SharedChipProps {
    /**
     * The user badge to display on the left-hand side of the entity chip.
     */
    userBadge: ReactElement<UserBadgeProps>;
}

export const EntityChip: FFC<HTMLDivElement, EntityChipProps> = forwardRef(
    ({ className, children, userBadge, ...baseChipProps }: EntityChipProps, ref) => {
        userBadge = cloneElement(userBadge, {
            className: clsx(userBadge.props.className, "bb-entity-chip__badge"),
            size: UserBadgeSize.SMALL,
            onClick: undefined,
            tooltip: undefined,
        });
        return (
            <BaseChip
                {...baseChipProps}
                ref={ref}
                className={clsx("bb-entity-chip", className)}
                leftContent={userBadge}
            >
                {children}
            </BaseChip>
        );
    },
);

export interface ReviewChipProps extends SharedChipProps {
    /**
     * The color of the band on the left-hand side of the chip.
     */
    color: EverColor | string;
    /**
     * One or more icons to display to the left of the label.
     */
    icons?: ReactElement<IconProps> | ReactElement<IconProps>[];
    /**
     * If provided, renders a lock icon to the right of the label, as well as a tooltip on the
     * chip with the given reason. The `onClick`, `href`, and `tooltip` props will be ignored.
     */
    lockedReason?: string;
}

export const ReviewChip: FFC<HTMLDivElement, ReviewChipProps> = forwardRef(
    (
        {
            className,
            children,
            color,
            icons,
            onClick,
            href,
            tooltip,
            lockedReason,
            ...baseChipProps
        }: ReviewChipProps,
        ref,
    ) => {
        icons &&= wrap(icons);
        const leftContent = (
            <>
                <div className={"bb-review-chip__color-band"} />
                {icons?.map((icon, index) =>
                    cloneElement(icon, {
                        key: index,
                        className: clsx(icon.props.className, "bb-review-chip__left-icon"),
                        size: 16,
                    }),
                )}
            </>
        );
        return (
            <BaseChip
                {...baseChipProps}
                ref={ref}
                className={clsx("bb-review-chip", className)}
                leftContent={leftContent}
                rightContent={
                    lockedReason ? (
                        <Icon.LockFilled
                            className={"bb-review-chip__lock-icon"}
                            aria-label={"Locked"}
                            size={16}
                        />
                    ) : undefined
                }
                tooltip={lockedReason ? <Tooltip>{lockedReason}</Tooltip> : tooltip}
                onClick={!lockedReason ? onClick : undefined}
                href={!lockedReason ? href : undefined}
                styleProps={{ "--bb-review-chip-band-color": color } as CSSProperties}
            >
                {children}
            </BaseChip>
        );
    },
);
