//Taken from https://github.com/zhaluza/react-detect-click-outside/

import { useBrandedCallback } from "hooks/useBranded";
import { useEventListener } from "hooks/useEventListener";
import { MultiValueRefObject, refObjectValues } from "hooks/useMultiValueRef";
import { RefObject } from "react";

export interface DetectClickConfig {
    disableClick?: boolean;
    useMouseDown?: boolean;
    disableKeys?: boolean;
    allowAnyKey?: boolean;
    triggerKeys?: string[];
}

/**
 * Hook used to detect clicks outside a component (or an escape key press).
 * onTriggered function is triggered on `click` or escape `keyup` event.
 */
export function useDetectClickOutside<E extends Element = Element>(
    // Disabling no explicit any for the key type, since we only care about the values
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    elementRef: RefObject<E> | MultiValueRefObject<any, E>,
    onTriggered: EventListener,
    {
        disableClick,
        useMouseDown,
        disableKeys,
        allowAnyKey,
        triggerKeys = ["Escape"],
    }: DetectClickConfig = {},
): void {
    const keyListener = useBrandedCallback(
        (e: Event) => {
            if (disableKeys || !(e instanceof KeyboardEvent)) {
                return;
            }
            if (allowAnyKey || triggerKeys.includes(e.key)) {
                onTriggered(e);
            }
        },
        [onTriggered, allowAnyKey, triggerKeys, disableKeys],
    );
    useEventListener(document, "keyup", keyListener);

    const clickListener = useBrandedCallback(
        (e: Event) => {
            if (disableClick || !(e instanceof MouseEvent) || !(e.target instanceof Node)) {
                return;
            }
            if (!refObjectValues(elementRef).some((c) => c?.contains(e.target as Node))) {
                onTriggered?.(e);
            }
        },
        [elementRef, disableClick, onTriggered],
    );
    useEventListener(document, useMouseDown ? "mousedown" : "click", clickListener);
}
