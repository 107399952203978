import clsx from "clsx";
import { BaseButtonProps, BaseSplitButtonProps } from "components/Button/BaseButton";
import { ButtonSize } from "components/Button/Button/Button";
import { CounterColor, CounterProps } from "components/Counter";
import * as Icon from "components/Icon";
import { IconProps } from "components/Icon/IconProps";
import { everHashProp } from "EverAttribute/EverHash";
import { everIdProp } from "EverAttribute/EverId";
import React, { FC, forwardRef, ReactElement, ReactNode } from "react";
import * as ColorTokens from "tokens/typescript/ColorTokens";
import "../Button.scss";

export enum TextButtonFontSize {
    SMALL = "small",
    MEDIUM = "medium",
}

export interface TextButtonProps extends BaseButtonProps<ReactNode> {
    /**
     * The font size of the text within the button.
     * Defaults to {@link TextButtonFontSize.Medium}.
     */
    fontSize?: TextButtonFontSize;
    /**
     * An optional icon to add to the left hand side of the button.
     *
     * Its color will be overridden to everblue40, and its size overridden to 20.
     */
    icon?: ReactElement<IconProps>;
    /**
     * The size of the button. Any of (small, large). Determines the height and, along with the
     * width parameter, width of the button.
     *
     * Defaults to large.
     */
    size?: ButtonSize;
    /**
     * An optional counter that, if provided, is displayed on the right hand side of the button.
     * If the button is sitting on a non-white background, the
     * {@link CounterProps.hasWhiteBackground} prop must be set to false.
     */
    counter?: ReactElement<CounterProps>;
}

export type SplitTextButtonProps = BaseSplitButtonProps<TextButtonProps>;

/**
 * A button component made to be placed inline with text which, without hovering, looks like a
 * simple link.
 */
export const TextButton = forwardRef<HTMLButtonElement, TextButtonProps>(
    (
        {
            children,
            className,
            fontSize = TextButtonFontSize.MEDIUM,
            onClick,
            onMouseDown,
            disabled,
            active,
            icon,
            size = ButtonSize.LARGE,
            counter,
            tabFocusable = true,
            style,
            everId,
            ...props
        },
        ref,
    ) => {
        icon =
            icon
            && React.cloneElement(icon, {
                className: clsx(icon.props.className, "bb-text-button__icon"),
                size: 20,
                color: icon.props.color || ColorTokens.TEXT_BUTTON_PRIMARY,
                "aria-hidden": true,
            });
        className = clsx(
            "bb-text-button",
            `bb-text-button--${size}`,
            `bb-text-button--${fontSize}-font`,
            className,
            {
                "bb-text-button--active": active,
            },
        );
        counter &&= React.cloneElement(counter, {
            className: clsx(counter.props.className, "bb-text-button__counter"),
            color: CounterColor.DEFAULT,
        });
        return (
            <button
                ref={ref}
                className={className}
                onClick={(e) => !disabled && onClick?.(e)}
                aria-disabled={disabled}
                type={"button"}
                tabIndex={!tabFocusable ? -1 : undefined}
                style={style}
                {...everIdProp(everId)}
                {...(typeof children === "string" ? everHashProp(children) : {})}
                {...props}
            >
                {icon}
                {children && <span className={"bb-text-button__label"}>{children}</span>}
                {counter}
            </button>
        );
    },
);
TextButton.displayName = "TextButton";

/**
 * A text button split up into two parts, a main button and a dropdown button. The main button
 * performs some primary action, while the dropdown button allows access to some secondary actions.
 */
export const SplitTextButton: FC<SplitTextButtonProps> = ({
    "sideButton-aria-label": sideButtonLabel = "Expand",
    "sideButton-aria-describedby": sideButtonDescribedby,
    "sideButton-aria-expanded": sideButtonExpanded,
    "sideButton-aria-controls": sideButtonControls,
    "mainButton-aria-label": mainButtonLabel,
    "mainButton-aria-describedby": mainButtonDescribedby,
    "mainButton-aria-expanded": mainButtonExpanded,
    "mainButton-aria-controls": mainButtonControls,
    size = ButtonSize.LARGE,
    mainButtonEverId,
    sideButtonEverId,
    sideButtonIcon = <Icon.CaretDown />,
    tabFocusable = true,
    ...props
}) => {
    const className = clsx("bb-split-text-button", props.className, {
        "bb-split-text-button--active": props.mainButtonActive || props.sideButtonActive,
    });
    const sideButtonClassName = clsx("bb-split-text-button__side-button", "bb-icon-button", {
        "bb-icon-button--active": props.sideButtonActive,
    });
    const shouldDisableSideButton = props.disabled || props.sideButtonDisabled;
    return (
        <div className={className}>
            <TextButton
                everId={mainButtonEverId}
                ref={props.mainButtonRef}
                icon={props.icon}
                className={"bb-split-text-button__main-button"}
                id={props.mainButtonId}
                onClick={props.onMainButtonClick}
                onMouseDown={props.onMainButtonMouseDown}
                disabled={props.disabled || props.mainButtonDisabled}
                active={props.mainButtonActive}
                aria-label={mainButtonLabel}
                aria-describedby={mainButtonDescribedby}
                aria-expanded={mainButtonExpanded}
                aria-controls={mainButtonControls}
                tabFocusable={tabFocusable}
                size={size}
                autoFocus={props.mainButtonAutoFocus}
            >
                {props.children}
            </TextButton>
            <button
                ref={props.sideButtonRef}
                className={sideButtonClassName}
                id={props.sideButtonId}
                onClick={(e) => !shouldDisableSideButton && props.onSideButtonClick?.(e)}
                onMouseDown={(e) => !shouldDisableSideButton && props.onSideButtonMouseDown?.(e)}
                aria-disabled={shouldDisableSideButton}
                aria-label={sideButtonLabel}
                aria-describedby={sideButtonDescribedby}
                aria-expanded={sideButtonExpanded}
                aria-controls={sideButtonControls}
                tabIndex={!tabFocusable ? -1 : undefined}
                type={"button"}
                {...everIdProp(sideButtonEverId)}
                autoFocus={props.sideButtonAutoFocus}
            >
                {React.cloneElement(sideButtonIcon, {
                    color: ColorTokens.TEXT_BUTTON_PRIMARY,
                    size: 16,
                    "aria-hidden": true,
                })}
            </button>
        </div>
    );
};
