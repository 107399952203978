import React, { FC } from "react";
import { SelectorGroup, SelectorGroupProps } from "components/SelectorGroup";

export type RadioGroupProps = Omit<SelectorGroupProps, "hidden" | "nested">;

/**
 * A grouping of radios
 */
export const RadioGroup: FC<RadioGroupProps> = (props) => {
    return <SelectorGroup {...props} />;
};
