import { RefObject, useEffect } from "react";
import { EverId, setEverId } from "EverAttribute/EverId";

/**
 * Sets {@link EverId}s for elements within React components. Useful for situations where a
 * component or JSX element cannot have an {@link EverId} set directly through props.
 * @param elementsAndIds Tuples of the format [element to assign EverId, EverId to assign].
 */
export function useSetEverId(
    ...elementsAndIds: [RefObject<HTMLElement> | HTMLElement | null, EverId | undefined][]
): void {
    // Extract elements from any present RefObjects.
    const extractedElsAndIds: [HTMLElement | null, EverId | undefined][] = elementsAndIds.map(
        ([rawElement, id]) => {
            let element: HTMLElement | null;
            if (!rawElement) {
                element = null;
            } else if (rawElement instanceof HTMLElement) {
                element = rawElement;
            } else {
                element = rawElement.current;
            }
            return [element, id];
        },
    );
    // Use a string representation of elementsAndIds as the dependency of useEffect so that the hook
    // triggers appropriately after components mount and RefObjects are set.
    const depString = extractedElsAndIds.map(([el, id]) => `${el?.nodeName},${id}`).join("|");
    useEffect(() => {
        extractedElsAndIds.forEach(([element, everId]) => {
            if (element && everId) {
                setEverId(element, everId);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [depString]);
}
