import { MenuCheckboxProps, Checkbox as MenuCheckbox } from "components/Menu/Item";
import React, { forwardRef } from "react";
import { FFC } from "util/type";

export type PopoverMenuCheckboxProps = Omit<MenuCheckboxProps, "role">;

/**
 * A PopoverMenu version of a Checkbox. Handles the necessary styling for placing a Checkbox within
 * a PopoverMenu as a menu item.
 */
export const Checkbox: FFC<HTMLInputElement, PopoverMenuCheckboxProps> = forwardRef(
    ({ ...props }, ref) => {
        return <MenuCheckbox {...props} role={"menuitemcheckbox"} />;
    },
);
Checkbox.displayName = "Checkbox";
