import { getYear, format, isValid, parse } from "date-fns";

/**
 * An enum of acceptable date, month, and year orderings.
 */
export enum DateFormat {
    MDY = "MDY",
    DMY = "DMY",
    YMD = "YMD",
}

/**
 * For each {@link DateFormat}, the corresponding string can be used for things like
 * text field placeholders to represent to users the date format we expect them to use.
 */
export const DATE_FORMAT_PLACEHOLDER = {
    [DateFormat.MDY]: "mm/dd/yyyy",
    [DateFormat.DMY]: "dd/mm/yyyy",
    [DateFormat.YMD]: "yyyy/mm/dd",
};

/**
 * The date-fns format string for each {@link DateFormat}. Used for formatting a Date object
 * as a string.
 */
const DISPLAY_FORMATS = {
    [DateFormat.MDY]: "MM/dd/yyyy",
    [DateFormat.DMY]: "dd/MM/yyyy",
    [DateFormat.YMD]: "yyyy/MM/dd",
};

/**
 * Returns a simple formatted date string representing the given Date object in the given
 * {@link DateFormat}, using the local browser timezone. The formatted string does not
 * include time.
 *
 * Note: If the date needs to be interpreted in a timezone other than the local browser
 * timezone, then use the {@code formatDateWithTimezone} wrapper function instead.
 */
export function formatDate(date: Date, dateFormat: DateFormat): string {
    return format(date, DISPLAY_FORMATS[dateFormat]);
}

/**
 * The date-fns parsing formats that we accept for each {@link DateFormat}. Slash and dash
 * separators are accepted, as well as one and two-digit dates and months. Years must have
 * four digits.
 */
const PARSING_FORMATS = {
    [DateFormat.MDY]: ["M/d/yyyy", "M-d-yyyy"],
    [DateFormat.DMY]: ["d/M/yyyy", "d-M-yyyy"],
    [DateFormat.YMD]: ["yyyy/M/d", "yyyy-M-d"],
};

/**
 * Given a string representing a date and its corresponding {@link DateFormat}, returns a
 * Date object, or null if the date string is invalid with regard to the given {@link DateFormat}.
 *
 * Note: {@link DateFormat} only specifies the expected order of the date, month, and year.
 * Regardless of the given {@link DateFormat}, slash and dash separators are accepted,
 * as well as one and two-digit dates and months. Years must have four digits.
 */
export function parseUsingFormat(dateStr: string, dateFormat: DateFormat): Date | null {
    for (const format of PARSING_FORMATS[dateFormat]) {
        const parsedDate = parse(dateStr, format, new Date());
        if (isValid(parsedDate)) {
            // date-fns doesn't use strict parsing, so if the year < 1000, it's possible that
            // less than 4 digits were entered for the year. Realistically, we shouldn't need
            // to worry about dates from that long ago.
            //
            // See this discussion for more info: https://github.com/date-fns/date-fns/issues/942
            return getYear(parsedDate) < 1000 ? null : parsedDate;
        }
    }
    return null;
}
