import React, {
    Dispatch,
    FC,
    ReactNode,
    SetStateAction,
    useCallback,
    useMemo,
    useState,
} from "react";
import { multiLineEllipsify } from "util/string";
import { EverIdProp } from "util/type";
import { H1, HeadingMargin } from "../Text";
import "./Dialog.scss";

export enum DialogSize {
    SMALL = 456,
    SM = DialogSize.SMALL,
    MEDIUM = 576,
    MD = DialogSize.MEDIUM,
    LARGE = 720,
    LG = DialogSize.LARGE,
    EXTRA_LARGE = 864,
    XL = DialogSize.EXTRA_LARGE,
    FULLSCREEN = -1,
}

export interface DialogHeaderProps {
    /**
     * The text to display in the header of the dialog.
     */
    title: React.ReactNode;
    /**
     * The width of the dialog header.
     */
    width: number;
}

export interface BaseDialogProps extends Omit<DialogHeaderProps, "width">, EverIdProp {
    /**
     * The inner content of the dialog.
     */
    children: ReactNode;
    /**
     * An optional class name to append to the classes already applied to the dialog.
     */
    className?: string;
    /**
     * Used as part of the key for storing certain state in localStorage. If not provided, defaults
     * to a generated unique id.
     */
    id?: string;
    /**
     * An optional callback that is called when the dialog is hidden (i.e. closed via the top-right
     * close button or the darkened background).
     */
    onHide?: () => void;
    /**
     * An optional callback that is called when the dialog is shown.
     */
    onShow?: () => void;
    /**
     * Size of the dialog header.
     */
    size: DialogSize | number;
    /**
     * If true, the dialog is shown. Note that the value of this property must be manually updated
     * by the calling component in the onHide, onCancel, and onComplete callbacks, otherwise the
     * dialog will not close.
     */
    visible: boolean;
}

export const DialogHeader: FC<DialogHeaderProps> = ({ title, width }) => {
    if (typeof title === "string" && width !== DialogSize.FULLSCREEN) {
        const perLine = (width - 48) / 11; // this was determined based on trial and error, no way to do this perfectly
        title = multiLineEllipsify(title, 2, perLine).join("\n");
    }
    return (
        <div className="bb-dialog__header">
            <H1.Small marginType={HeadingMargin.NONE}>{title}</H1.Small>
        </div>
    );
};

export function useToggleVisibilityWrapper(
    finalState: boolean,
    setVisible: Dispatch<SetStateAction<boolean>>,
    outerCallback?: () => boolean,
): () => void {
    return useCallback(() => {
        let shouldSet = true;
        if (outerCallback) {
            shouldSet &&= outerCallback();
        }
        if (shouldSet) {
            setVisible(finalState);
        }
    }, [finalState, outerCallback, setVisible]);
}

export interface UseDialogProps {
    /**
     * The function to call when the dialog is hidden (i.e. closed via the top-right close
     * button or the darkened background).
     *
     * Should return true if the dialog should be closed, and false if it should remain open.
     *
     * This function will be wrapped in another function, which toggles the visible state based
     * on the return value of the inner, provided, function.
     *
     * If no function is provided, the wrapping function will just set the visible state to false.
     */
    onHide?: () => boolean;
    /**
     * The function to call when the dialog is shown.
     *
     * Should return true if the dialog should be shown, and false if it should remain hidden.
     *
     * This function will be wrapped in another function, which toggles the visible state based on
     * the return value of the inner, provided, function.
     *
     * If no function is provided, the wrapping function will just set the visible state to true.
     */
    onShow?: () => boolean;
}

export type DialogFC<P, U> = FC<P> & { use: U };

export interface UseDialogResult {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    dialogProps: {
        onHide: () => void;
        onShow: () => void;
        visible: boolean;
    };
}

export function useDialog({
    onHide: outerOnHide,
    onShow: outerOnShow,
}: UseDialogProps = {}): UseDialogResult {
    const [visible, setVisible] = useState(false);

    const onHide = useToggleVisibilityWrapper(false, setVisible, outerOnHide);
    const onShow = useToggleVisibilityWrapper(true, setVisible, outerOnShow);

    return useMemo(
        () => ({
            visible,
            setVisible,
            dialogProps: {
                onHide,
                onShow,
                visible,
            },
        }),
        [visible, onHide, onShow],
    );
}
