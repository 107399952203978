import { Memo, useBrandedCallback } from "hooks/useBranded";
import { EventFilter } from "hooks/useFilteredEventHandler";
import { RefObject, useMemo } from "react";
import { combineSelectors } from "util/css";

/**
 * This is a list of different types of interactive elements that likely have
 * onClick/onKeyDown/etc. handlers, whose events we will likely want to ignore.
 *
 * These are the default selectors for {@link useCssSelectorFilter}
 */
export const INTERACTIVE_ELEMENT_SELECTORS = [
    "a[href]",
    'a[tabindex]:not([tabindex="-1"])',
    "area",
    "button",
    "iframe",
    "input",
    "select",
    "textarea",
    "[contenteditable=true]",
    ".bb-link",
    ".bb-button",
    ".bb-text-button",
    ".bb-icon-button",
    ".bb-user-badge",
    ".bb-selector",
    ".bb-toggle",
    ".bb-text-field",
    ".bb-text-area",
    ".bb-popover",
];

export function useCssSelectorFilter<E extends { target: unknown }>(
    element: RefObject<Element>,
    excludedSelectors: string[] = INTERACTIVE_ELEMENT_SELECTORS,
): Memo<EventFilter<E>> {
    const excludedSelector = useMemo(
        () => combineSelectors(excludedSelectors),
        // We want to be able to pass inline arrays of selectors. Without joining the array
        // this would recompute on every render.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [excludedSelectors.join()],
    );
    return useBrandedCallback(
        (event) => {
            return (
                event.target instanceof Element
                && !!element.current?.contains(event.target)
                && event.target.matches(excludedSelector)
                && event.target !== element.current
            );
        },
        [element, excludedSelector],
    );
}
