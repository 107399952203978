import { Memo } from "hooks/useBranded";
import { useEventListener } from "hooks/useEventListener";
import { EventFilter, useFilteredEventHandler } from "hooks/useFilteredEventHandler";
import { RefObject } from "react";

/**
 * In most cases, you should prefer using {@link useFilteredEventHandler} to wrap your handler,
 * then add it yourself to your element through the standard onClick, onKeyDown, onBlur, etc.
 * handlers. This hook circumvents React's event handling, while {@link useFilteredEventHandler}
 * does not, so that hook should be preferred.
 *
 * This hook does the same thing as {@link useFilteredEventHandler}, but instead of returning
 * the handler for you to pass to your element, it uses the browser's native addEventListener
 * to add the listener to your element (thus circumventing React's event system).
 *
 * @param element the element to add the event listener to
 * @param events the events to fire the event listener on
 * @param listener the listener to call when the given element has the given event fire, and the
 *      given filter returns true for the produced event,
 *      OR [
 *          the listener to call when the filter returns true,
 *          the listener to call when the filter returns false
 *      ]
 * @param filter the filter to wrap the supplied callback in. Frequently just a filter returned by
 * {@link useCssSelectorFilter}.
 */
export function useFilteredEventListener(
    element: RefObject<Element>,
    events: string | string[],
    listener:
        | Memo<EventListener>
        | [Memo<EventListener> | undefined, Memo<EventListener> | undefined]
        | undefined,
    filter: Memo<EventFilter<Event>>,
): void {
    const finalListener = useFilteredEventHandler(listener, filter);
    useEventListener(element, events, finalListener);
}
