import { OffsetOptions } from "@floating-ui/react-dom";
import clsx from "clsx";
import * as Icon from "components/Icon";
import { tooltipManager } from "components/Tooltip/TooltipManager";
import { useBrandedCallback } from "hooks/useBranded";
import { useEventListener } from "hooks/useEventListener";
import { useLatest } from "hooks/useLatest";
import React, { FC, ReactNode, RefObject, useCallback, useEffect, useRef, useState } from "react";
import {
    BasePopover,
    PopoverNesting,
    PopoverPlacement as TooltipPlacement,
} from "components/util/BasePopover/BasePopover";
import "./Tooltip.scss";
import { EverColor } from "tokens/typescript/EverColor";
import { hasFocusVisible } from "util/dom";
import { EverIdProp } from "util/type";

// More events can be added to TooltipShowEvent and TooltipHideEvent as needed.

// Events that can trigger a tooltip to appear.
export type TooltipShowEvent = "mouseenter" | "focus" | "focusin";
// Events that can trigger a tooltip to hide.
export type TooltipHideEvent = "mouseleave" | "blur" | "focusout";

export { TooltipPlacement };

export interface TooltipProps extends EverIdProp {
    /**
     * For good accessibility, tooltips should have ids. These ids should then be set as the value
     * of the `aria-describedby` attribute of the target element. The exception to this rule is if
     * the contents of the tooltip are redundant with the contents of the target; in which case,
     * `aria-hidden` should be set to true.
     */
    id?: string;
    className?: string;
    /**
     * Ref of the target element that the tooltip is to be displayed next to. If the tooltip is
     * being passed as a non-`children` prop to another component, this prop should be omitted.
     * Otherwise, this prop is required for the tooltip to appear at all.
     */
    target?: RefObject<Element>;
    /**
     * Specific events to show the tooltip. Expects array of Element event strings. Defaults to
     * `["mouseenter", "focusin"]`.
     */
    showEvents?: TooltipShowEvent[];
    /**
     * Specific events to hide the tooltip. Expects array of Element event strings. Defaults to
     * `["blur", "mouseleave"]`.
     */
    hideEvents?: TooltipHideEvent[];
    /**
     * Delay to show the tooltip in milliseconds. Once the tooltip is mounted, changes to this prop
     * will be ignored. Defaults to 400.
     */
    showDelay?: number;
    /**
     * Delay to hide the tooltip in milliseconds. Once the tooltip is mounted, changes to this prop
     * will be ignored. Defaults to 50.
     */
    hideDelay?: number;
    /**
     * Placement(s) of the tooltip relative to the target. If an array of placements is given, the
     * first placement is treated as the preferred placement. If there is not enough room for the
     * preferred placement (i.e. there is overflow), the tooltip will automatically try to place
     * itself into the next given placement in the array. It will keep trying the placements in the
     * order which they are given until it finds the first placement that causes no overflow.
     * Defaults to TooltipPlacement.BOTTOM.
     */
    placement?: TooltipPlacement | TooltipPlacement[];
    /**
     * Callback to invoke when the tooltip is shown. Once the tooltip is mounted, changes to this
     * prop will be ignored.
     */
    onShow?(): void;
    /**
     * Callback to invoke when the tooltip is hidden. Once the tooltip is mounted, changes to this
     * prop will be ignored.
     */
    onHide?(): void;
    /**
     * If included, adds a "Learn more" external link to the bottom of the tooltip. This prop, if
     * defined, is expected to be a valid URL. Generally, when this prop is provided,
     * {@link renderOutsideParent} should be set to false, and the tooltip should be the
     * immediate sibling after its target element to maintain proper tab ordering for the link.
     * However, when this prop is provided and {@link renderOutsideParent} is false, keyboard
     * interaction with this link is still possible through pressing the "L" key. In either case,
     * the link will open in a new tab when the target is focused and the "L" key is pressed.
     */
    learnMoreLink?: string;
    /**
     * If included, the "Learn more" link will use the given text instead of the default
     * "Learn more". Only applicable when {@link learnMoreLink} is included.
     */
    learnMoreText?: string;
    /**
     * Set to true if the tooltip's contents are redundant with the contents of the target.
     * Otherwise, assistive technologies may end up reading both the target and the tooltip to
     * users.
     */
    "aria-hidden"?: boolean;
    /**
     * Not available outside Bluebook design system (hidden on the main platform).
     * For debugging and testing purposes only. If set to true, the tooltip will always show itself.
     * Show and hide events will still be triggered, but they will have no effect on tooltip
     * visibility. Will cause accessibility issues if used in production. Defaults to false.
     */
    debug?: boolean;
    /**
     * The content of the tooltip.
     */
    children: ReactNode;
    /**
     * Ref of the element that should trigger the tooltip when hovered. If not provided, the
     * tooltip is triggered when the target element is hovered. Use this prop on the rare
     * occasion that the hover trigger element is not the same as the target element.
     */
    hoverTrigger?: RefObject<Element>;
    /**
     * Ref of the element that should trigger the tooltip when focused. If not provided, the
     * tooltip is triggered when the target element is focused. Use this prop on the rare
     * occasion that the focus trigger element is not the same as the target element.
     */
    focusTrigger?: RefObject<Element>;
    /**
     * This prop allows you to add space between the popover and the target or adjust placement
     * along other axes.
     *
     * See https://floating-ui.com/docs/offset#options for details.
     */
    offset?: OffsetOptions;
    /**
     * Avoid using this prop if possible. Setting to false will cause the tooltip to display
     * improperly in many cases (inside tables, inside popovers, inside dialogs) and it will be
     * clipped, or can cause issues with grid and flex layouts.
     *
     * The only exception is if there is interactable content within the tooltip, though this
     * should generally be avoided, as well.
     *
     * When this prop is set to false and there is interactable content within the tooltip, it
     * should be placed as the sibling immediately after its target element in DOM order, for best
     * keyboard accessibility.
     *
     * Defaults to true.
     */
    renderOutsideParent?: boolean;
    /**
     * See {@link BasePopoverProps.nesting}.
     */
    nesting?: PopoverNesting;
}

/**
 * A standard tooltip component. Use Tooltip when appearance should be triggered by mouse and focus
 * events on the triggering element.
 *
 * For good accessibility, Tooltip should be assigned an id, and the `aria-describedby` attribute
 * on the target element should be set to that id. The exception to these rules is if the contents
 * of the tooltip are redundant with the contents of the target; in which case, `aria-hidden`
 * should be set to true. Additionally, if `renderOutsideParent` is set to false and there is
 * interactable content within the tooltip, the tooltip should be placed as the immediate sibling
 * following the element it targets.
 *
 * If a tooltip instance is passed as a prop to another component outside of that component's
 * `children`, the minimum set of props (e.g. only `children` if possible) should be passed to the
 * tooltip.
 *
 * All tooltips are registered with and managed by {@link TooltipManager}, which ensures that
 * only one tooltip is displayed at any given time.
 *
 * Note that there is currently a limitation to this functionality in the case that an element
 * is a tooltip target and also has a descendant that is tooltip target itself. When moving the
 * mouse from the descendant to the ancestor, the ancestor tooltip is not triggered.
 */
export const Tooltip: FC<TooltipProps> = ({
    id,
    everId,
    className,
    target: targetProp,
    showEvents = ["focus", "mouseenter"],
    hideEvents = ["blur", "mouseleave"],
    showDelay = 400,
    hideDelay = 50,
    placement = TooltipPlacement.BOTTOM,
    onShow = () => {},
    onHide = () => {},
    learnMoreLink,
    learnMoreText = "Learn more",
    debug = false,
    children,
    hoverTrigger: hoverTriggerProp,
    focusTrigger: focusTriggerProp,
    offset,
    renderOutsideParent = true,
    ...props
}) => {
    const EMPTY_TARGET = useRef<Element>(null);
    const target = targetProp || EMPTY_TARGET;
    const hoverTrigger = hoverTriggerProp || target;
    const focusTrigger = focusTriggerProp || target;
    const onShowRef = useLatest(onShow);
    const onHideRef = useLatest(onHide);
    const [show, setShow] = useState<boolean>(false);
    const [popoverElement, setPopoverElement] = useState<HTMLDivElement | null>(null);
    const timeout = useRef<number | null>(null);

    const hideTooltip = useCallback(() => {
        // If the old tooltip hasn't displayed yet because the show delay is still in effect,
        // then cancel the timeout so that it never displays.
        timeout.current && clearTimeout(timeout.current);
        setShow(false);
        onHideRef.current();
    }, [onHideRef]);

    const delayedSetShow = useCallback(
        (value: boolean) => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
            const delay: number = value ? showDelay : hideDelay;
            timeout.current = window.setTimeout(() => {
                setShow(value);
                (value ? onShowRef.current : onHideRef.current)();
            }, delay);

            if (value) {
                tooltipManager.changeTooltip(hideTooltip);
            } else {
                tooltipManager.unmount(hideTooltip);
            }
        },
        [showDelay, hideDelay, onShowRef, onHideRef, hideTooltip],
    );

    // Add event listeners to the hover trigger element, except for the `focus` and `blur` events.
    const triggerShowListener = useBrandedCallback(() => delayedSetShow(true), [delayedSetShow]);
    useEventListener(
        hoverTrigger,
        showEvents.filter((e) => e !== "focus"),
        triggerShowListener,
    );
    const triggerHideListener = useBrandedCallback(() => delayedSetShow(false), [delayedSetShow]);
    useEventListener(
        hoverTrigger,
        hideEvents.filter((e) => e !== "blur"),
        triggerHideListener,
    );

    // Add `focus` and `blur` event listeners to the focus trigger element.
    const triggerFocusShowListener = useBrandedCallback(() => {
        if (showEvents.includes("focus") && hasFocusVisible(focusTrigger.current)) {
            delayedSetShow(true);
        }
    }, [delayedSetShow, showEvents, focusTrigger]);
    useEventListener(focusTrigger, "focus", triggerFocusShowListener);
    const triggerFocusHideListener = useBrandedCallback(() => {
        if (hideEvents.includes("blur")) {
            delayedSetShow(false);
        }
    }, [delayedSetShow, hideEvents]);
    useEventListener(focusTrigger, "blur", triggerFocusHideListener);

    const tooltipShowListener = useBrandedCallback(() => delayedSetShow(true), [delayedSetShow]);
    const tooltipHideListener = useBrandedCallback(() => delayedSetShow(false), [delayedSetShow]);
    useEventListener(popoverElement, ["mouseenter", "focusin"], tooltipShowListener);
    useEventListener(popoverElement, ["mouseleave", "focusout"], tooltipHideListener);

    // If the tooltip is rendered outside the normal DOM hierarchy and there's a learn more link,
    // listen for the "l" key to be pressed to open the link in a new tab.
    const learnMoreLinkListener = useBrandedCallback(
        (event: Event) => {
            if (!learnMoreLink) {
                return;
            }
            if (event instanceof KeyboardEvent && event.key === "l") {
                event.stopPropagation();
                window.open(learnMoreLink, "_blank");
            }
        },
        [learnMoreLink],
    );
    useEventListener(focusTrigger, "keyup", learnMoreLinkListener);

    // Close tooltip if escape is pressed.
    const keyListener = useBrandedCallback(
        (e: Event) => {
            if (e instanceof KeyboardEvent && e.key === "Escape") {
                delayedSetShow(false);
            }
        },
        [delayedSetShow],
    );
    useEventListener(document, "keyup", keyListener);

    // Clear ongoing timeouts on unmount.
    useEffect(() => {
        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
            tooltipManager.unmount(hideTooltip);
        };
    }, [hideTooltip]);

    return (
        <BasePopover
            ref={setPopoverElement}
            id={id}
            everId={everId}
            className={clsx("bb-tooltip", className)}
            role={"tooltip"}
            target={target}
            show={debug || show}
            placement={placement}
            centerArrow={true}
            arrowWidth={12}
            arrowHeight={8}
            arrowMargin={8}
            offset={offset}
            renderOutsideParent={renderOutsideParent}
            {...props}
        >
            {typeof children === "string" ? <div>{children}</div> : children}
            {learnMoreLink && (
                <a
                    href={learnMoreLink}
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                    className={"bb-tooltip__learn-more-link"}
                    aria-label={"Press l to learn more"}
                >
                    {learnMoreText}
                    <Icon.ArrowUpRight size={16} color={EverColor.WHITE} aria-hidden={true} />
                </a>
            )}
        </BasePopover>
    );
};
