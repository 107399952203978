// Scrollbar utilities taken from https://github.com/malte-wessel/react-custom-scrollbars/tree/master/src/utils

export function getInnerHeight(el: HTMLElement): number {
    const { clientHeight } = el;
    const { paddingTop, paddingBottom } = getComputedStyle(el);
    return clientHeight - parseFloat(paddingTop) - parseFloat(paddingBottom);
}

export function getInnerWidth(el: HTMLElement): number {
    const { clientWidth } = el;
    const { paddingLeft, paddingRight } = getComputedStyle(el);
    return clientWidth - parseFloat(paddingLeft) - parseFloat(paddingRight);
}

let scrollbarWidth = -1;

export function getScrollbarWidth(): number {
    if (scrollbarWidth !== -1) {
        return scrollbarWidth;
    }
    if (typeof document !== "undefined") {
        const div = document.createElement("div");
        div.style.width = "100px";
        div.style.height = "100px";
        div.style.position = "absolute";
        div.style.top = "-9999";
        div.style.overflow = "scroll";
        document.body.appendChild(div);
        scrollbarWidth = div.offsetWidth - div.clientWidth;
        document.body.removeChild(div);
    } else {
        scrollbarWidth = 0;
    }
    return scrollbarWidth || 0;
}
