import clsx from "clsx";
import React, { FC, ReactElement, ReactNode, useEffect } from "react";
import { DndProvider as ReactDndProvider, useDrag, useDrop } from "react-dnd";
import { createDragDropManager } from "dnd-core";
import { TouchBackend } from "react-dnd-touch-backend";
import { Portal } from "util/Portal";
import { Identifier, useDragPreview } from "./DragAndDropUtils";

/**
 * Create a DragDropManager instead of directly passing a Backend to DndProvider to guarantee that
 * only a single instance of the Backend exists on the page. Otherwise, there will be
 * complaints/errors about multiple backends which we cannot otherwise avoid because we can have
 * multiple roots on a single page.
 */
const DND_MANAGER = createDragDropManager(TouchBackend, undefined, { enableMouseEvents: true });

/**
 * A wrapper around React DnD's DndProvider that pre-configures it for Everlaw's use.
 */
export const DndProvider: FC<{ children: ReactNode }> = ({ children }) => {
    return <ReactDndProvider manager={DND_MANAGER}>{children}</ReactDndProvider>;
};

export interface DraggableProps<D> {
    className?: string;
    style?: React.CSSProperties;
    /**
     * An identifier representing the type of the Draggable. Used by Droppable to determine if it
     * can accept a Draggable.
     */
    dragType: Identifier;
    /**
     * Object passed to Droppable when Draggable is dropped on a Droppable.
     */
    dragData: D;
    /**
     * Function to determine if Draggable should be draggable. If omitted, Draggable will always be
     * draggable.
     */
    canDrag?: () => boolean;
    /**
     * Callback function for when Draggable is dropped.
     * @param dragData Data associated with the dragged object.
     */
    onDrop?: (dragData: D) => void;
    /**
     * The component to render as the dragged element (which follows the cursor). By default, a copy
     * of the children of Draggable will be rendered as the drag preview.
     * Note the default likely will not be appropriate if this is a draggable table row, as the
     * row content will be outside the context of its table and may not render correctly.
     */
    dragPreview?: ReactNode;
    /**
     * The component(s) to make draggable.
     */
    children: ReactNode;
    /**
     * @param children will be wrapped in a HTML element of this type. Default is 'div'
     */
    element?: keyof React.ReactHTML;
}

/**
 * A wrapper that enables a given component to be dragged and dropped onto a Droppable.
 */
export function Draggable<D>({
    className,
    dragType,
    dragData,
    canDrag = () => true,
    onDrop,
    dragPreview: dragPreviewComponent,
    children,
    element = "div",
    ...props
}: DraggableProps<D>): ReactElement<DraggableProps<D>> {
    const [{ isDragging }, dragRef] = useDrag({
        type: dragType,
        item: dragData,
        canDrag,
        end: onDrop,
        collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    });
    const dragPreview = useDragPreview<D, HTMLDivElement>();

    // Disable text selection while dragging. Necessary for Firefox.
    useEffect(() => {
        if (isDragging) {
            document.body.style.userSelect = "none";
        } else {
            document.body.style.userSelect = "";
        }
        return () => {
            document.body.style.userSelect = "";
        };
    }, [isDragging]);

    return (
        <>
            {React.createElement(
                element,
                {
                    ref: dragRef,
                    className: clsx(className, "bb-dnd__draggable", {
                        "bb-dnd__draggable--dragging": isDragging,
                    }),
                    ...props,
                },
                children,
            )}
            {isDragging && dragPreview.display && (
                <Portal>
                    <div
                        ref={dragPreview.ref}
                        style={dragPreview.style}
                        className={"bb-dnd__drag-preview"}
                        aria-hidden={true}
                    >
                        {dragPreviewComponent === undefined ? children : dragPreviewComponent}
                    </div>
                </Portal>
            )}
        </>
    );
}

export interface DroppableProps<D> {
    className?: string;
    style?: React.CSSProperties;
    /**
     * Draggable type(s) that Droppable accepts.
     */
    accept: Identifier | Identifier[];
    /**
     * Function to determine if Droppable should accept a Draggable. The type of the Draggable is
     * already checked by Droppable before this function is called. If omitted, Droppable will
     * accept all Draggables with compatible drag types.
     * @param dragData Data associated with the received Draggable.
     */
    canDrop?: (dragData: D) => boolean;
    /**
     * Callback for when Droppable receives a Draggable through a drop.
     * @param dragData Data associated with the received Draggable.
     */
    onDrop: (dragData: D) => void;
    /**
     * The component(s) to receive draggables.
     */
    children: ReactNode;
}

/**
 * A wrapper that enables a given component to receive a dropped Draggable.
 */
export function Droppable<D>({
    className,
    accept,
    onDrop,
    canDrop: canDropFunc = () => true,
    children,
    ...props
}: DroppableProps<D>): ReactElement<DroppableProps<D>> {
    const [{ isOver, canDrop }, dropRef] = useDrop({
        accept,
        drop: onDrop,
        canDrop: canDropFunc,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });
    return (
        <div
            ref={dropRef}
            className={clsx(className, "bb-dnd__droppable", {
                "bb-dnd__droppable--is-over": isOver,
                "bb-dnd__droppable--can-drop": canDrop,
            })}
            {...props}
        >
            {children}
        </div>
    );
}
