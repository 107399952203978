import { clsx } from "clsx";
import type { TooltipProps } from "components/Tooltip";
// Cannot import Tooltip from index.tsx because that would introduce circular dependencies.
import { Tooltip } from "./Tooltip";
import { useCombinedRef } from "hooks/useCombinedRef";
import { useResizeObserver } from "hooks/useResizeObserver";
import React, { ReactElement, Ref, RefCallback, useId, useRef } from "react";

interface UseEllipsisTooltipProps<T extends Element> extends TooltipProps {
    /**
     * Class name to be applied to enable ellipsification.
     *
     * Defaults to "bb-ellipsis-overflow".
     */
    ellipsisClassName?: string;
    /**
     * Class name to be applied to the target element that the tooltip should be attached to.
     * This string will be included in (and will not override) the returned
     * {@link UseEllipsisTooltipResult.tooltipTargetProps tooltipTargetProps} object.
     */
    targetClassName?: string;
    /**
     * Ref to be passed to the target element that the tooltip should be attached to. This ref will
     * be merged with the refs needed for the hook to function properly and returned through
     * {@Link UseEllipsisTooltipResult.tooltipTargetProps tooltipTargetProps}.
     */
    targetRef?: Ref<T>;
    /**
     * The direction to check for overflow detection. Defaults to HORIZONTAL.
     */
    direction?: EllipsisDirection;
}

interface UseEllipsisTooltipResult<T extends Element> {
    /**
     * Tooltip component to be rendered, or null if the tooltip is unneeded.
     */
    tooltipComponent: ReactElement<TooltipProps> | null;
    /**
     * Props to be passed to the target element that the tooltip should be attached to. This target
     * element is assumed be a div (or some other non-inline element) wrapping the text that could
     * be ellipsed.
     *
     * These props will automatically:
     * - add `text-overflow: ellipsis` styling and focus-visible outlines to the target via CSS
     * classes
     * - associate the tooltip with the target element
     * - add necessary accessibility attributes to support keyboard navigation
     *
     * If you are using this hook to add an ellipsis tooltip to a target that does not want all of
     * these props configured, the essential ones to extract and add to the target element are `ref`
     * and `aria-describedby` (if the tooltip is not `aria-hidden`).
     */
    tooltipTargetProps: {
        ref: RefCallback<T>;
        tabIndex?: 0;
        /**
         * May include classes for adding `text-overflow: ellipsis` styling and focus-visible
         * outlines. Includes {@link UseEllipsisTooltipProps.targetClassName targetClassName} if
         * specified.
         */
        className: string;
        "aria-describedby"?: string;
    };
}

export enum EllipsisDirection {
    HORIZONTAL,
    VERTICAL,
}

function isOverflowed<E extends Element>(element: E, direction: EllipsisDirection): boolean {
    switch (direction) {
        case EllipsisDirection.HORIZONTAL:
            return element.scrollWidth > element.clientWidth;
        case EllipsisDirection.VERTICAL:
            return element.scrollHeight > element.clientHeight;
    }
}

/**
 * Hook that automatically creates and configures a tooltip for a given target element if that
 * element's contents are ellipsed.
 *
 * Returns two things:
 * - The tooltip component to be rendered (if necessary)
 * - The props to be passed to the target element that the tooltip should be attached to.
 *
 * Not all returned target element props need to be passed on directly to the target. See
 * {@link UseEllipsisTooltipResult.tooltipTargetProps tooltipTargetProps} for more details.
 */
export function useEllipsisTooltip<T extends Element = Element>({
    ellipsisClassName = "bb-ellipsis-overflow",
    targetClassName = "",
    targetRef: externalTargetRef = null,
    children: tooltipChildren,
    direction = EllipsisDirection.HORIZONTAL,
    ...tooltipProps
}: UseEllipsisTooltipProps<T>): UseEllipsisTooltipResult<T> {
    const tooltipId = useId();
    // Target ref to pass to the tooltip component.
    const targetRef = useRef<T>(null);
    // Use a ResizeObserver to detect when the target element is resized outside of React's systems.
    const [setObservedNode, observerEntry] = useResizeObserver<T>();
    const finalTargetRef = useCombinedRef<T>(targetRef, setObservedNode, externalTargetRef);

    const targetElement = observerEntry.target;
    const isEllipsed = !!targetElement && isOverflowed(targetElement, direction);
    if (targetRef.current === null || !isEllipsed) {
        return {
            tooltipComponent: null,
            tooltipTargetProps: {
                ref: finalTargetRef,
                className: clsx(targetClassName, ellipsisClassName),
            },
        };
    }

    return {
        tooltipComponent: (
            <Tooltip {...tooltipProps} id={tooltipId} target={targetRef}>
                {tooltipChildren}
            </Tooltip>
        ),
        tooltipTargetProps: {
            ref: finalTargetRef,
            tabIndex: 0,
            className: clsx(targetClassName, ellipsisClassName, "bb-focus-visible-within"),
            "aria-describedby": tooltipProps["aria-hidden"] ? undefined : tooltipId,
        },
    };
}
