import clsx from "clsx";
import { everIdProp } from "EverAttribute/EverId";
import React, { forwardRef, HTMLAttributes, ReactNode } from "react";
import "./Text.scss";
import { EverIdProp, FFC } from "util/type";

enum HeadingVariant {
    TINY = "tiny",
    EXTRA_SMALL = "extra small",
    SMALL = "small",
    MEDIUM = "medium",
    LARGE = "large",
    LARGE_BOLD = "large bold",
}

const HEADING_VARIANT_MAP = {
    [HeadingVariant.TINY]: "bb-heading--tiny",
    [HeadingVariant.EXTRA_SMALL]: "bb-heading--extra-small",
    [HeadingVariant.SMALL]: "bb-heading--small",
    [HeadingVariant.MEDIUM]: "bb-heading--medium",
    [HeadingVariant.LARGE]: "bb-heading--large",
    [HeadingVariant.LARGE_BOLD]: "bb-heading--large-bold",
};

export enum HeadingMargin {
    DEFAULT = "default",
    WITH_SUBHEADING = "with-subheading",
    NONE = "none",
}

type HeadingVariantName = "Tiny" | "ExtraSmall" | "Small" | "Medium" | "Large" | "LargeBold";

const HEADING_VARIANT_NAME_MAP: { [N in HeadingVariantName]: HeadingVariant } = {
    Tiny: HeadingVariant.TINY,
    ExtraSmall: HeadingVariant.EXTRA_SMALL,
    Small: HeadingVariant.SMALL,
    Medium: HeadingVariant.MEDIUM,
    Large: HeadingVariant.LARGE,
    LargeBold: HeadingVariant.LARGE_BOLD,
};

export type HeadingElement = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement>, EverIdProp {
    /**
     * The variant of the heading. Options are TINY, EXTRA_SMALL, SMALL, MEDIUM, LARGE,
     * and LARGE_BOLD.
     *
     * If none is provided, defaults to SMALL.
     */
    variant?: HeadingVariant;
    /**
     * The type of element to use for the heading (e.g. <h1> - <h6>).
     *
     * Note that the visual styling of the heading does not necessarily match the importance of the
     * heading in the DOM hierarchy, and, for accessibility, it is important for a heading's HTML
     * element to match its position in the DOM. For instance, all the subheadings of <h2> elements
     * should be <h3>, and so on.
     */
    element: HeadingElement;
    /**
     * The inner body of the heading element.
     */
    children?: ReactNode;
    /**
     * The type of bottom margin to apply to the heading. The actual px value of the bottom
     * margin depends on this prop as well as the size of the heading.
     * Default {@link HeadingMargin.DEFAULT}.
     */
    marginType?: HeadingMargin;
    /**
     * Whether the line-height should be set to the font size. Defaults to false.
     *
     * This option is useful when the heading's parent element is shorter than the default
     * line-height, as the heading may not be vertically centered within the parent otherwise.
     */
    matchLineHeightToSize?: boolean;
}

type HeadingElementProps = Omit<HeadingProps, "element">;

type HeadingVariantProps<P extends HeadingProps | HeadingElementProps = HeadingProps> = Omit<
    P,
    "variant"
>;

type HeadingFC<P extends HeadingProps | HeadingElementProps> = FFC<HTMLHeadingElement, P> & {
    Tiny: FFC<HTMLHeadingElement, HeadingVariantProps<P>>;
    ExtraSmall: FFC<HTMLHeadingElement, HeadingVariantProps<P>>;
    Small: FFC<HTMLHeadingElement, HeadingVariantProps<P>>;
    Medium: FFC<HTMLHeadingElement, HeadingVariantProps<P>>;
    Large: FFC<HTMLHeadingElement, HeadingVariantProps<P>>;
    LargeBold: FFC<HTMLHeadingElement, HeadingVariantProps<P>>;
};

/**
 * An element for page and section headings.
 *
 * The _visual_ style of the heading does not necessarily match the
 * importance of the heading in the DOM hierarchy. For this reason,
 * `<Heading>` has separate `style` and `element` properties.
 *
 * For accessibility, it is important for a heading's HTML element to
 * match its position in the DOM hierarchy. For example, all the
 * subheadings of `<h2>` should be `<h3>`; `<h4>` should not follow an
 * `<h2>` without an `<h3>` in between. This helps assistive technologies
 * such as screen readers better understand the structure of the page.
 *
 * These styles are also available via Sass classes and mixins.
 */
export const Heading: FFC<HTMLHeadingElement, HeadingProps> = forwardRef<
    HTMLHeadingElement,
    HeadingProps
>(
    (
        {
            variant = HeadingVariant.SMALL,
            element,
            children,
            everId,
            className,
            marginType = HeadingMargin.DEFAULT,
            matchLineHeightToSize = false,
            ...props
        },
        ref,
    ) => {
        return React.createElement(
            element,
            {
                className: clsx(
                    "bb-heading",
                    HEADING_VARIANT_MAP[variant],
                    className,
                    `bb-heading--margin-${marginType}`,
                    { "bb-heading--font-size-line-height": matchLineHeightToSize },
                ),
                ref,
                ...everIdProp(everId),
                ...props,
            },
            children,
        );
    },
);
Heading.displayName = "Heading";

function headingElementComponent(element: HeadingElement): HeadingFC<HeadingElementProps> {
    return forwardRef<HTMLHeadingElement, HeadingElementProps>((props, ref) => {
        return <Heading {...props} element={element} ref={ref} />;
    }) as HeadingFC<HeadingElementProps>;
}

export const H1: HeadingFC<HeadingElementProps> = headingElementComponent("h1");
H1.displayName = "H1";

export const H2: HeadingFC<HeadingElementProps> = headingElementComponent("h2");
H2.displayName = "H2";

export const H3: HeadingFC<HeadingElementProps> = headingElementComponent("h3");
H3.displayName = "H3";

export const H4: HeadingFC<HeadingElementProps> = headingElementComponent("h4");
H4.displayName = "H4";

export const H5: HeadingFC<HeadingElementProps> = headingElementComponent("h5");
H5.displayName = "H5";

export const H6: HeadingFC<HeadingElementProps> = headingElementComponent("h6");
H6.displayName = "H6";

[H1, H2, H3, H4, H5, H6].forEach((Component) => {
    (Object.keys(HEADING_VARIANT_NAME_MAP) as HeadingVariantName[]).forEach((variant) => {
        Component[variant] = forwardRef<HTMLHeadingElement>((props, ref) => {
            return <Component {...props} variant={HEADING_VARIANT_NAME_MAP[variant]} ref={ref} />;
        });
        Component[variant].displayName = `${Component.displayName}.${variant}`;
    });
});
