import { Dispatch, SetStateAction, useState } from "react";

interface UseColumnFilterProps<T> {
    /**
     * The initial value for {@link UseColumnFilterResult.filter}.
     */
    initialFilter?: T;
    /**
     * The initial value for {@link UseColumnFilterResult.previewFilter}.
     */
    initialPreviewFilter?: T;
    /**
     * The initial value for {@link UseColumnFilterResult.previewCount}. Defaults to 0.
     */
    initialPreviewCount?: number;
    /**
     * The initial value for {@link UseColumnFilterResult.previewLoading}. Defaults to false.
     */
    initialPreviewLoading?: boolean;
    /**
     * The initial value for {@link UseColumnFilterResult.showPopover}. Defaults to false.
     */
    initialShowPopover?: boolean;
}

export interface UseColumnFilterResult<T> {
    /**
     * The active filter value.
     */
    filter?: T;
    /**
     * A setter for the {@link filter} state variable.
     */
    setFilter: Dispatch<SetStateAction<T | undefined>>;
    /**
     * The preview filter value entered into the column filter popover.
     */
    previewFilter?: T;
    /**
     * A setter for the {@link previewFilter} state variable.
     */
    setPreviewFilter: Dispatch<SetStateAction<T | undefined>>;
    /**
     * The number of results for {@link previewFilter}. Note that this should reflect the
     * number of results as if {@link previewFilter} were applied in addition to any active
     * filters on other columns.
     */
    previewCount: number;
    /**
     * A setter for the {@link previewCount} state variable.
     */
    setPreviewCount: Dispatch<SetStateAction<number>>;
    /**
     * Whether the preview is loading. If true, a loading state will be displayed instead of
     * {@link previewCount}.
     */
    previewLoading: boolean;
    /**
     * A setter for the {@link previewLoading} state variable.
     */
    setPreviewLoading: Dispatch<SetStateAction<boolean>>;
    /**
     * Whether to show the column filter popover.
     */
    showPopover: boolean;
    /**
     * A setter for the {@link showPopover} state variable.
     */
    setShowPopover: Dispatch<SetStateAction<boolean>>;
}

/**
 * A hook that sets up the necessary state variables and their setters for use with a
 * column filter and returns them. This hook should be called at whatever level the state
 * of the column filter lives. The provided initial values will be used, but generally, the
 * default initial values won't need to be overwritten unless there is already an active
 * filter for this column.
 *
 * See {@link UseColumnFilterResult} for details on the variables returned by this hook.
 */
export function useColumnFilter<T>({
    initialFilter,
    initialPreviewFilter,
    initialPreviewCount = 0,
    initialPreviewLoading = false,
    initialShowPopover = false,
}: UseColumnFilterProps<T> = {}): UseColumnFilterResult<T> {
    const [filter, setFilter] = useState<T | undefined>(initialFilter);
    const [previewFilter, setPreviewFilter] = useState<T | undefined>(initialPreviewFilter);
    const [previewCount, setPreviewCount] = useState<number>(initialPreviewCount);
    const [previewLoading, setPreviewLoading] = useState<boolean>(initialPreviewLoading);
    const [showPopover, setShowPopover] = useState(initialShowPopover);
    return {
        filter,
        setFilter,
        previewFilter,
        setPreviewFilter,
        previewCount,
        setPreviewCount,
        previewLoading,
        setPreviewLoading,
        showPopover,
        setShowPopover,
    };
}
