import React from "react";
import { MenuOptionProps, Option as MenuOption, MenuOptionType } from "components/Menu/Item/Option";

export type MenuInfoProps = Omit<
    MenuOptionProps,
    "type" | "onClick" | "href" | "disabled" | "selected"
>;

export function Info({ ...props }: MenuInfoProps) {
    return <MenuOption {...props} type={MenuOptionType.INFO} />;
}
Info.displayName = "Info";
