import { useCallback, useRef } from "react";
import { useLatest } from "hooks/useLatest";

/**
 * This implementation of useAsyncDebounce was adapted from `react-table` v7.
 */

interface UseAsyncDebounceState<V> {
    timeout?: number;
    promise?: Promise<V>;
    resolve?: (value: V) => void;
    reject?: (reason?: unknown) => void;
}

/**
 * Returns a debounced function which delays calling the given function until after {@link wait}
 * milliseconds have passed since the debounced function was last called.
 * @param func Function to debounce
 * @param wait Milliseconds to wait until calling the given func.
 */
export function useAsyncDebounce<A, B>(func: (arg: A) => B, wait = 0): (arg: A) => Promise<B> {
    const debounceRef = useRef<UseAsyncDebounceState<B>>({});
    const funcRef = useLatest(func);
    const waitRef = useLatest(wait);

    return useCallback(
        async (arg: A): Promise<B> => {
            if (!debounceRef.current.promise) {
                debounceRef.current.promise = new Promise<B>((resolve, reject) => {
                    debounceRef.current.resolve = resolve;
                    debounceRef.current.reject = reject;
                });
            }

            if (debounceRef.current.timeout) {
                clearTimeout(debounceRef.current.timeout);
            }

            debounceRef.current.timeout = window.setTimeout(async () => {
                delete debounceRef.current.timeout;
                try {
                    debounceRef.current?.resolve?.(await funcRef.current(arg));
                } catch (err) {
                    debounceRef.current?.reject?.(err);
                } finally {
                    delete debounceRef.current.promise;
                }
            }, waitRef.current);

            return debounceRef.current.promise;
        },
        [funcRef, waitRef],
    );
}
