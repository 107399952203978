import clsx from "clsx";
import { IconButton } from "components/Button";
import { IconProps } from "components/Icon/IconProps";
import * as CommonIcon from "components/Icon/CommonIcon";
import * as Icon from "components/Icon";
import { everIdProp } from "EverAttribute/EverId";
import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import "./Toast.scss";
import { EverIdProp } from "util/type";

/**
 * Toast types mostly used for icon standardization. The default is STANDARD.
 */
export enum ToastType {
    SUCCESS = "success",
    ERROR = "error",
    STANDARD = "standard",
}

/**
 * The properties of the actual Toast component.
 */
export interface ToastProps extends EverIdProp {
    /**
     * The title of the toast
     */
    title: string;
    /**
     * The body of the toast
     */
    children?: ReactNode;
    /**
     * A callback to use when a toast is closed. Typically this will remove the toast from the global
     * store.
     */
    removeToast: () => void;
    /**
     * An optional callback when the toast is closed
     */
    onClose?: () => void;
    /**
     * An option callback when the toast is closed by the user clicking the x icon button rather
     * than by a timeout
     */
    onDirectClose?: () => void;
    /**
     * An optional parameter specifying the icon placed to the left of the title
     */
    icon?: ReactElement<IconProps>;
    /**
     * An optional parameter specifying the type of toast. If none is specified, it will default to
     * standard
     */
    type?: ToastType;
    /**
     * An optional parameter dictating if the toast can be closed via an X icon button. Default true.
     */
    closeable?: boolean;
}

function getToastIcon(
    type: ToastType,
    icon: ReactElement<IconProps> | undefined,
): ReactElement<IconProps> | null {
    if (type === ToastType.SUCCESS) {
        return <CommonIcon.Success className={"bb-toast__icon"} size={20} aria-hidden={true} />;
    } else if (type === ToastType.ERROR) {
        return <CommonIcon.Error className={"bb-toast__icon"} size={20} aria-hidden={true} />;
    } else if (type === ToastType.STANDARD && icon) {
        return React.cloneElement(icon, {
            size: icon.props.size || 20,
            className: clsx(icon.props.className, "bb-toast__icon"),
            "aria-hidden": true,
        });
    } else {
        return null;
    }
}

/**
 * A temporary, short notification that appears to the user. These are stored within a ToastBox
 * component. Additions / Removals of Toasts should happen via modifying state in the parent
 * ToastBox.
 *
 */
export function Toast({
    everId,
    title,
    children,
    icon,
    removeToast,
    onClose,
    onDirectClose,
    type = ToastType.STANDARD,
    closeable = true,
}: ToastProps): ReactElement<ToastProps> {
    const [isClosed, setIsClosed] = useState<boolean>(false);

    const handleAnimationEnd = () => {
        if (isClosed) {
            removeToast();
            onClose?.();
        }
    };

    // Use the isClosed state before closing to trigger the animation and then close the component
    // after animation ends using the onAnimationEnd handler.
    const onClick = () => {
        setIsClosed(true);
        onDirectClose?.();
    };

    // Set the length of time for which a Toast will be visible
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsClosed(true);
        }, 5000);
        return () => clearTimeout(timer); // Clear the timer if the user clicks the close button
    }, []);

    return (
        <div
            onAnimationEnd={handleAnimationEnd}
            className={clsx("bb-component-content", "bb-toast", { "bb-toast--exit": isClosed })}
            role={"alert"}
            {...everIdProp(everId)}
        >
            {closeable && (
                <IconButton
                    aria-label={"Close notification"}
                    className="bb-toast__close-icon"
                    onClick={onClick}
                >
                    <Icon.X size={20} />
                </IconButton>
            )}
            <div className="bb-toast__header">
                {getToastIcon(type, icon)}
                <div className="bb-toast__title">{title}</div>
            </div>
            {children && <div className="bb-toast__body">{children}</div>}
        </div>
    );
}

/**
 * The properties needed to render a Toast. Notably, the key is needed by React to ensure
 * uniqueness.
 */
export interface RenderToastProps extends ToastProps {
    /**
     * The unique key of the toast.
     */
    key: React.Key;
}

export interface ToastBoxProps {
    /**
     * An array of properties for each of the toasts to display.
     */
    toastProps: RenderToastProps[];
    /**
     * An optional parameter that, when provided, is applied as a className to the returned element.
     */
    className?: string;
}

/**
 * A component which contains a collection of Toasts. This is not intended to be visible to the end
 * user.
 */
export function ToastBox({ toastProps, className }: ToastBoxProps): ReactElement<ToastBoxProps> {
    return (
        <div className={clsx("bb-toast-box", className)}>
            {toastProps.map(({ key, ...props }) => (
                <Toast key={key} {...props} />
            ))}
        </div>
    );
}
